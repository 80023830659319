import {
  TableCell,
  TableRow,
  LinearProgress,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(
  createStyles({
    root: {
      padding: 0,
      borderBottom: 'none',
    },
    loadingShift: {
      marginTop: -4,
    },
  }),
);

type Props = {
  width: number;
};

const LoadingIndicator: React.FC<Props> = ({ width }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.root} colSpan={width}>
        <LinearProgress className={classes.loadingShift} />
      </TableCell>
    </TableRow>
  );
};

export default LoadingIndicator;
