import {
  Badge,
  createStyles,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import LinkIcon from './LinkIcon';
import { NavigationLink } from './types';
import useActiveLocation from './useActiveLocation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
    },
    nested: {
      backgroundColor: theme.palette.background.default,
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    actions: {
      pointerEvents: 'none',
      left: 'initial',
      top: '50%',
      right: 'initial',
      position: 'absolute',
      transform: 'translateX(calc(240px - 16px - 100%)) translateY(-50%)',
    },
    badgeRootCollapsedHidden: {
      maxWidth: '100%',
    },
  }),
);

type LinkItemProps = NavigationLink & {
  child?: boolean;
};

const LinkItem: React.FC<LinkItemProps> = ({
  label,
  link,
  icon,
  child,
  children,
  isActive: isActiveProp,
  location: locationProp,
  exact,
  strict,
  badgeProps,
}) => {
  const classes = useStyles();
  const isActive = useActiveLocation(link, {
    location: locationProp,
    exact,
    strict,
    isActive: isActiveProp,
  });

  return (
    <ListItem
      aria-current={isActive ? 'page' : undefined}
      button
      to={link}
      selected={isActive}
      className={clsx(classes.listItem, child && classes.nested)}
      component={Link}
    >
      <LinkIcon icon={icon} isActive={isActive} title={label} />

      <Badge
        color="primary"
        classes={{ root: classes.badgeRootCollapsedHidden }}
        {...badgeProps}
      >
        <ListItemText
          primary={label}
          primaryTypographyProps={{ noWrap: true }}
        />
      </Badge>

      <ListItemSecondaryAction classes={{ root: classes.actions }}>
        {children}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default LinkItem;
