import {
  createStyles,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { ColumnConfig, OrderType } from './types';

const useStyles = makeStyles(() =>
  createStyles({
    resetOpacity: {
      opacity: 'initial',
    },
  }),
);

type Props = {
  onSort?: ({ order, orderBy }: { order: OrderType; orderBy: string }) => void;
  initialOrder?: OrderType;
  initialOrderBy?: string;
  columns: ColumnConfig[];
  tooltip?: string;
};

const TableHeadSortable: React.FC<Props> = ({
  onSort,
  initialOrder = 'asc',
  initialOrderBy = '',
  columns,
  tooltip = 'Sort',
}) => {
  const classes = useStyles();
  const mounted = useRef(false);
  const [sort, changeSort] = useState<{ order: OrderType; orderBy: string }>({
    order: initialOrder,
    orderBy: initialOrderBy,
  });

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else if (typeof onSort === 'function') {
      onSort(sort);
    }
  }, [onSort, sort]);

  const onRequestSort = (columnId: string) => (
    _: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const orderByDef = columnId;
    let orderDef: OrderType = 'desc';

    if (sort.orderBy === columnId && sort.order === 'desc') {
      orderDef = 'asc';
    }

    changeSort({ order: orderDef, orderBy: orderByDef });
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(
          ({ id, label, options: { align, sort: sortable = true } = {} }) => (
            <TableCell
              key={id}
              align={align}
              sortDirection={sort.orderBy === id ? sort.order : false}
              className={
                typeof label === 'string' || typeof label === 'number'
                  ? undefined
                  : classes.resetOpacity
              }
            >
              {typeof label === 'string' || typeof label === 'number' ? (
                <Tooltip
                  title={tooltip}
                  placement={align === 'right' ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={sort.orderBy === id}
                    direction={sort.order}
                    onClick={onRequestSort(id)}
                    hideSortIcon={!sortable}
                    disabled={!sortable}
                  >
                    {label}
                  </TableSortLabel>
                </Tooltip>
              ) : (
                label
              )}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadSortable;
