import { TablePagination } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

type PaginationState = {
  page: number;
  perPage: number;
};

type Props = {
  count: number;
  onChange(state: PaginationState): void;
  initialPage?: number;
  initialRowsPerPage?: number;
  perPageOptions?: number[];
  paginationProps?: Record<string, unknown>;
};

const TablePaginationWrapper: React.FC<Props> = ({
  count,
  onChange,
  initialPage = 1,
  initialRowsPerPage = 25,
  perPageOptions,
  paginationProps = {},
}) => {
  const mounted = useRef<boolean>(false);
  const [page, changePage] = useState<number>(initialPage);
  const [perPage, changeRowsPerPage] = useState<number>(initialRowsPerPage);

  useEffect(() => {
    changePage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      onChange({
        page,
        perPage,
      } as PaginationState);
    }
  }, [onChange, page, perPage]);

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    changePage(1);
    changeRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <TablePagination
      rowsPerPageOptions={perPageOptions}
      component="div"
      count={count}
      rowsPerPage={perPage}
      page={page - 1}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={(_, value) => {
        changePage(value + 1);
      }}
      {...paginationProps}
    />
  );
};

export default TablePaginationWrapper;
