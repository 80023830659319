/* eslint-disable react/destructuring-assignment */
import { Grid, Paper } from '@material-ui/core';
import { isEqual, isNil, negate, pickBy } from 'lodash-es';
import queryString from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EnhancedPaginatedTable, {
  Props as EnhancedPaginatedTableProps,
} from './EnhancedPaginatedTable';

type Initial = Partial<{
  roleName: string;
  name: string;
  address: string;
  search: string;
  orderBy: string;
  sort: string;
  order: string;
  isActive: '1' | '0' | boolean;
  perPage: number;
  page: number;
  status: string;
  statusGroup: string;
  isInvalid: boolean;
}>;

export type PropsForFilterRow = {
  onChange: (params: Partial<Initial>) => void;
  displayOptions: {
    [key: string]: string;
  };
};

type TableWithHistoryProps<
  State extends object = Record<string, string | number>
> = EnhancedPaginatedTableProps & {
  initial?: Initial;
  filterRow?(props: PropsForFilterRow): React.ReactElement;
  statusGroup?: string;
  status?: string;
  onStateChange?: (state: State) => void;
  customTable?(props: PropsForFilterRow['displayOptions']): React.ReactElement;
  state?: Initial;
};

const TableWithHistory: React.FC<TableWithHistoryProps> = ({
  initial = {},
  filterRow,
  statusGroup,
  onStateChange,
  customTable,
  status,
  state,
  ...restProps
}) => {
  const history = useHistory();

  const [displayOptions, setOptions] = useState(() => {
    const { search } = history.location;

    const initialState = search ? queryString.parse(search) : (initial as {});

    return initialState;
  });

  const [statusGroupHistory, setStatusGroupHistory] = useState<
    string | undefined
  >(statusGroup || undefined);

  useEffect(() => {
    if (statusGroup && statusGroup !== statusGroupHistory) {
      setStatusGroupHistory(statusGroup);
      setOptions({
        ...initial,
        page: 1,
        perPage: 10,
        start: null,
        end: null,
        ...(status ? { status } : null),
      });
    }
  }, [initial, status, statusGroup, statusGroupHistory]);

  useEffect(() => {
    setOptions(prevParams => ({ ...prevParams, ...state }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // Prevent duplicate of requests on component initialization
  const isFirstLoadRef = useRef(true);

  useEffect(() => {
    if (isFirstLoadRef.current) {
      isFirstLoadRef.current = false;
    } else {
      // eslint-disable-next-line no-unused-expressions
      onStateChange?.(displayOptions);
    }
  }, [displayOptions, onStateChange, isFirstLoadRef]);

  useEffect(() => {
    history.replace({ search: queryString.stringify(displayOptions) });
  }, [displayOptions, history]);

  const onChange = useCallback((params: Partial<Initial>) => {
    setOptions(prevParams => {
      const newParams = pickBy(
        { ...prevParams, page: 1, ...params },
        negate(isNil),
      );
      return isEqual(newParams, prevParams) ? prevParams : newParams;
    });
  }, []);

  return (
    <Grid container spacing={2} alignItems="flex-end" justify="flex-start">
      {filterRow && filterRow({ onChange, displayOptions })}
      <Grid item xs={12}>
        <Paper>
          {customTable ? (
            customTable(displayOptions)
          ) : (
            <EnhancedPaginatedTable
              {...restProps}
              initial={{
                page: +(
                  (displayOptions as { page: number }).page ||
                  initial.page ||
                  1
                ),
                perPage: +(
                  (displayOptions as { perPage: number }).perPage ||
                  initial.perPage ||
                  10
                ),
              }}
              onStateChange={onChange}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TableWithHistory;
