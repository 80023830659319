import { ENDPOINTS } from '../environment';
import {
  ChannelIdentity,
  ChannelsInfinitePaginationParams,
  ChannelType,
  FetchedChannel,
  InfinitePaginationResponse,
} from '../types';
import api from './apiSetup';
import {
  Channel,
  mapChannelTypeToAPI,
  mapInfinitePaginatedData,
  mapInfinitePaginationParams,
} from './mappers';

export const getChatList = (
  channelType: ChannelType,
  params?: ChannelsInfinitePaginationParams,
) =>
  api
    .get<InfinitePaginationResponse<FetchedChannel>>(
      ENDPOINTS.chats(mapChannelTypeToAPI(channelType)),
      {
        params: mapInfinitePaginationParams(params),
      },
    )
    .then(({ data }) =>
      mapInfinitePaginatedData((v: FetchedChannel) => new Channel(v))(data),
    )
    .catch(e => {
      throw e.response.message || e.message;
    });

export const getChannelInfoAPI = ({
  channelType,
  channelId,
}: ChannelIdentity) =>
  api
    .get<FetchedChannel>(
      `${ENDPOINTS.chats(mapChannelTypeToAPI(channelType))}/${channelId}`,
    )
    .then(({ data }) => new Channel(data))
    .catch(e => {
      throw e.response.message || e.message;
    });
