export const BASE_API_URL = process.env.REACT_APP_API_URI;

const URI_PROTOCOL = process.env.REACT_APP_URI_PROTOCOL;
const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;
const SUBDOMAIN_LANDING = process.env.REACT_APP_SUBDOMAIN_LANDING;

export const REDUX_APP_ID = '@rtt/admin/' as const;

export const ROUTE_WITHOUT_PRIVILEGES = '/';
export const LOGIN_PAGE = `${URI_PROTOCOL}${
  SUBDOMAIN_LANDING ? `${SUBDOMAIN_LANDING}.` : ''
}${ROOT_DOMAIN}/login`;

export const ACTIVATION_REQUEST_STATUS = 'requested';
export const REGISTERED_STATUS = 'registered';

export const ENDPOINTS = {
  categories: 'categories',
  moderators: '/manage/moderators',
  moderatorsStatusSuffix: 'status/change',
  distributorAppChange: 'application/distributor/create',
  distributorAppNotices: 'application/distributor/notices',
  distributorActiveEdit: (id: number) =>
    `distributors/${id}/profile?include=contact,documents,categories`,
  distributors: 'distributors',
  distributorsList: 'distributors/search',
  distributorStats: (id: number) => `distributors/${id}/stats`,
  rtt: 'rtt',
  rttList: 'rtt/search',
  rttEditActive: (id: number) =>
    `rtt/${id}/profile?include=contact,showcases,documents,categories`,
  rttAppChange: 'application/rtt/create',
  rttAppNotices: 'application/rtt/notices',
  contactForm: 'contact-form/settings',
};

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const SHOW_HIDDEN_FEATURE =
  process.env.REACT_APP_SHOW_FEATURES === 'true';
