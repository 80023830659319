import * as types from './types';
import type {
  FeedbackEmailFormValues,
  FeedbackEmailFormErrors,
} from '../types';

/*
  Get feedback email settings
*/
export const getFeedbackEmailRequest = () => ({
  type: types.SETTINGS_EMAIL_GET_REQUEST,
});

export const getFeedbackEmailSuccess = (email: string) => ({
  type: types.SETTINGS_EMAIL_GET_SUCCESS,
  payload: email,
});

export const getFeedbackEmailFailure = (error: string) => ({
  type: types.SETTINGS_EMAIL_GET_FAILURE,
  payload: error,
});

/*
  Set feedback email settings
*/
export const setFeedbackEmailRequest = (
  formValues: FeedbackEmailFormValues,
) => ({
  type: types.SETTINGS_EMAIL_SET_REQUEST,
  payload: formValues,
});

export const setFeedbackEmailSuccess = (email: string) => ({
  type: types.SETTINGS_EMAIL_SET_SUCCESS,
  payload: email,
});

export const setFeedbackEmailFailure = (errors: FeedbackEmailFormErrors) => ({
  type: types.SETTINGS_EMAIL_SET_FAILURE,
  payload: errors,
});

export type SettingsActions = ReturnType<
  | typeof getFeedbackEmailRequest
  | typeof getFeedbackEmailSuccess
  | typeof getFeedbackEmailFailure
  | typeof setFeedbackEmailRequest
  | typeof setFeedbackEmailSuccess
  | typeof setFeedbackEmailFailure
>;
