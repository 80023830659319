export const GET_RTT_REQUEST = `[admin]rtt/GET_RTT_REQUEST` as const;
export const GET_RTT_SUCCESS = `[admin]rtt/GET_RTT_SUCCESS` as const;
export const GET_RTT_FAILURE = `[admin]rtt/GET_RTT_FAILURE` as const;

export const GET_RTT_DETAILS_REQUEST = `[admin]rtt/GET_RTT_DETAILS_REQUEST` as const;
export const GET_RTT_DETAILS_SUCCESS = `[admin]rtt/GET_RTT_DETAILS_SUCCESS` as const;
export const GET_RTT_DETAILS_FAILURE = `[admin]rtt/GET_RTT_DETAILS_FAILURE` as const;

export const CHANGE_RTT_STATUS_REQUEST = `[admin]rtt/CHANGE_RTT_STATUS_REQUEST` as const;
export const CHANGE_RTT_STATUS_SUCCESS = `[admin]rtt/CHANGE_RTT_STATUS_SUCCESS` as const;
export const CHANGE_RTT_STATUS_FAILURE = `[admin]rtt/CHANGE_RTT_STATUS_FAILURE` as const;

export const CHANGE_RTT_REQUEST = `[admin]rtt/CHANGE_RTT_REQUEST` as const;
export const CHANGE_RTT_SUCCESS = `[admin]rtt/CHANGE_RTT_SUCCESS` as const;
export const CHANGE_RTT_FAILURE = `[admin]rtt/CHANGE_RTT_FAILURE` as const;
