import { RoleRules, UserInfoType } from '../types';

const checkSingle = <P extends string = string, D extends object = {}>(
  rules: RoleRules<P, D>,
  user: UserInfoType | null,
  action: P,
  data?: D,
): boolean => {
  if (!user || !user.role) {
    return false;
  }
  const permissions = rules[user && user.role];

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  if (permissions.static && permissions.static.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    // dynamic rule not provided for action
    return (
      (typeof permissionCondition === 'function' &&
        permissionCondition(user, data)) ||
      false
    );
  }

  return false;
};

const checkMultiple = <P extends string = string, D extends object = {}>(
  rules: RoleRules<P, D>,
  user: UserInfoType | null,
  action: P[],
  data?: D,
): boolean => {
  return action.every(actionItem => checkSingle(rules, user, actionItem, data));
};

const check = <P extends string = string, D extends object = {}>(
  rules: RoleRules<P, D>,
  user: UserInfoType | null,
  action: P | P[],
  data?: D,
): boolean => {
  if (Array.isArray(action)) {
    return checkMultiple(rules, user, action, data);
  }
  return checkSingle(rules, user, action, data);
};

export default check;
