import React from 'react';
import { Send, SendProps } from 'react-web-gifted-chat';
import { useTranslation } from 'react-i18next';

const SendButton = (props: SendProps) => {
  const [t] = useTranslation();

  return <Send {...props} label={t('controls.send')} />;
};

export default SendButton;
