import { List } from '@material-ui/core';
import React from 'react';
import MainLink from './MainLink';
import { NavigationConfig } from './types';

type Props = {
  links: NavigationConfig;
};

const NavigationExt: React.FC<Props> = ({ links }) => {
  return (
    <List component="nav">
      {links.map(link => (
        <MainLink key={link.label} {...link} />
      ))}
    </List>
  );
};

export default NavigationExt;
