import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import { FieldWithMessages as Field, TextFieldAdapter } from '@rtt-libs/views';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { EditModeratorValues } from '../../../api/moderators';

type Props = FormRenderProps<EditModeratorValues> & { onClose(): void };

const EditModeratorForm: React.FC<Props> = ({
  handleSubmit,
  submitError,
  onClose,
}) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle id="edit-moderator-dialog-title">
        {t('admin.moderators.change.dialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('admin.moderators.change.dialogDescription')}
        </DialogContentText>
        <Field name="id" component="input" hidden />
        <Field
          margin="dense"
          name="login"
          type="text"
          fullWidth
          required
          component={TextFieldAdapter}
          inputProps={{
            pattern: '^[a-zA-Z0-9-_]+$',
          }}
          label={t('admin.moderators.add.login.label')}
          placeholder={t('admin.moderators.add.login.placeholder')}
        />
        <Field
          margin="dense"
          name="password"
          type="text"
          fullWidth
          required
          component={TextFieldAdapter}
          inputProps={{
            minLength: 6,
            maxLength: 32,
          }}
          label={t('admin.moderators.change.passwordLabel')}
          placeholder={t('admin.moderators.change.passwordPlaceholder')}
        />
        <Field
          component={TextFieldAdapter}
          name="firstName"
          margin="dense"
          type="text"
          fullWidth
          required
          label={t('admin.moderators.add.firstName.label')}
        />
        <Field
          component={TextFieldAdapter}
          name="lastName"
          margin="dense"
          type="text"
          fullWidth
          required
          label={t('admin.moderators.add.lastName.label')}
        />
        <Field
          component={TextFieldAdapter}
          name="email"
          margin="dense"
          type="email"
          fullWidth
          required
          label={t('admin.moderators.add.email.label')}
          placeholder={t('admin.moderators.add.email.placeholder')}
        />
        <Field
          component={TextFieldAdapter}
          name="phone"
          margin="dense"
          type="phone"
          initialValue="+380"
          inputProps={{
            pattern: '^(\\+\\d{1,2})?\\d{10}$',
          }}
          fullWidth
          required
          label={t('admin.moderators.add.phone.label')}
          placeholder={t('admin.moderators.add.phone.placeholder')}
        />
        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('controls.cancel')}
        </Button>
        <Button type="submit" color="primary" variant="contained">
          {t('controls.save')}
        </Button>
      </DialogActions>
    </form>
  );
};

export default EditModeratorForm;
