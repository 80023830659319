/* eslint-disable @typescript-eslint/camelcase */
import { transformSimpleErrorMessage } from '@rtt-libs/api-services';
import { ENDPOINTS } from '../environment';
import {
  ChannelIdentity,
  FetchedChannel,
  ResolveChatRequestData,
  ResolveRequestAPI,
  ResolveRequestData,
} from '../types';
import api from './apiSetup';
import { Channel, mapChannelTypeToAPI } from './mappers';

export const resolveChat = (payload: ResolveRequestData) =>
  api
    .post<FetchedChannel>(
      ENDPOINTS.chats(mapChannelTypeToAPI(payload.chatType)),
      'opponentId' in payload ? mapResolveChannelData(payload) : undefined,
    )
    .then(({ data }) => new Channel(data))
    .catch(transformSimpleErrorMessage);

const mapResolveChannelData = ({
  opponentId,
  opponentType,
}: ResolveChatRequestData): ResolveRequestAPI => {
  switch (opponentType) {
    case 'distributor':
      return { distributor_id: opponentId };
    case 'rtt':
      return { rtt_id: opponentId };
    default:
      throw new Error('Wrong opponentType value');
  }
};

export const markChatReadAPI = ({ channelId, channelType }: ChannelIdentity) =>
  api
    .post(ENDPOINTS.chatsMarkRead(channelId, mapChannelTypeToAPI(channelType)))
    .catch(transformSimpleErrorMessage);

export const assignSupportChannelAPI = (channelId: string) =>
  api
    .post<FetchedChannel>(ENDPOINTS.assignSupportChannel(channelId))
    .then(data => new Channel(data.data))
    .catch(transformSimpleErrorMessage);

export const closeSupportChannelAPI = (channelId: string) =>
  api
    .post(ENDPOINTS.resolveSupportChannel(channelId))
    .catch(transformSimpleErrorMessage);
