import { CircularProgress, TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, AutocompleteProps, Value } from '@material-ui/lab';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldRenderProps, UseFieldConfig, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldWithMessages } from '../FormFieldAdapters';

export interface Option {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

interface TranslatedAutocompleteProps<
  T = Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>
  extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput' | 'multiple' | 'disableClearable' | 'freeSolo'
  > {
  multiple?: Multiple;
  disableClearable?: DisableClearable;
  freeSolo?: FreeSolo;

  helperText?: string;
  error?: boolean;
  label?: string;
  textFieldProps?: TextFieldProps;
}

export function TranslatedAutocomplete<
  T = Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  helperText,
  error,
  label,
  textFieldProps,
  ...rest
}: TranslatedAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const [t] = useTranslation();

  const translatedStringProps = useMemo(
    () => ({
      clearText: t('controls.autocomplete.clearText', 'Clear'),
      closeText: t('controls.autocomplete.closeText', 'Close'),
      loadingText: t('controls.autocomplete.loadingText', 'Loading…'),
      noOptionsText: t('controls.autocomplete.noOptionsText', 'No options'),
      openText: t('controls.autocomplete.openText', 'Open'),
    }),
    [t],
  );

  return (
    <Autocomplete
      {...translatedStringProps}
      {...rest}
      renderInput={params => (
        <TextField
          {...textFieldProps}
          {...params}
          label={label}
          fullWidth
          variant="outlined"
          helperText={helperText}
          error={error}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
            endAdornment: !textFieldProps?.InputProps?.readOnly && (
              <>
                {rest.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

interface AsyncAutocompleteProps<
  T = Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>
  extends FieldRenderProps<Value<T, Multiple, DisableClearable, FreeSolo>>,
    Partial<
      Omit<
        AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
        'renderInput' | 'multiple' | 'disableClearable' | 'freeSolo'
      >
    > {
  multiple?: Multiple;
  disableClearable?: DisableClearable;
  freeSolo?: FreeSolo;

  textFieldProps?: TextFieldProps;
}

const dummyValue = { name: '' };
const emptyOptions = [] as Option[];
const emptyMultipleValue = [] as Option[];

function AsyncAutocomplete<
  T = Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  input,
  meta,
  textFieldProps,
  options = (emptyOptions as unknown) as T[],
  ...rest
}: AsyncAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { onChange, value, name, ...restInput } = input;

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const emptySingleValue = restInput.freeSolo ? '' : dummyValue;
  const emptyValue = ((restInput.multiple
    ? emptyMultipleValue
    : emptySingleValue) as unknown) as Value<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >;

  const onChangeAutocomplete = useCallback(
    (
      _: React.ChangeEvent<{}>,
      newValue: Value<T, boolean | undefined, DisableClearable, FreeSolo>,
    ) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <TranslatedAutocomplete
      {...rest}
      options={options}
      onChange={onChangeAutocomplete}
      value={value || emptyValue}
      helperText={showError ? meta.error || meta.submitError : ''}
      error={showError}
      textFieldProps={{ name, ...textFieldProps }}
      {...restInput}
    />
  );
}

interface ClearOption {
  id: number | string;
  name: string;
}

interface Props<Option extends ClearOption = ClearOption>
  extends UseFieldConfig<number | string> {
  readOnly?: boolean;
  fetchOptions: (inputValue?: string | null) => void;
  options: Option[];
  loading?: boolean;
  name: string;
  textFieldProps?: TextFieldProps;
  label?: string;
  disableClearable?: boolean;
  groupBy?: (option: Option) => string;
  blurOnSelect?: boolean;
}

const AsyncAutocompleteField = <Option extends ClearOption>({
  readOnly,
  fetchOptions,
  options,
  loading,
  name,
  ...fieldProps
}: Props<Option>) => {
  const [open, setOpen] = useState(false);
  const { pauseValidation, resumeValidation } = useForm();

  // Fixes validation of required field, bc it invokes before actually filling input
  useEffect(() => {
    if (open) {
      pauseValidation();
    } else {
      resumeValidation();
    }
  }, [pauseValidation, resumeValidation, open]);

  // TODO: could be optimized. Revise implementation of preventing calling fetch on every "open" and save last fetch results
  const preventFetch = useRef(false);

  useEffect(() => {
    if (open && !preventFetch.current) {
      fetchOptions();
      // Do not initiate fetch while loading
      preventFetch.current = true;
    }
  }, [fetchOptions, open]);

  useEffect(() => {
    if (!loading) {
      preventFetch.current = false;
    }
  }, [loading]);

  const onOpen = useCallback(() => {
    setOpen(!readOnly);
  }, [readOnly]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <FieldWithMessages
      name={name}
      component={AsyncAutocomplete}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      getOptionSelected={(option: Option, value: Option) => {
        return option?.name === value?.name;
      }}
      getOptionLabel={(option: Option) => option?.name}
      options={options}
      loading={loading}
      textFieldProps={{ required: true, InputProps: { readOnly } }}
      {...fieldProps}
    />
  );
};

export default AsyncAutocompleteField;
