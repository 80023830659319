import { Box } from '@material-ui/core';
import React from 'react';
import { ProductPreviewCard } from '../../../productsAddition';
import type { ProductMessagePayload } from '../../../types';

interface Props {
  product: ProductMessagePayload;
}

const ProductMessageView = ({ product }: Props) => {
  return (
    <Box clone margin={1} marginBottom={0.5}>
      <ProductPreviewCard product={product} />
    </Box>
  );
};

export default ProductMessageView;
