/* eslint-disable @typescript-eslint/camelcase */
import {
  CategoryItemId,
  CategoryItem,
  CategoriesItemDictionary,
} from '@rtt-libs/types';
import { mapValues, assign } from 'lodash/fp';
import {
  AddCategoryFormValues,
  EditCategoryFormValues,
  MoveCategoryItem,
} from '../categoriesTree/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';

const withChildrenProps = {
  hasChildren: true,
  isExpanded: false,
};

const addProps = mapValues((value: CategoryItem) => {
  if (value.children.length > 0) {
    const withChildren = assign(withChildrenProps, value);
    // add missing field for root item
    if (value.id === undefined) {
      withChildren.id = 'root';
      withChildren.isExpanded = true;
    }
    return withChildren;
  }
  return value;
});

export const getCategories = () =>
  api
    .get<Record<CategoryItemId, CategoryItem>>(ENDPOINTS.categories, {
      params: { is_active: 1 },
    })
    .then(({ data }) => addProps(data));

export const getAllCategories = () =>
  api
    .get<CategoriesItemDictionary>(ENDPOINTS.categories, {
      params: { is_active: 0 },
    })
    .then(({ data }) => addProps(data));

export const createCategory = ({
  parentId,
  ...values
}: AddCategoryFormValues) =>
  api.post(`${ENDPOINTS.categories}/${parentId}`, values);

export const createRootCategory = (values: AddCategoryFormValues) =>
  api.post(ENDPOINTS.categories, values);

export const editRootCategory = ({ id, titles }: EditCategoryFormValues) =>
  api.put(ENDPOINTS.categories, { titles });

export const editCategory = ({ id, titles }: EditCategoryFormValues) =>
  api.put(`${ENDPOINTS.categories}/${id}`, { titles });

export const moveRootCategory = ({ children }: MoveCategoryItem) =>
  api.put(ENDPOINTS.categories, { children });

export const moveCategory = ({
  parentId,
  children,
  titles,
}: MoveCategoryItem) =>
  api.put(`${ENDPOINTS.categories}/${parentId}`, { children, titles });

export const deleteCategory = (id: CategoryItemId) =>
  api.delete(`${ENDPOINTS.categories}/${id}`);
