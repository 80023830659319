import isEqual from 'lodash/isEqual';
import { useRef } from 'react';
import { UserInfoType } from '../types';
import profileLocalStorage from './profile';

const useProfile = () => {
  const profileLocal = profileLocalStorage.get();

  const profile = useRef<UserInfoType | null>(null);

  if (isEqual(profile.current, profileLocal)) {
    return profile.current;
  }

  profile.current =
    !!profileLocal && typeof profileLocal === 'object' ? profileLocal : null;

  return profile.current;
};

export default useProfile;
