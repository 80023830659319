import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

interface SelectAdapterProps extends FieldRenderProps<(string | number)[]> {
  label?: string;
  formControlProps?: FormControlProps;
  selectProps?: Record<string, unknown>;
}

const SelectAdapter: React.FC<SelectAdapterProps> = ({
  input: { name, value, onChange, ...restInput },
  meta,
  label,
  formControlProps,
  selectProps,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FormControl {...formControlProps} error={showError}>
      <InputLabel htmlFor={name} shrink={rest.displayEmpty}>
        {label}
      </InputLabel>

      <Select
        {...rest}
        {...selectProps}
        name={name}
        onChange={onChange}
        inputProps={restInput}
        value={value}
      />

      {showError && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectAdapter;
