import React from 'react';
import { Box, Paper } from '@material-ui/core';

const ChatWrapper: React.FC = ({ children }) => {
  return (
    <Box clone flex="1 1" p={1} display="flex" flexDirection="column">
      <Paper variant="outlined">{children}</Paper>
    </Box>
  );
};

export default ChatWrapper;
