import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { editMessageRequest } from '../../../duck/actions';
import {
  selectMessageError,
  selectMessageLoading,
} from '../../../duck/selectors';
import type { ChannelType, Message, UUID } from '../../../types';

interface Props {
  onClose(): void;
  text: string;
  id: Message['id'];
  open: boolean;
  onExited?: () => void;
  channelType: ChannelType;
  channelId: UUID;
}

const EditMessageModal = ({
  open,
  onClose,
  text,
  onExited,
  id,
  channelId,
  channelType,
}: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [value, setValue] = useState<string | undefined>(text);

  const loading = useSelector(selectMessageLoading);
  const error = useSelector(selectMessageError);

  const handleSubmit = (event: FormEvent) => {
    const trimmedValue = value?.trim();

    if (trimmedValue) {
      dispatch(
        editMessageRequest({
          id,
          channelId,
          channelType,
          type: 'text',
          data: {
            message: trimmedValue,
          },
        }),
      );
    } else {
      setValue(trimmedValue);
    }

    event.preventDefault();
  };

  const preventClosingRef = useRef(true);
  useEffect(() => {
    if (!loading && !error && !preventClosingRef.current) {
      onClose();
    }
    preventClosingRef.current = false;
  }, [loading, error, onClose]);

  return (
    <Dialog open={open} onClose={onClose} onExited={onExited}>
      <DialogTitle>{t('chats.editMessageTitle')}</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            multiline
            label={t('chats.messages.messageFieldLabel')}
            name="text"
            value={value}
            onChange={event => setValue(event.target.value)}
            fullWidth
            required
            inputProps={{
              pattern: '.*\\S+.*',
            }}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </DialogContent>

        <DialogActions>
          <Button type="submit" disabled={loading}>
            {t('controls.save')}
          </Button>
          <Button onClick={onClose}>{t('controls.close')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditMessageModal;

export const useModalState = (initialState = false) => {
  const [isOpened, setOpenState] = useState(initialState);

  const toggle = useCallback(() => {
    setOpenState(openState => !openState);
  }, []);

  const close = useCallback(() => {
    setOpenState(false);
  }, []);

  const open = useCallback(() => {
    setOpenState(true);
  }, []);

  return {
    isOpened,
    open,
    close,
    toggle,
  };
};
