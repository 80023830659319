import { all, call, race, take } from 'redux-saga/effects';
import { getAuthToken } from '../../../api/apiSetup';
import { CHATS_CANCEL } from '../../types';
import echoSubscriptionListener from './echoSubscriptionListener';
import { createEcho, initializeEchoSocket } from './echoWrapper';
import socketEmitListener from './socketEmitListener';
import socketApiListener from './socketListener';

function* startChannelWatcher(token: string) {
  /* Echo wrapper from Laravel */
  const echo = createEcho(token);
  const socket = initializeEchoSocket(echo);

  yield race({
    task: all([
      call(socketApiListener, socket),
      call(socketEmitListener, socket),
      call(echoSubscriptionListener, echo),
    ]),
    cancel: take(CHATS_CANCEL),
  });
}

function* startSocketWatcher() {
  const token = getAuthToken();

  if (token) {
    yield* startChannelWatcher(token);
  }
}

export default startSocketWatcher;
