const ROLES = {
  SYSTEM_ADMIN: 'admin',
  SYSTEM_MODERATOR: 'moderator',
  DISTR_OWNER: 'distributor_owner',
  DISTR_ADMIN: 'distributor_admin',
  DISTR_SUPERVISOR: 'distributor_supervisor',
  DISTR_MANAGER: 'distributor_manager',
  DISTR_DRIVER: 'distributor_driver',
  RTT_OWNER: 'rtt_owner',
} as const;

export default ROLES;
