import { TextField } from '@material-ui/core';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

const TextFieldAdapter: React.FC<
  FieldRenderProps<string> & {
    inputProps?: { [k: string]: unknown };
    className?: string;
  }
> = props => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    inputProps = {},
    ...rest
  } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const innerInputProps = {
    ...restInput,
    ...inputProps,
  };

  return (
    <TextField
      {...rest}
      name={name}
      helperText={
        showError ? meta.error || meta.submitError : rest.helperText ?? ''
      }
      error={showError}
      inputProps={innerInputProps}
      onChange={onChange}
      value={value}
    />
  );
};

export default TextFieldAdapter;
