import { chatsRootSaga } from '@rtt-libs/chats';
import { saga as notifications } from '@rtt-libs/state-containers';
import { all } from 'redux-saga/effects';
import { saga as categories } from '../categoriesTree';
import { saga as distributors } from '../distributors';
import { saga as moderators } from '../moderators';
import { saga as rtt } from '../rtt';
import { saga as settings } from '../settings';

export default function* rootSaga() {
  yield all([
    categories(),
    distributors(),
    moderators(),
    rtt(),
    settings(),
    notifications(),
    chatsRootSaga(),
  ]);
}
