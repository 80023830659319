import {
  applyMiddleware,
  createStore,
  Middleware,
  Store,
  StoreEnhancer,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reduxPromiseListener from './promiseListener';
import rootReducer, { RootState } from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store: Store<RootState> = createStore(
  rootReducer,
  bindMiddleware([reduxPromiseListener.middleware, sagaMiddleware]),
);

sagaMiddleware.run(rootSaga);

export default store;
