import {
  DistributorDetails,
  DistributorStats,
  Pagination,
} from '@rtt-libs/types';
import { Reducer } from 'redux';
import { Distributor } from '../../api/distributors';
import { Actions } from './actions';
import { mainReducerKey } from './const';
import * as TYPES from './types';

const initialState = {
  loading: false,
  [mainReducerKey]: [] as Distributor[],
  current: undefined as (DistributorDetails & DistributorStats) | undefined,
  currentNotices: null as string | null,
  pagination: {} as Pagination,
  error: undefined as string | undefined,

  currentStats: {} as DistributorStats['stats'],
  statsLoading: false,
  statsError: null as string | null,
  options: [] as Distributor[],
};

export type State = typeof initialState;

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_DISTRIBUTOR_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case TYPES.GET_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        [mainReducerKey]: action.payload,
        pagination: action.meta.pagination,
      };
    case TYPES.GET_DISTRIBUTOR_FAILURE:
    case TYPES.GET_DISTRIBUTOR_DETAILS_FAILURE:
    case TYPES.CHANGE_DISTRIBUTOR_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        [mainReducerKey]: [],
      };
    case TYPES.GET_DISTRIBUTOR_DETAILS_REQUEST:
      return {
        ...state,
        current: undefined,
        loading: true,
        error: undefined,
      };
    case TYPES.GET_DISTRIBUTOR_DETAILS_SUCCESS:
      return {
        ...state,
        current: action.payload,
        currentNotices: action.meta ? action.meta.notices : null,
        loading: false,
      };
    case TYPES.CHANGE_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        current: action.payload,
      };
    case TYPES.CHANGE_DISTRIBUTOR_STATUS_SUCCESS:
      return {
        ...state,
        current: state.current
          ? {
              ...state.current,
              status: action.payload.status,
            }
          : state.current,
        currentNotices: action.payload.notices,
        loading: false,
        [mainReducerKey]: state[mainReducerKey].map(distributor => {
          if (distributor.id === action.meta.id) {
            return {
              ...distributor,
              status: action.payload.status,
            };
          }
          return distributor;
        }),
      };

    case TYPES.DISTRIBUTORS_GET_STATS_REQUEST:
      return {
        ...state,
        statsError: null,
        statsLoading: true,
      };
    case TYPES.DISTRIBUTORS_GET_STATS_SUCCESS:
      return {
        ...state,
        statsLoading: false,
        currentStats: action.payload,
      };
    case TYPES.DISTRIBUTORS_GET_STATS_FAILURE:
      return {
        ...state,
        statsLoading: false,
        statsError: action.payload,
      };

    case TYPES.DISTRIBUTORS_GET_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case TYPES.DISTRIBUTORS_GET_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        options: action.payload,
      };
    case TYPES.DISTRIBUTORS_GET_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
