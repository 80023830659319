/* eslint-disable @typescript-eslint/camelcase */

import {
  formErrorTransform,
  mapPaginatedData,
  TransformAPIError,
} from '@rtt-libs/api-services';
import { OrderParam, Paginated } from '@rtt-libs/types';
import snakeCase from 'lodash/snakeCase';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';

type ModeratorId = number;

type Phone = string;

type Email = string;

type ModeratorRole = 'moderator';

type FetchedModerator = {
  id: ModeratorId;
  phone: Phone;
  login: string;
  email: Email;
  first_name: string;
  last_name: string;
  password: string;
  is_active: boolean;
  role: ModeratorRole;
};

export class Moderator {
  id: ModeratorId;
  phone: Phone;
  login: string;
  email: Email;
  firstName: string;
  lastName: string;
  password: string;
  isActive: boolean;
  role: ModeratorRole;
  /** Added generic key to accept Record<string, unknown> typing  */
  [key: string]: unknown;

  constructor(moderator: FetchedModerator) {
    this.id = moderator.id;
    this.phone = moderator.phone;
    this.login = moderator.login;
    this.email = moderator.email;
    this.firstName = moderator.first_name;
    this.lastName = moderator.last_name;
    this.password = moderator.password;
    this.isActive = moderator.is_active;
    this.role = moderator.role;
  }
}

type SortValue =
  | 'email'
  | 'phone'
  | 'login'
  | 'first_name'
  | 'last_name'
  | 'is_active';

type APISearchParams = Partial<{
  role_name: ModeratorRole;
  search: string;
  order: OrderParam;
  sort: SortValue | string;
  is_active: '0' | '1';
  per_page: number;
  page: number;
}>;

export type GetAllParams = Partial<{
  perPage: number;
  page: number;
}>;

export type SearchParams = Partial<{
  roleName: ModeratorRole;
  orderBy: string;
  search: string;
  order: OrderParam;
  sort: SortValue | string;
  isActive: '1' | '0';
  perPage: number;
  page: number;
}>;

export type CreateModeratorValues = {
  login: string;
  phone: Phone;
  email: Email;
  firstName: string;
  lastName: string;
  role: ModeratorRole;
};

export type EditModeratorValues = CreateModeratorValues & {
  id: ModeratorId;
  password: string;
};

/*
 * Moderator API handlers
 */

export const getAllModerators = (params: GetAllParams) =>
  api
    .get<Paginated<FetchedModerator>>(ENDPOINTS.moderators, {
      params: mapAPISearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapModeratorArray));

export const createModerator = (values: CreateModeratorValues) =>
  api
    .post<FetchedModerator>(
      ENDPOINTS.moderators,
      mapCreateModeratorValues(values),
    )
    .then(({ data }) => new Moderator(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateModeratorErrors);
    });

export const editModerator = (values: EditModeratorValues) =>
  api
    .put<FetchedModerator>(
      `${ENDPOINTS.moderators}/${values.id}`,
      mapEditModeratorValues(values),
    )
    .then(({ data }) => new Moderator(data))
    .catch(e => {
      throw formErrorTransform(e, transformEditModeratorErrors);
    });

export const changeModeratorStatus = ({
  id,
  status,
}: {
  id: ModeratorId;
  status: boolean;
}) =>
  api
    .post<FetchedModerator>(
      `${ENDPOINTS.moderators}/${id}/${ENDPOINTS.moderatorsStatusSuffix}`,
      { is_active: status },
    )
    .then(({ data }) => new Moderator(data))
    .catch(e => {
      throw collectErrorMessagesToError(
        formErrorTransform(e, transformChangeStatusModeratorErrors),
      );
    });

/*
 * Moderator Mappers
 */

function mapModeratorArray(moderatorArray: FetchedModerator[]) {
  return moderatorArray.map(moderator => new Moderator(moderator));
}

function mapAPISearchParams(params: SearchParams): APISearchParams {
  return {
    role_name: params.roleName || undefined,
    search: params.search || undefined,
    sort: params.orderBy && snakeCase(params.orderBy),
    order: params.order,
    is_active: params.isActive || undefined,
    per_page: params.perPage,
    page: params.page,
  };
}

type CreateModeratorAPI = {
  user_type: ModeratorRole;
  login: string;
  phone: string;
  email: string;
  first_name: string;
  last_name: string;
};

type EditModeratorAPI = CreateModeratorAPI & {
  password: string;
};

function mapCreateModeratorValues(
  values: CreateModeratorValues,
): CreateModeratorAPI {
  return {
    login: values.login,
    phone: values.phone,
    email: values.email,
    first_name: values.firstName,
    last_name: values.lastName,
    user_type: values.role,
  };
}

function mapEditModeratorValues({
  password,
  ...values
}: EditModeratorValues): EditModeratorAPI {
  return {
    password,
    ...mapCreateModeratorValues(values),
  };
}

const transformCreateModeratorErrors: TransformAPIError<
  Record<keyof CreateModeratorAPI, string | string[]>,
  Record<keyof CreateModeratorValues, string>
> = function transformCreateModeratorErrors(e) {
  return {
    login: e.login?.toString(),
    phone: e.phone?.toString(),
    email: e.email?.toString(),
    firstName: e.first_name?.toString(),
    lastName: e.last_name?.toString(),
  };
};

const transformEditModeratorErrors: TransformAPIError<
  Record<keyof EditModeratorAPI, string | string[]>,
  Record<keyof EditModeratorValues, string>
> = function transformEditModeratorErrors(e) {
  return {
    password: e.password?.toString(),
    ...transformCreateModeratorErrors(e),
  };
};

const transformChangeStatusModeratorErrors: TransformAPIError<
  Record<'is_active', string | string[]>,
  Record<'isActive', string>
> = function transformChangeStatusModeratorErrors(e) {
  return {
    isActive: e.is_active?.toString(),
  };
};

function collectErrorMessagesToError(errors: Record<string, string>) {
  return new Error(Object.values(errors).join(' '));
}
