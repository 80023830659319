import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import React, { MouseEvent } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

type Props = Partial<{
  onClick?(e: MouseEvent<HTMLTableRowElement>): void;
  data: React.ReactNodeArray;
  className?: string;
}>;

/**
 * Wraps table row with onClick callback.
 */
const TableRowClickable: React.FC<Props> = ({
  onClick,
  data,
  children,
  className,
}) => {
  const styles = useStyles();

  if (children && data) {
    // eslint-disable-next-line no-console
    console.warn(
      'TableRow component takes children OR data.\nIf both passed renders only children.',
    );
  }

  return (
    <TableRow
      hover={!!onClick}
      onClick={onClick}
      className={clsx(onClick && styles.root, className)}
    >
      {children ||
        (Array.isArray(data) &&
          data.map((value, id) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableCell key={id}>{value}</TableCell>
          )))}
    </TableRow>
  );
};

export default TableRowClickable;
