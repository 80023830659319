import type { Pagination } from '@rtt-libs/types';
import get from 'lodash/get';
import { createSelector, Selector } from 'reselect';
import type { ProductInfo } from '../types';
import { branchName } from './const';
import type { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const getProductsCollectionSel: Selector<
  PartialRootState,
  Record<string, ProductInfo>
> = (state: PartialRootState) => get(state, [branchName, 'collection']);

const getProductIdsSel: Selector<PartialRootState, string[]> = (
  state: PartialRootState,
) => get(state, [branchName, 'idList']);

const selectProductsError: Selector<PartialRootState, string | null> = (
  state: PartialRootState,
) => get(state, [branchName, 'error']);

const getProductsLoadingSel: Selector<PartialRootState, boolean> = (
  state: PartialRootState,
) => get(state, [branchName, 'loading']);

const selectProductPagination: Selector<PartialRootState, Pagination> = (
  state: PartialRootState,
) => get(state, [branchName, 'pagination']);

export const selectProductsForRtt = createSelector(
  [
    getProductsCollectionSel,
    getProductIdsSel,
    selectProductPagination,
    getProductsLoadingSel,
    selectProductsError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: ids.map(id => collection[id]),
    pagination,
    loading,
    error,
  }),
);
