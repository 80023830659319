import React from 'react';
import { Moderator } from '../../../api/moderators';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const ModeratorContacts: React.FC<Props> = ({ id, value }) => {
  if (value instanceof Moderator) {
    return (
      <>
        <div>{value.phone}</div>
        <div>{value.email}</div>
      </>
    );
  }
  return null;
};

export default ModeratorContacts;
