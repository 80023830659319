import pickBy from 'lodash/pickBy';
import { call, put, select } from 'redux-saga/effects';
import { getChannelInfoAPI } from '../../../api/channelList';
import type { Channel, SystemChannelMessage } from '../../../types';
import {
  chatReceiveSystemNotification,
  resolveChatFailure,
  resolveChatSuccess,
  updateChannel,
} from '../../actions';
import { selectChatListId } from '../../selectors';

function* shouldAddChatOnNewMessage(payload: SystemChannelMessage) {
  if (payload.type !== 'new_message') return false;

  const channelList: Channel['id'][] = yield select(selectChatListId);

  if (channelList.includes(payload.channelId)) return false;

  return true;
}

export function* addMissingChatOnNewMessageWorker({
  payload,
}: ReturnType<typeof chatReceiveSystemNotification>) {
  if (!(yield* shouldAddChatOnNewMessage(payload))) return;

  try {
    const missingChannelWithNewMessage: Channel = yield call(
      getChannelInfoAPI,
      { channelId: payload.channelId, channelType: payload.channelType },
    );

    yield put(resolveChatSuccess(missingChannelWithNewMessage));
  } catch (e) {
    yield put(resolveChatFailure(e));
  }
}

export function* updateChannelWorker({
  payload: { channelId, type, message, channelType, user, ...rest },
}: ReturnType<typeof chatReceiveSystemNotification>) {
  if (type !== 'update_channel') return;

  const updatedChannelData = pickBy(rest, v => v !== undefined);

  yield put(updateChannel({ id: channelId, ...updatedChannelData }));
}
