import get from 'lodash/get';
import { branchName } from './const';
import { NotificationsSliceState } from './reducer';

// Copied from react-redux types
type Selector<S, TProps, TOwnProps = null> = TOwnProps extends null | undefined
  ? (state: S) => TProps
  : (state: S, ownProps: TOwnProps) => TProps;

export interface PartialRootState {
  [branchName]: NotificationsSliceState;
}

export const selectNotifications: Selector<
  PartialRootState,
  NotificationsSliceState['data']
> = state => get(state, [branchName, 'data']);

export const selectNotificationsLoading: Selector<
  PartialRootState,
  NotificationsSliceState['loading']
> = state => get(state, [branchName, 'loading']);

export const selectNotificationsError: Selector<
  PartialRootState,
  NotificationsSliceState['error']
> = state => get(state, [branchName, 'error']);
