import Echo from 'laravel-echo';
import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as TYPES from '../../types';
import {
  connectToChannel,
  connectToSystem,
  disconnectFromChannel,
  disconnectFromSystem,
} from './echoWrapper';
import { systemNotificationWorker } from './notifications';
import {
  addMissingChatOnNewMessageWorker,
  updateChannelWorker,
} from './systemMessages';

function* connectChannelWorker(
  echo: Echo,
  action: ReturnType<typeof actions.connectToChannel>,
) {
  yield call(connectToChannel, echo, action.payload);
}

function* disconnectChannelWorker(
  echo: Echo,
  action: ReturnType<typeof actions.disconnectFromChannel>,
) {
  yield call(disconnectFromChannel, echo, action.payload);
}

function* connectSystemChannelWorker(
  echo: Echo,
  action: ReturnType<typeof actions.connectToSystemChannel>,
) {
  yield call(connectToSystem, echo, action.payload);
}

function* disconnectSystemChannelWorker(
  echo: Echo,
  action: ReturnType<typeof actions.disconnectFromSystemChannel>,
) {
  yield call(disconnectFromSystem, echo, action.payload);
}

export default function* echoSubscriptionListener(echo: Echo) {
  yield takeEvery(TYPES.CHATS_CONNECT_CHANNEL, connectChannelWorker, echo);
  yield takeEvery(TYPES.CHATS_CONNECT_SYSTEM, connectSystemChannelWorker, echo);
  yield takeEvery(
    TYPES.CHATS_DISCONNECT_CHANNEL,
    disconnectChannelWorker,
    echo,
  );
  yield takeEvery(
    TYPES.CHATS_DISCONNECT_SYSTEM,
    disconnectSystemChannelWorker,
    echo,
  );

  yield takeEvery(
    TYPES.CHATS_RECEIVE_SYSTEM_NOTIFICATION,
    addMissingChatOnNewMessageWorker,
  );

  yield takeEvery(TYPES.CHATS_RECEIVE_SYSTEM_NOTIFICATION, updateChannelWorker);

  yield takeLeading(
    TYPES.CHATS_RECEIVE_SYSTEM_NOTIFICATION,
    systemNotificationWorker,
  );
}
