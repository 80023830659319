import type { AttachmentDocumentType } from '@rtt-libs/types';
import { at, get } from 'lodash';
import type { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import type { Channel, Message, OpponentType } from '../types';
import type { ChatsState, MessagesByChannelState } from './reducer';

export const CHAT_STORE_KEY = 'chats';
const emptyArray: unknown[] = [];

export type PartialRootState = {
  [CHAT_STORE_KEY]: ChatsState;
};

export const selectChatsState: Selector<
  PartialRootState,
  ChatsState['channels']
> = state => state[CHAT_STORE_KEY].channels;

export const selectMessagesState: Selector<
  PartialRootState,
  ChatsState['messages']
> = state => state[CHAT_STORE_KEY].messages;

export const selectMessagesCollection: Selector<
  PartialRootState,
  MessagesByChannelState
> = (state: PartialRootState) =>
  get(state, [CHAT_STORE_KEY, 'messages', 'messagesByChannel']);

const selectMessagesByChannelId = createSelector(
  [selectMessagesCollection, (_: PartialRootState, id?: string) => id],
  (collection, id) => {
    if (id === undefined) return emptyArray as Message[];

    const channelMessagesState = get(collection, id);

    if (channelMessagesState === undefined) return emptyArray as Message[];

    return at(channelMessagesState.collection, channelMessagesState.idList);
  },
);

export const selectMessageLoading: Selector<PartialRootState, boolean> = (
  state: PartialRootState,
) => get(state, [CHAT_STORE_KEY, 'messages', 'loading']);

export const selectMessageError: Selector<PartialRootState, string | null> = (
  state: PartialRootState,
) => get(state, [CHAT_STORE_KEY, 'messages', 'error']);

export const selectMessagesByChannel = (id?: string) => (
  state: PartialRootState,
) => selectMessagesByChannelId(state, id);

export const selectChatHasMore: Selector<PartialRootState, boolean> = (
  state: PartialRootState,
) => get(state, [CHAT_STORE_KEY, 'channels', 'hasMore']);

export const selectChatLoading: Selector<PartialRootState, boolean> = (
  state: PartialRootState,
) => get(state, [CHAT_STORE_KEY, 'channels', 'loading']);

export const selectChatError: Selector<PartialRootState, string | null> = (
  state: PartialRootState,
) => get(state, [CHAT_STORE_KEY, 'channels', 'error']);

export const selectChatCollection: Selector<
  PartialRootState,
  Record<string, Channel>
> = (state: PartialRootState) =>
  get(state, [CHAT_STORE_KEY, 'channels', 'collection']);

export const selectChatListId: Selector<PartialRootState, string[]> = (
  state: PartialRootState,
) => get(state, [CHAT_STORE_KEY, 'channels', 'chatIdList']);

export const selectChatData = createSelector(
  [selectChatCollection, selectChatListId],
  (collection, idList) => at(collection, idList),
);

type ChatOpponentSelectorOptions = {
  opponentType: OpponentType;
  channelId?: string;
};

const selectChatOpponentFromState = createSelector(
  [
    selectChatCollection,
    (
      _: PartialRootState,
      { opponentType, channelId }: ChatOpponentSelectorOptions,
    ) => ({ opponentType, channelId }),
  ],
  (collection, { channelId = '', opponentType }) => {
    return get(collection, [channelId, opponentType]);
  },
);

export const selectChatOpponent = (options: ChatOpponentSelectorOptions) => (
  state: PartialRootState,
) => selectChatOpponentFromState(state, options);

const selectRttIdByChannelId = createSelector(
  [selectChatCollection, (_: PartialRootState, id?: string) => id],
  (collection, id = '') => get(collection, [id, 'rtt'])?.id,
);

export const selectRttIdByChannel = (id?: string) => (
  state: PartialRootState,
) => selectRttIdByChannelId(state, id);

const selectResolvedStateByChannelId = createSelector(
  [selectChatCollection, (_: PartialRootState, id?: string) => id],
  (collection, id = '') => get(collection, id)?.isResolved ?? true,
);
export const selectResolvedStateByChannel = (id?: string) => (
  state: PartialRootState,
) => selectResolvedStateByChannelId(state, id);

const selectAssignableChannelId = createSelector(
  [selectChatCollection, (_: PartialRootState, id?: string) => id],
  (collection, id = '') => {
    const channel = get(collection, id);
    if (!channel) return false;

    if (channel.isResolved === undefined) return false;

    return !channel.isResolved && !channel.admin;
  },
);
export const selectAssignableChannel = (id?: string) => (
  state: PartialRootState,
) => selectAssignableChannelId(state, id);

const selectChannelAdminById = createSelector(
  [selectChatCollection, (_: PartialRootState, id?: string) => id],
  (collection, id = '') => {
    const channel = get(collection, id);

    return channel?.admin;
  },
);
export const selectChannelAdmin = (id?: string) => (state: PartialRootState) =>
  selectChannelAdminById(state, id);

export const selectLastChatId = createSelector(
  selectChatListId,
  idList => idList[idList.length - 1] as string | undefined,
);

export const selectResolveLoading: Selector<
  PartialRootState,
  boolean
> = state => state[CHAT_STORE_KEY].channels.resolveLoading;

export const selectLastUploadedImage: Selector<
  PartialRootState,
  AttachmentDocumentType | undefined
> = state => get(state, [CHAT_STORE_KEY, 'messages', 'lastUploadedImage']);

export const selectImageUploadLoading: Selector<
  PartialRootState,
  boolean
> = state => get(state, [CHAT_STORE_KEY, 'messages', 'imageUploadLoading']);

export const selectImageUploadError: Selector<
  PartialRootState,
  string | null
> = state => get(state, [CHAT_STORE_KEY, 'messages', 'imageUploadError']);
