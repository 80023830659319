export const SEARCH_MODERATORS_REQUEST = `@rtt[D]/SEARCH_MODERATORS_REQUEST` as const;
export const SEARCH_MODERATORS_SUCCESS = `@rtt[D]/SEARCH_MODERATORS_SUCCESS` as const;
export const SEARCH_MODERATORS_FAILURE = `@rtt[D]/SEARCH_MODERATORS_FAILURE` as const;

export const GET_MODERATORS_REQUEST = `@rtt[D]/GET_MODERATORS_REQUEST` as const;
export const GET_MODERATORS_SUCCESS = `@rtt[D]/GET_MODERATORS_SUCCESS` as const;
export const GET_MODERATORS_FAILURE = `@rtt[D]/GET_MODERATORS_FAILURE` as const;

export const CREATE_MODERATOR_REQUEST = `@rtt[D]/CREATE_MODERATOR_REQUEST` as const;
export const CREATE_MODERATOR_RESPONSE = `@rtt[D]/CREATE_MODERATOR_RESPONSE` as const;
export const CREATE_MODERATOR_SUCCESS = `@rtt[D]/CREATE_MODERATOR_SUCCESS` as const;
export const CREATE_MODERATOR_FAILURE = `@rtt[D]/CREATE_MODERATOR_FAILURE` as const;

export const EDIT_MODERATOR_REQUEST = `@rtt[D]/EDIT_MODERATOR_REQUEST` as const;
export const EDIT_MODERATOR_RESPONSE = `@rtt[D]/EDIT_MODERATOR_RESPONSE` as const;
export const EDIT_MODERATOR_SUCCESS = `@rtt[D]/EDIT_MODERATOR_SUCCESS` as const;
export const EDIT_MODERATOR_FAILURE = `@rtt[D]/EDIT_MODERATOR_FAILURE` as const;

export const CHANGE_STATUS_MODERATOR_REQUEST = `@rtt[D]/CHANGE_STATUS_MODERATOR_REQUEST` as const;
export const CHANGE_STATUS_MODERATOR_SUCCESS = `@rtt[D]/CHANGE_STATUS_MODERATOR_SUCCESS` as const;
export const CHANGE_STATUS_MODERATOR_FAILURE = `@rtt[D]/CHANGE_STATUS_MODERATOR_FAILURE` as const;
