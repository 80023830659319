import { ColumnConfig, Status } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import { getRttAvailableStatuses } from '../../utils/availableStatus';
import RttActionsMenu from './RttActionsMenu';
import { Rtt } from '../../api/rtt';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const rttTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'status',
    label: t('admin.tables.common.status', 'Status'),
    options: {
      component: Status,
    },
  },
  {
    id: 'contactName',
    label: t('admin.tables.common.contactName', 'Contact Name'),
    options: {
      sort: false,
    },
  },
  {
    id: 'phone',
    label: t('admin.tables.common.phone', 'Phone'),
    options: {
      align: 'right',
      sort: false,
    },
  },
  {
    id: 'name',
    label: t('admin.tables.rtt.companyName', 'Company Name'),
  },
  {
    id: 'address',
    label: t('admin.tables.common.address', 'Address'),
  },
  {
    id: 'createdAt',
    label: t('admin.tables.common.registrationDate', 'RegistrationDate'),
    options: {
      sort: false,
      format: (date: string) => t('formattedDate', { date }),
    },
  },
  {
    id: 'actions',
    label: '',
    options: {
      align: 'right',
      format: (_: unknown, entry: Rtt) =>
        getRttAvailableStatuses(entry.status.value),
      component: RttActionsMenu,
      sort: false,
    },
  },
];

export default rttTableConfig;
