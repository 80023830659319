import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

type Props = RouteProps & {
  /** Used function to calculate permissions on every render */
  checkAccess: boolean | (() => boolean);
  redirectPath: string;
};

const PrivateRoute: React.FC<Props> = ({
  checkAccess,
  redirectPath,
  ...rest
}) => {
  if (typeof checkAccess === 'function' ? !checkAccess() : !checkAccess) {
    if (/^https?:\/\//.test(redirectPath)) {
      window.location.href = redirectPath;
      return null;
    }

    return (
      <Route>
        <Redirect to={redirectPath} />
      </Route>
    );
  }

  return <Route {...rest} />;
};

export default PrivateRoute;
