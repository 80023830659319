import { TableCell } from '@material-ui/core';
import React from 'react';
import TableRow from './TableRow';
import { ColumnConfig } from './types';

type Props = {
  data: Record<string, unknown>[];
  onClick?(id: string | number): void;
  entryIdKey?: string;
  columnsConfig: ColumnConfig[];
};

const TableRowListCG: React.FC<Props> = ({
  data,
  onClick,
  entryIdKey = 'id',
  columnsConfig,
}) => {
  return (
    <>
      {data.map(entry => {
        const key = String(entry[entryIdKey]);
        const handleClick = () => {
          if (typeof onClick === 'function') onClick(key);
        };

        return (
          <TableRow onClick={onClick && handleClick} key={key}>
            {columnsConfig.map(
              ({
                id,
                options: { align, format, component: Component } = {},
              }) => {
                let value = entry[id];
                value = format ? format(value, entry) : value;

                return (
                  <TableCell key={id} align={align}>
                    {Component ? (
                      <Component id={key} value={value} />
                    ) : (
                      (value as React.ReactNode)
                    )}
                  </TableCell>
                );
              },
            )}
          </TableRow>
        );
      })}
    </>
  );
};

export default TableRowListCG;
