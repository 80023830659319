import { ENDPOINTS } from '../environment';
import api from './setup';
import { UserInfoType, UsedLocales } from '../types';
import genLocaleHeader from './localeHeader';

const getUserProfile = (token: string, locale?: UsedLocales) => {
  return api.get<UserInfoType>(ENDPOINTS.profile, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...genLocaleHeader(locale),
    },
  });
};

export default getUserProfile;
