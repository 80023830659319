import type { AttachmentDocumentType } from '@rtt-libs/types';
import { groupBy, keyBy, map, mapValues, omit } from 'lodash';
import union from 'lodash/union';
import { combineReducers } from 'redux';
import type { Reducer } from 'redux';
import type { Channel, Message } from '../types';
import type { ChatsAction } from './actions';
import * as TYPES from './types';

export const CHANNELS_INITIAL_STATE = {
  collection: {} as Record<Channel['id'], Channel>,
  loading: false,
  error: null as string | null,
  chatIdList: [] as Channel['id'][],
  supportQuestionIdList: [] as Channel['id'][],
  resolveLoading: false,
  hasMore: false,
  currentChannelId: undefined as Channel['id'] | undefined,
};

type ChannelMessagesState = {
  idList: Message['id'][];
  collection: Record<Message['id'], Message>;
};

const MESSAGES_BY_CHANNEL_INITIAL_STATE = {} as Record<
  Channel['id'],
  ChannelMessagesState | undefined
>;

export type MessagesByChannelState = typeof MESSAGES_BY_CHANNEL_INITIAL_STATE;

const messagesByChannelReducer: Reducer<MessagesByChannelState, ChatsAction> = (
  state = MESSAGES_BY_CHANNEL_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case TYPES.CHATS_MESSAGES_GET_LIST_SUCCESS: {
      const messageArrayByChannelId = groupBy(action.payload.data, 'channelId');

      const normalizedChannelMessagesState = mapValues(
        messageArrayByChannelId,
        messages => ({
          idList: map(messages, 'id'),
          collection: keyBy(messages, 'id'),
        }),
      );

      return {
        ...state,
        ...normalizedChannelMessagesState,
      };
    }
    case TYPES.CHATS_CHANNELS_RESOLVE_SUCCESS: {
      const newLastMessage = action.payload.lastMessage;
      const channelState = state[action.payload.id];

      const currentLastMessageId = channelState?.idList[0];
      const safeIdList = channelState?.idList || [];

      const newMessages =
        newLastMessage && newLastMessage.id !== currentLastMessageId
          ? union([newLastMessage.id], safeIdList)
          : safeIdList;

      return {
        ...state,
        [action.payload.id]: {
          ...channelState,
          idList: newMessages,
          collection: {
            ...channelState?.collection,
            ...(newLastMessage
              ? { [newLastMessage.id]: newLastMessage }
              : undefined),
          },
        },
      };
    }

    case TYPES.CHATS_MESSAGES_SEND_REQUEST: {
      const { tempMessage } = action.payload;
      const targetChannelId = tempMessage.channelId;

      return {
        ...state,
        [targetChannelId]: {
          ...state[targetChannelId],
          idList: union([tempMessage.id], state[targetChannelId]?.idList),
          collection: {
            ...state[targetChannelId]?.collection,
            [tempMessage.id]: tempMessage,
          },
        },
      };
    }
    case TYPES.CHATS_MESSAGES_SEND_SUCCESS: {
      const { payload, meta: tempMessageId } = action;
      const targetChannelId = payload.channelId;

      const idListCopy = state[targetChannelId]?.idList?.slice() || [];

      const indexOfTempMessage = idListCopy.indexOf(tempMessageId);
      // Replacing temp message
      idListCopy.splice(indexOfTempMessage, 1, payload.id);

      return {
        ...state,
        [targetChannelId]: {
          ...state[targetChannelId],
          idList: idListCopy,
          collection: omit(
            {
              ...state[targetChannelId]?.collection,
              [payload.id]: payload,
            },
            tempMessageId,
          ),
        },
      };
    }

    case TYPES.CHATS_MESSAGES_EDIT_SUCCESS: {
      const targetChannelId = action.payload.channelId;
      const targetChannelState = state[targetChannelId];

      return {
        ...state,
        [targetChannelId]: targetChannelState && {
          ...targetChannelState,
          collection: {
            ...targetChannelState.collection,
            [action.payload.id]: action.payload,
          },
        },
      };
    }

    case TYPES.CHATS_MESSAGES_GET_MORE_LIST_SUCCESS: {
      const targetChannelId = action.payload.meta.channelId;

      return {
        ...state,
        [targetChannelId]: {
          idList: union(
            state[targetChannelId]?.idList || [],
            action.payload.data.map(message => message.id),
          ),
          collection: {
            ...state[targetChannelId]?.collection,
            ...keyBy(action.payload.data, 'id'),
          },
        },
      };
    }

    case TYPES.CHATS_RECEIVE_MESSAGE: {
      const targetChannelId = action.payload.channelId;

      return {
        ...state,
        [targetChannelId]: {
          ...state[targetChannelId],
          idList: union([action.payload.id], state[targetChannelId]?.idList),
          collection: {
            ...state[targetChannelId]?.collection,
            [action.payload.id]: action.payload,
          },
        },
      };
    }

    case TYPES.CHATS_RECEIVE_SYSTEM_NOTIFICATION: {
      const targetChannelId = action.payload.channelId;
      const targetChannelState = state[targetChannelId];
      const updatedMessage = action.payload.message;

      if (targetChannelState && updatedMessage) {
        return {
          ...state,
          [targetChannelId]: {
            ...targetChannelState,
            collection: {
              ...targetChannelState.collection,
              [updatedMessage.id]: updatedMessage,
            },
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export const MESSAGES_INITIAL_STATE = {
  loading: false,
  error: null as string | null,
  hasMore: false,
  lastUploadedImage: undefined as AttachmentDocumentType | undefined,
  imageUploadLoading: false,
  imageUploadError: null as string | null,

  messagesByChannel: MESSAGES_BY_CHANNEL_INITIAL_STATE,
};

const channelsReducer: Reducer<typeof CHANNELS_INITIAL_STATE, ChatsAction> = (
  state = CHANNELS_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case TYPES.CHATS_CHANNELS_GET_LIST_REQUEST:
    case TYPES.CHATS_CHANNELS_GET_MORE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case TYPES.CHATS_CHANNELS_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: {
          ...state.collection,
          ...keyBy(action.payload.data, 'id'),
        },
        chatIdList: map(action.payload.data, 'id'),
        hasMore: action.payload.meta.hasMore,
      };
    case TYPES.CHATS_CHANNELS_GET_MORE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: {
          ...state.collection,
          ...keyBy(action.payload.data, 'id'),
        },
        chatIdList: state.chatIdList.concat(map(action.payload.data, 'id')),
        hasMore: action.payload.meta.hasMore,
      };

    case TYPES.CHATS_CHANNELS_GET_LIST_FAILURE:
    case TYPES.CHATS_CHANNELS_GET_MORE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.CHATS_CHANNELS_RESOLVE_REQUEST:
      return {
        ...state,
        resolveLoading: true,
        error: null,
      };
    case TYPES.CHATS_CHANNELS_RESOLVE_SUCCESS:
      return {
        ...state,
        resolveLoading: false,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
        chatIdList: union([action.payload.id], state.chatIdList),
      };
    case TYPES.CHATS_CHANNELS_RESOLVE_FAILURE:
      return {
        ...state,
        resolveLoading: false,
        error: action.payload,
      };

    case TYPES.CHATS_CHANNELS_MAKE_READ_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload]: {
            ...state.collection[action.payload],
            hasUnseenMessages: false,
          },
        },
      };

    case TYPES.CHATS_CONNECT_CHANNEL:
      return {
        ...state,
        currentChannelId: action.payload,
      };

    case TYPES.CHATS_DISCONNECT_CHANNEL:
      return {
        ...state,
        currentChannelId: undefined,
      };

    case TYPES.CHATS_RECEIVE_SYSTEM_NOTIFICATION: {
      const isChatInactive =
        state.currentChannelId !== action.payload.channelId;

      if (
        !!state.collection[action.payload.channelId] &&
        action.payload.type === 'new_message'
      ) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [action.payload.channelId]: {
              ...state.collection[action.payload.channelId],
              hasUnseenMessages: isChatInactive,
              lastMessage: action.payload.message,
              lastMessageDate: action.payload.message?.date,
            },
          },
        };
      }

      if (
        !!state.collection[action.payload.channelId] &&
        action.payload.type === 'read_message'
      ) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [action.payload.channelId]: {
              ...state.collection[action.payload.channelId],
              lastMessage: action.payload.message,
              lastMessageDate: action.payload.message?.date,
            },
          },
        };
      }

      return state;
    }

    case TYPES.CHATS_MESSAGES_SEND_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.channelId]: {
            ...state.collection[action.payload.channelId],
            lastMessage: action.payload,
            lastMessageDate: action.payload.date,
          },
        },
      };

    case TYPES.CHATS_SUPPORT_CLOSE_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload]: {
            ...state.collection[action.payload],
            isResolved: true,
          },
        },
      };

    case TYPES.CHATS_SUPPORT_ASSIGN_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };

    case TYPES.CHATS_CHANNELS_UPDATE_DATA:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: {
            ...state.collection[action.payload.id],
            ...action.payload,
          },
        },
      };

    default:
      return state;
  }
};

const messagesReducer: Reducer<typeof MESSAGES_INITIAL_STATE, ChatsAction> = (
  state = MESSAGES_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case TYPES.CHATS_MESSAGES_GET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case TYPES.CHATS_MESSAGES_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasMore: action.payload.meta.hasMore,
        messagesByChannel: messagesByChannelReducer(
          state.messagesByChannel,
          action,
        ),
      };

    case TYPES.CHATS_MESSAGES_GET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.CHATS_CHANNELS_RESOLVE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentChannelId: action.payload.id,
        messagesByChannel: messagesByChannelReducer(
          state.messagesByChannel,
          action,
        ),
      };

    case TYPES.CHATS_MESSAGES_SEND_REQUEST:
    case TYPES.CHATS_MESSAGES_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        lastUploadedImage: undefined,
        imageUploadLoading: false,
        messagesByChannel: messagesByChannelReducer(
          state.messagesByChannel,
          action,
        ),
      };

    case TYPES.CHATS_MESSAGES_SEND_SUCCESS:
    case TYPES.CHATS_MESSAGES_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        messagesByChannel: messagesByChannelReducer(
          state.messagesByChannel,
          action,
        ),
      };

    case TYPES.CHATS_MESSAGES_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.CHATS_MESSAGES_GET_MORE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.CHATS_MESSAGES_GET_MORE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasMore: action.payload.meta.hasMore,
        messagesByChannel: messagesByChannelReducer(
          state.messagesByChannel,
          action,
        ),
      };
    case TYPES.CHATS_MESSAGES_GET_MORE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.CHATS_RECEIVE_MESSAGE:
      return {
        ...state,
        messagesByChannel: messagesByChannelReducer(
          state.messagesByChannel,
          action,
        ),
      };

    case TYPES.CHATS_IMAGES_UPLOAD_REQUEST:
      return {
        ...state,
        lastUploadedImage: undefined,
        imageUploadLoading: true,
        imageUploadError: null,
      };

    case TYPES.CHATS_IMAGES_UPLOAD_SUCCESS: {
      if (state.imageUploadLoading)
        return {
          ...state,
          lastUploadedImage: action.payload,
        };

      return state;
    }

    case TYPES.CHATS_IMAGES_UPLOAD_FAILURE: {
      if (state.imageUploadLoading)
        return {
          ...state,
          imageUploadLoading: false,
          imageUploadError: action.payload,
        };

      return state;
    }

    case TYPES.CHATS_IMAGES_UPLOAD_CLEANUP:
      return {
        ...state,
        lastUploadedImage: undefined,
        imageUploadLoading: false,
        imageUploadError: null,
      };

    // Clean state of image id on open chats page
    case TYPES.CHATS_CHANNELS_GET_LIST_REQUEST:
      return {
        ...state,
        lastUploadedImage: undefined,
      };

    case TYPES.CHATS_RECEIVE_SYSTEM_NOTIFICATION: {
      if (action.payload.type !== 'read_message') return state;

      return {
        ...state,
        messagesByChannel: messagesByChannelReducer(
          state.messagesByChannel,
          action,
        ),
      };
    }

    default:
      return state;
  }
};

export type ChatsState = {
  channels: typeof CHANNELS_INITIAL_STATE;
  messages: typeof MESSAGES_INITIAL_STATE;
};

const reducer = combineReducers<ChatsState>({
  channels: channelsReducer,
  messages: messagesReducer,
});

export default reducer;
