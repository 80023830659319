import { Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModeratorsRequest } from '../duck/actions';
import { selectModerators } from '../duck/selectors';
import { SearchParams } from '../../api/moderators';
import ModeratorsTable from './ModeratorTable';

/**
 * Container to fetch & filter moderators & render them in table.
 */
const ModeratorContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { data, pagination, loading, error } = useSelector(selectModerators);

  const onTableStateChange = useCallback(
    (options: SearchParams) => {
      dispatch(getModeratorsRequest(options));
    },
    [dispatch],
  );

  return (
    <>
      {error && <Typography color="error">{error}</Typography>}
      <ModeratorsTable
        moderators={data}
        pagination={pagination}
        loading={loading}
        onStateChange={onTableStateChange}
      />
    </>
  );
};

export default ModeratorContainer;
