import { AttachmentDocumentType } from '@rtt-libs/types';
import { mapSystemMessage, Message } from '../api/mappers';
import type {
  Channel,
  ChannelIdentity,
  ChannelsInfinitePaginationParams,
  ChannelType,
  InfinitePaginationWrapper,
  MessagesInfinitePaginationParams,
  PostChatMessageData,
  ResolveRequestData,
  SocketMessage,
  SocketSystemMessage,
  UUID,
  PostMessageData,
} from '../types';
import * as TYPES from './types';

export const chatReceiveMessage = (_: string, data: SocketMessage) => ({
  type: TYPES.CHATS_RECEIVE_MESSAGE,
  payload: data?.payload && new Message(data.payload),
});

export const chatReceiveSystemNotification = (
  _: string,
  data: SocketSystemMessage,
) => ({
  type: TYPES.CHATS_RECEIVE_SYSTEM_NOTIFICATION,
  payload: data?.payload && mapSystemMessage(data.payload),
});

export const chatReceiveError = (channel: string, data: unknown) => ({
  type: TYPES.CHATS_RECEIVE_ERROR,
  payload: {
    channel,
    data,
  },
});

export const emitSocketEvent = (event: string, data: unknown) => ({
  type: TYPES.CHATS_EMIT_EVENT,
  payload: {
    event,
    data,
  },
});

export type EmitSocketEventAction = ReturnType<typeof emitSocketEvent>;

export const connectToChannel = (channelId: string) => ({
  type: TYPES.CHATS_CONNECT_CHANNEL,
  payload: channelId,
});

export const disconnectFromChannel = (channelId: string) => ({
  type: TYPES.CHATS_DISCONNECT_CHANNEL,
  payload: channelId,
});

export const connectToSystemChannel = (userId: number) => ({
  type: TYPES.CHATS_CONNECT_SYSTEM,
  payload: userId,
});

export const disconnectFromSystemChannel = (userId: number) => ({
  type: TYPES.CHATS_DISCONNECT_SYSTEM,
  payload: userId,
});

export const updateChannel = (
  payload: Partial<Channel> & Pick<Channel, 'id'>,
) => ({
  type: TYPES.CHATS_CHANNELS_UPDATE_DATA,
  payload,
});

export type SocketChatsAction = ReturnType<
  | typeof emitSocketEvent
  | typeof chatReceiveMessage
  | typeof chatReceiveSystemNotification
  | typeof chatReceiveError
  | typeof connectToChannel
  | typeof disconnectFromChannel
  | typeof updateChannel
>;

/*
 * REST action creators
 */

export const getChatListRequest = (
  channelType: ChannelType,
  payload?: ChannelsInfinitePaginationParams,
) => ({
  type: TYPES.CHATS_CHANNELS_GET_LIST_REQUEST,
  payload,
  meta: channelType,
});

export const getChatListSuccess = (
  payload: InfinitePaginationWrapper<Channel>,
) => ({
  type: TYPES.CHATS_CHANNELS_GET_LIST_SUCCESS,
  payload,
});

export const getChatListFailure = (error: string) => ({
  type: TYPES.CHATS_CHANNELS_GET_LIST_FAILURE,
  payload: error,
});

export const loadMoreChatListRequest = (
  channelType: ChannelType,
  payload?: ChannelsInfinitePaginationParams,
) => ({
  type: TYPES.CHATS_CHANNELS_GET_MORE_LIST_REQUEST,
  payload,
  meta: channelType,
});

export const loadMoreChatListSuccess = (
  payload: InfinitePaginationWrapper<Channel>,
) => ({
  type: TYPES.CHATS_CHANNELS_GET_MORE_LIST_SUCCESS,
  payload,
});

export const loadMoreChatListFailure = (error: string) => ({
  type: TYPES.CHATS_CHANNELS_GET_MORE_LIST_FAILURE,
  payload: error,
});

export const resolveChatRequest = (payload: ResolveRequestData) => ({
  type: TYPES.CHATS_CHANNELS_RESOLVE_REQUEST,
  payload,
});

export const resolveChatSuccess = (payload: Channel) => ({
  type: TYPES.CHATS_CHANNELS_RESOLVE_SUCCESS,
  payload,
});

export const resolveChatFailure = (error: string) => ({
  type: TYPES.CHATS_CHANNELS_RESOLVE_FAILURE,
  payload: error,
});

export const closeSupportChannelRequest = (payload: string) => ({
  type: TYPES.CHATS_SUPPORT_CLOSE_REQUEST,
  payload,
});

export const closeSupportChannelSuccess = (payload: string) => ({
  type: TYPES.CHATS_SUPPORT_CLOSE_SUCCESS,
  payload,
});

export const closeSupportChannelFailure = (error: string) => ({
  type: TYPES.CHATS_SUPPORT_CLOSE_FAILURE,
  payload: error,
});

export const assignSupportChannelRequest = (payload: string) => ({
  type: TYPES.CHATS_SUPPORT_ASSIGN_REQUEST,
  payload,
});

export const assignSupportChannelSuccess = (payload: Channel) => ({
  type: TYPES.CHATS_SUPPORT_ASSIGN_SUCCESS,
  payload,
});

export const assignSupportChannelFailure = (error: string) => ({
  type: TYPES.CHATS_SUPPORT_ASSIGN_FAILURE,
  payload: error,
});

export const makeChannelReadRequest = (payload: ChannelIdentity) => ({
  type: TYPES.CHATS_CHANNELS_MAKE_READ_REQUEST,
  payload,
});

export const makeChannelReadSuccess = (payload: Channel['id']) => ({
  type: TYPES.CHATS_CHANNELS_MAKE_READ_SUCCESS,
  payload,
});

export const makeChannelReadFailure = (error: string) => ({
  type: TYPES.CHATS_CHANNELS_MAKE_READ_FAILURE,
  payload: error,
});

export const getMessageListRequest = (
  payload: MessagesInfinitePaginationParams,
) => ({
  type: TYPES.CHATS_MESSAGES_GET_LIST_REQUEST,
  payload,
});

export const getMessageListSuccess = (
  payload: InfinitePaginationWrapper<Message>,
) => ({
  type: TYPES.CHATS_MESSAGES_GET_LIST_SUCCESS,
  payload,
});

export const getMessageListFailure = (error: string) => ({
  type: TYPES.CHATS_MESSAGES_GET_LIST_FAILURE,
  payload: error,
});

export const loadMoreMessageListRequest = (
  payload: MessagesInfinitePaginationParams,
) => ({
  type: TYPES.CHATS_MESSAGES_GET_MORE_LIST_REQUEST,
  payload,
});

export const loadMoreMessageListSuccess = (
  payload: InfinitePaginationWrapper<Message> & { meta: ChannelIdentity },
) => ({
  type: TYPES.CHATS_MESSAGES_GET_MORE_LIST_SUCCESS,
  payload,
});

export const loadMoreMessageListFailure = (error: string) => ({
  type: TYPES.CHATS_MESSAGES_GET_MORE_LIST_FAILURE,
  payload: error,
});

export const sendMessageRequest = (payload: PostChatMessageData) => ({
  type: TYPES.CHATS_MESSAGES_SEND_REQUEST,
  payload,
});

export const sendMessageSuccess = (payload: Message, tempId: string) => ({
  type: TYPES.CHATS_MESSAGES_SEND_SUCCESS,
  payload,
  meta: tempId,
});

export const sendMessageFailure = (error: string, tempMessage?: Message) => ({
  type: TYPES.CHATS_MESSAGES_SEND_FAILURE,
  payload: error,
  meta: tempMessage,
});

export const editMessageRequest = (
  payload: PostMessageData & ChannelIdentity & { id: UUID },
) => ({
  type: TYPES.CHATS_MESSAGES_EDIT_REQUEST,
  payload,
});

export const editMessageSuccess = (payload: Message) => ({
  type: TYPES.CHATS_MESSAGES_EDIT_SUCCESS,
  payload,
});

export const editMessageFailure = (error: string) => ({
  type: TYPES.CHATS_MESSAGES_EDIT_FAILURE,
  payload: error,
});

export type ChatsRestAction = ReturnType<
  | typeof getChatListRequest
  | typeof getChatListSuccess
  | typeof getChatListFailure
  | typeof loadMoreChatListRequest
  | typeof loadMoreChatListSuccess
  | typeof loadMoreChatListFailure
  | typeof getMessageListRequest
  | typeof getMessageListSuccess
  | typeof getMessageListFailure
  | typeof loadMoreMessageListRequest
  | typeof loadMoreMessageListSuccess
  | typeof loadMoreMessageListFailure
  | typeof sendMessageRequest
  | typeof sendMessageSuccess
  | typeof sendMessageFailure
  | typeof editMessageRequest
  | typeof editMessageSuccess
  | typeof editMessageFailure
  | typeof resolveChatRequest
  | typeof resolveChatSuccess
  | typeof resolveChatFailure
  | typeof closeSupportChannelRequest
  | typeof closeSupportChannelSuccess
  | typeof closeSupportChannelFailure
  | typeof assignSupportChannelRequest
  | typeof assignSupportChannelSuccess
  | typeof assignSupportChannelFailure
  | typeof makeChannelReadRequest
  | typeof makeChannelReadSuccess
  | typeof makeChannelReadFailure
>;

export const uploadImageRequest = (payload: File) => ({
  type: TYPES.CHATS_IMAGES_UPLOAD_REQUEST,
  payload,
});

export const uploadImageSuccess = (payload: AttachmentDocumentType) => ({
  type: TYPES.CHATS_IMAGES_UPLOAD_SUCCESS,
  payload,
});

export const uploadImageFailure = (payload: string) => ({
  type: TYPES.CHATS_IMAGES_UPLOAD_FAILURE,
  payload,
});

export const uploadImageCleanup = () => ({
  type: TYPES.CHATS_IMAGES_UPLOAD_CLEANUP,
});

export type ChatsImagesAction = ReturnType<
  | typeof uploadImageRequest
  | typeof uploadImageSuccess
  | typeof uploadImageFailure
  | typeof uploadImageCleanup
>;

export type ChatsAction =
  | ChatsRestAction
  | SocketChatsAction
  | ChatsImagesAction;
