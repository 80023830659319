import { Chip, createStyles, makeStyles } from '@material-ui/core';
import { Status } from '@rtt-libs/types';
import React from 'react';

const STATUS_COLORS: Record<string, string> = {
  // company & user statuses
  new: '#8C35DC',
  updated: '#8C35DC',
  active: '#0B8208',
  deactivated: '#767676',
  declined: '#CA3528',
  deletion: '#B55700',
  disabled: '#767676',
  // rtt status colors
  alert: '#b01d0c',

  // order statuses
  // new: '#888888',
  pending: '#B55700',
  canceled: '#767676',
  processing: '#253AA6',
  rejected: '#767676',
  // eslint-disable-next-line @typescript-eslint/camelcase
  requires_confirm: '#2173E2',
  approved: '#0B8208',
  // declined: '#888888',
  submit: '#0B8208',
};

const genStatusDisplay = (value: unknown): string => {
  const status = String(value);

  return STATUS_COLORS[status] || 'initial';
};

const useStyles = makeStyles(
  createStyles({
    root: {
      backgroundColor: (props: { color: string }) => props.color,
      color: '#FFFFFF',
      borderRadius: 4,
      width: '100%',
      fontWeight: 500,
      maxWidth: '200px',
    },
  }),
);

type Props = {
  value: unknown;
};

const StatusBadge: React.FC<Props> = ({ value }) => {
  const { value: status, title } = value as Status;
  const color = genStatusDisplay(status);
  const classes = useStyles({ color });

  return value ? <Chip className={classes.root} label={title} /> : null;
};

export default StatusBadge;
