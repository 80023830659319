import { Grid, TextField } from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import { StatusValue } from '@rtt-libs/types';
import { FilterMenu } from '@rtt-libs/views';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { statusFilterConfig } from '../../common';
import routes from '../../pages/routeStrings';
import { StatusGroup } from '../../types';
import { getDistributorsStatusesFromGroup } from '../../utils/getStatusesFromGroup';

type Props = {
  onChange(params: Record<string, string | number | undefined>): void;
  initialFilterValues: Record<string, string>;
};

/**
 * Component to handle filtering of table data.
 */
const FilterRow: React.FC<Props> = ({
  onChange,
  initialFilterValues: { name = '', address = '' },
}) => {
  const [t] = useTranslation();
  const { status = '', statusGroup } = useParams();
  const history = useHistory();

  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onStatusChange = (
    value: Record<string, string | number | undefined>,
  ) => {
    onChange(value);
    const baseUrl = statusGroup
      ? `${routes.distributors}/${statusGroup}`
      : routes.rtt;
    history.replace(!value.status ? baseUrl : `${baseUrl}/${value.status}`);
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ name: e.target.value });
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ address: e.target.value });
  };

  const statusesGroup = getDistributorsStatusesFromGroup(
    statusGroup as StatusGroup,
  ) as StatusValue[] | undefined;

  return (
    <>
      <Grid item xs={1}>
        {statusesGroup && (
          <FilterMenu
            key={status || 'all'}
            filters={statusFilterConfig(t, statusesGroup)}
            onChange={onStatusChange}
            initialValues={{ status }}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        <TextField
          defaultValue={name}
          name="name"
          label={t('admin.tables.distributor.name')}
          onChange={onNameChange}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          defaultValue={address}
          name="address"
          label={t('admin.tables.distributor.address')}
          onChange={onAddressChange}
        />
      </Grid>
      <Grid item xs={3} />
    </>
  );
};

export default FilterRow;
