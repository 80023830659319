import { TableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import TableRow from './TableRow';
import { ColumnConfig } from './types';

type Props = {
  count?: number;
  columnsConfig: ColumnConfig[];
  RowComponent?: React.ComponentType<SkeletonRowProps>;
};

const SkeletonRows: React.FC<Props> = ({
  columnsConfig,
  count = 7,
  RowComponent = SkeletonRow,
}) => {
  return (
    <>
      {Array.from(Array(count).keys()).map(rowId => {
        return (
          <RowComponent
            key={rowId}
            rowId={rowId}
            columnsConfig={columnsConfig}
          />
        );
      })}
    </>
  );
};

export default SkeletonRows;

export type SkeletonRowProps = {
  rowId?: number;
  columnsConfig: ColumnConfig[];
};
const SkeletonRow = ({ columnsConfig }: SkeletonRowProps) => {
  return (
    <TableRow>
      {columnsConfig.map(({ id, options: { align } = {} }) => {
        return (
          <TableCell key={id} align={align}>
            <Skeleton />
          </TableCell>
        );
      })}
    </TableRow>
  );
};
