import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import React from 'react';
import { ChatMessageWithProduct, UUID } from '../../../types';
import TimeHiddenButton from './EditMessageButton';

interface Props extends ChatMessageWithProduct {
  userId?: number;
  onEditClick?: (id: UUID, text: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tickItem: {
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(0, 0.5),
    },
  }),
);

const CustomTicks = ({
  user,
  userId,
  id,
  isUnread,
  createdAt,
  text,
  onEditClick,
}: Props) => {
  const classes = useStyles();

  if (user.id !== userId) {
    return null;
  }

  return (
    <>
      {isUnread !== undefined && text && (
        <TimeHiddenButton
          createdAt={createdAt}
          onClick={() => onEditClick?.(id, text)}
        />
      )}

      <Typography component="div" className={classes.tickItem}>
        {isUnread === true && <DoneIcon fontSize="inherit" />}
        {isUnread === false && <DoneAllIcon fontSize="inherit" />}
        {isUnread === undefined && <HourglassEmptyIcon fontSize="inherit" />}
      </Typography>
    </>
  );
};

export default CustomTicks;
