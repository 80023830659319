import {
  Button,
  createStyles,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React from 'react';
import { DRAWER_WIDTH } from './config';

type Props = {
  open: boolean;
  handleDrawerToggle: () => void;
  renderLogo?: React.ReactNode;
  collapseDescription?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      overflowX: 'hidden',
      width: DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(6) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    collapseSection: {
      position: 'sticky',
      bottom: 0,
      padding: theme.spacing(2, 1),
      flex: '1 0 auto',
      alignItems: 'flex-end',
      justifyContent: 'center',
      minHeight: 0,
    },
    collapseButton: {
      color: theme.palette.text.secondary,
      fontSize: 11,
      justifyContent: 'flex-start',
    },
    preventScrolling: {
      position: 'sticky',
      top: 0,
      zIndex: 100,
    },
  }),
);

const SideDrawer: React.FC<Props> = ({
  children,
  renderLogo,
  open,
  handleDrawerToggle,
  collapseDescription = 'Приховати меню',
}) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
      PaperProps={{
        elevation: 6,
        component: 'aside',
      }}
    >
      <div className={classes.preventScrolling}>
        <Paper square elevation={0} className={classes.toolbar}>
          {renderLogo}
        </Paper>
        <Divider />
      </div>

      {children}

      <Paper
        elevation={0}
        square
        className={clsx(classes.toolbar, classes.collapseSection)}
      >
        {open ? (
          <Button
            color="inherit"
            fullWidth
            variant="text"
            aria-label="Collapse menu"
            onClick={handleDrawerToggle}
            startIcon={<ChevronLeftIcon />}
            className={classes.collapseButton}
          >
            {collapseDescription}
          </Button>
        ) : (
          <IconButton
            aria-label="Expand menu"
            onClick={handleDrawerToggle}
            size="small"
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </Paper>
    </Drawer>
  );
};

export default SideDrawer;
