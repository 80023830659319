import React from 'react';
import { useDispatch } from 'react-redux';
import { ActionsMenuComponent } from '../../common';
import { changeDistributorStatusRequest } from '../duck/actions';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

/**
 * Menu for Distributors with available status options & confirmation dialog
 */
const DistributorActionsMenu: React.FC<Props> = props => {
  const dispatch = useDispatch();

  const onStatusChange = (
    values: Parameters<typeof changeDistributorStatusRequest>[0],
  ) => {
    dispatch(changeDistributorStatusRequest(values));
  };

  return <ActionsMenuComponent {...props} onStatusChange={onStatusChange} />;
};

export default DistributorActionsMenu;
