import React from 'react';
import { InfiniteScroll } from '../common';

interface Props {
  onLoadMore(): void;
  hasMore?: boolean;
  loading?: boolean;
  component?: React.ElementType;
  render?: React.FC<{ sentinel: React.ReactNode }>;
  scrollOwnerRef?: React.RefObject<EventTarget>;
}

const InfiniteScrollWrapper: React.FC<Props> = ({
  onLoadMore,
  children,
  loading = false,
  hasMore = false,
  component,
  render,
  scrollOwnerRef,
}) => {
  return (
    <InfiniteScroll
      throttle={100}
      threshold={-50}
      isLoading={loading}
      hasMore={hasMore}
      onLoadMore={onLoadMore}
      component={component}
      render={render}
      scrollOwnerRef={scrollOwnerRef}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollWrapper;
