import { Box, CardHeader, CardHeaderProps, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelIdentity } from '../../types';

interface Props
  extends Partial<Pick<ChannelIdentity, 'channelId'>>,
    CardHeaderProps {
  id?: string;
}

const SupportChannelHeader = ({ id, ...props }: Props) => {
  const [t] = useTranslation();

  const theme = useTheme();

  return (
    <Box clone borderBottom={`solid 1px ${theme.palette.divider}`}>
      <CardHeader
        title={
          id
            ? t('chats.support.channelTitle', { id })
            : t('chats.selectSupportChannel')
        }
        titleTypographyProps={{ variant: 'h6' }}
        {...props}
      />
    </Box>
  );
};

export default SupportChannelHeader;
