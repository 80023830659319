import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectChannelAdmin, selectChatOpponent } from '../../duck/selectors';
import { ChannelIdentity } from '../../types';

interface Props {
  channelId: ChannelIdentity['channelId'];
}

const DistributorInfoChatTitle: React.FC<Props> = ({ channelId }) => {
  const [t] = useTranslation();
  const distributorInfo = useSelector(
    selectChatOpponent({ channelId, opponentType: 'distributor' }),
  );

  const moderatorInfo = useSelector(selectChannelAdmin(channelId));

  if (!distributorInfo || !('name' in distributorInfo)) {
    return <>{t('chats.support.channelTitleError')}</>;
  }

  const moderatorNameString = moderatorInfo
    ? `[ ${moderatorInfo.firstName} ${moderatorInfo.lastName} (${t(
        `roles.${moderatorInfo.role || 'moderator'}`,
      )}) ]`
    : `[ ${t('chats.support.channelUnassigned')} ]`;

  return (
    <>
      {distributorInfo?.name} {moderatorNameString}
    </>
  );
};

export default DistributorInfoChatTitle;
