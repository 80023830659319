import { RoleRules, ROLES, UserInfoType } from '@rtt-libs/auth';

export const PERMISSIONS = {
  APP_ACCESS: 'app:access',
  APP_RESOLVE_SUPPORT_CHANNEL: 'app:resolveSupportChannel',
  NAV_CATEGORIES: 'nav:categories',
  NAV_DISTR: 'nav:distr',
  NAV_MODERATORS: 'nav:moderators',
  NAV_RTT: 'nav:rtt',
  NAV_SETTINGS: 'nav:settings',
  NAV_SUPPORT: 'nav:support',
} as const;

const commonStaticPermissions = [
  PERMISSIONS.APP_ACCESS,
  PERMISSIONS.NAV_CATEGORIES,
  PERMISSIONS.NAV_DISTR,
  PERMISSIONS.NAV_RTT,
  PERMISSIONS.NAV_SETTINGS,
  PERMISSIONS.NAV_SUPPORT,
];

type Permissions = typeof PERMISSIONS[keyof typeof PERMISSIONS];

const checkUserActivity = (user: UserInfoType | null): boolean =>
  !!user?.is_active;

const rules: RoleRules<Permissions> = {
  [ROLES.SYSTEM_ADMIN]: {
    static: [...commonStaticPermissions, PERMISSIONS.NAV_MODERATORS],
  },
  [ROLES.SYSTEM_MODERATOR]: {
    static: [
      ...commonStaticPermissions,
      PERMISSIONS.APP_RESOLVE_SUPPORT_CHANNEL,
    ],
    dynamic: {
      [PERMISSIONS.APP_ACCESS]: checkUserActivity,
    },
  },
};

export default rules;
