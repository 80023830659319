import {
  DistributorDetails,
  DistributorStats,
  Pagination,
} from '@rtt-libs/types';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { Distributor } from '../../api/distributors';
import { branchName, mainReducerKey } from './const';
import { State } from './reducer';

export type PartialRootState = {
  distributors: State;
};

export const getDistributorsSel: Selector<
  PartialRootState,
  Distributor[]
> = get([branchName, mainReducerKey]);

export const getPaginationSel: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const getLoadingStateSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const getErrorSel: Selector<PartialRootState, string | undefined> = get([
  branchName,
  'error',
]);

export const getDistributorDetailsSel: Selector<
  PartialRootState,
  (DistributorDetails & DistributorStats) | undefined
> = get([branchName, 'current']);

export const getNoticesSel: Selector<PartialRootState, string | null> = get([
  branchName,
  'currentNotices',
]);

export const getTableContentSel = createSelector(
  [getDistributorsSel, getPaginationSel, getLoadingStateSel, getErrorSel],
  (distributors, pagination, loading, error) => ({
    distributors,
    pagination,
    loading,
    error,
  }),
);

export const getDistributorDetailsPageSel = createSelector(
  [getDistributorDetailsSel, getLoadingStateSel, getErrorSel],
  (distributor, loading, error) => ({
    distributor,
    loading,
    error,
  }),
);

const selectCurrentStats: Selector<
  PartialRootState,
  DistributorStats['stats']
> = get([branchName, 'currentStats']);

const selectStatsLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'statsLoading',
]);

const selectStatsError: Selector<PartialRootState, string | null> = get([
  branchName,
  'statsError',
]);

export const selectDistributorStats = createSelector(
  [selectCurrentStats, selectStatsLoading, selectStatsError],
  (stats, loading, error) => ({
    stats,
    loading,
    error,
  }),
);

export const selectDistributorOptions: Selector<
  PartialRootState,
  Distributor[]
> = get([branchName, 'options']);

export const selectDistributorLoading = getLoadingStateSel;
export const selectDistributorError = getErrorSel;
