import { createStyles, makeStyles, Table, TableBody } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import SkeletonRows from './SkeletonRows';
import TableHeadSortable from './TableHeadSortable';
import TablePagination from './TablePagination';
import TableRowList from './TableRowList';
import { ColumnConfig } from './types';

const useStyles = makeStyles(
  createStyles({
    scroll: {
      overflowX: 'auto',
    },
  }),
);

type LocaleLabels = {
  sort: string;
  displayedRows: (values: {
    from: number;
    to: number;
    count: number;
  }) => string;
  rowsPerPage: string;
};

export type Props = {
  /** Display loading indicator */
  loading?: boolean;
  /** Specify unique key of entry */
  entryIdKey?: string;
  /** Data which will be displayed in table */
  entries?: Record<string, unknown>[];
  /**
   * onClick handler which bound to a table row
   * @param entryId unique key of clicked entry
   */
  onRowClick?(entryId: string | number): void;
  /**
   * Fires event when the table requesting change page or sort column.
   * Should be unchangeable between renders (wrap by useCallback if needed).
   * @param state table state: pagination & sorting values
   */
  onStateChange?(state: Record<string, string | number>): void;
  /** List of available count of row displayed per page */
  perPageOptions?: number[];
  /**
   * Configuration properties of table
   * Specify order of column, which values should display, custom component to render inside Cell & formatter of value.
   */
  columnsConfig?: ColumnConfig[];
  /**
   * Label for common component in table, such as tooltips & pagination description.
   */
  localeLabels?: LocaleLabels;
  /**
   * Initial page & perPage params to render the table not only on first page
   * If perPageOption is set and displayed wrong/empty default option, you should probably fill this prop.
   */
  initial?: {
    page?: number;
    perPage?: number;
  };
  /** Total entries count which will be displayed in pagination section of the table */
  totalEntriesCount?: number;
  /** Disable build in pagination */
  withoutPagination?: boolean;
  /** Displayed message when table has no entries */
  emptyMessage?: React.ReactNode;
  /** To change default rendered components pass function like render-props pattern */
  children?(props: ChildrenProps): React.ReactElement;
};

type ChildrenProps = {
  handleChangePagination: ({
    page,
    perPage,
  }: Record<'page' | 'perPage', number>) => void;
  handleRequestSort: (orderState: Record<string, string>) => void;
};

/**
 * Table component with sortable header, pagination,
 * mappings of data & enhancement by custom components in Cells.
 *
 * If you need some different behavior or view modification, use render-props pattern to
 * construct your own table with Table, TableHeadSortable, TableRowList, TableRowList, etc.
 */
const EnhancedPaginatedTable = ({
  loading = true,
  onRowClick,
  onStateChange,
  entryIdKey,
  entries = [],
  columnsConfig = [],
  perPageOptions,
  localeLabels = {} as LocaleLabels,
  initial,
  totalEntriesCount,
  withoutPagination,
  emptyMessage,
  children,
}: Props) => {
  const classes = useStyles();
  const mounted = useRef(false);
  const [queries, changeQueries] = useState<Record<string, string | number>>(
    {},
  );

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else if (typeof onStateChange === 'function') onStateChange(queries);
  }, [onStateChange, queries]);

  const handleChangePagination = useCallback(
    ({ page, perPage }: Record<'page' | 'perPage', number>) => {
      changeQueries(prevQueries => ({ ...prevQueries, page, perPage }));
    },
    [],
  );

  const handleRequestSort = useCallback(
    (orderState: Record<string, string>) => {
      changeQueries(prevQueries => ({ ...prevQueries, ...orderState }));
    },
    [],
  );

  if (children) {
    if (typeof children === 'function') {
      return children({
        handleChangePagination,
        handleRequestSort,
      });
    }
    return null;
  }

  return (
    <>
      <div className={classes.scroll}>
        <Table>
          <TableHeadSortable
            onSort={handleRequestSort}
            columns={columnsConfig}
            tooltip={localeLabels.sort}
          />
          <TableBody>
            {loading && <LoadingIndicator width={columnsConfig.length} />}
            <TableRowList
              data={entries}
              onClick={onRowClick}
              columnsConfig={columnsConfig}
              entryIdKey={entryIdKey}
            />
            {loading && !entries.length && (
              <SkeletonRows columnsConfig={columnsConfig} />
            )}
          </TableBody>
        </Table>
        {!loading && !entries.length && emptyMessage}
      </div>
      {!withoutPagination && (
        <TablePagination
          count={totalEntriesCount || entries.length}
          onChange={handleChangePagination}
          perPageOptions={perPageOptions}
          paginationProps={{
            labelDisplayedRows: localeLabels.displayedRows,
            labelRowsPerPage: localeLabels.rowsPerPage,
          }}
          initialPage={initial && initial.page}
          initialRowsPerPage={initial && initial.perPage}
        />
      )}
    </>
  );
};

export default EnhancedPaginatedTable;
