import { Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import {
  CreateModeratorValues,
  Moderator,
  SearchParams,
  EditModeratorValues,
} from '../../api/moderators';
import * as types from './types';

export const getModeratorsRequest = (params: SearchParams) => ({
  type: types.GET_MODERATORS_REQUEST,
  payload: params,
});

export const getModeratorsSuccess = (
  payload: Moderator[],
  meta: { pagination: Pagination },
) => ({
  type: types.GET_MODERATORS_SUCCESS,
  payload,
  meta,
});

export const getModeratorsFailure = (error: string) => ({
  type: types.GET_MODERATORS_FAILURE,
  payload: error,
});

export const createModeratorRequest = (values: CreateModeratorValues) => ({
  type: types.CREATE_MODERATOR_REQUEST,
  payload: values,
});

export const createModeratorResponse = (
  submissionErrors?: Partial<CreateModeratorValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.CREATE_MODERATOR_RESPONSE,
  payload: submissionErrors,
});

export const createModeratorSuccess = (payload: Moderator) => ({
  type: types.CREATE_MODERATOR_SUCCESS,
  payload,
});

export const createModeratorFailure = (error: string) => ({
  type: types.CREATE_MODERATOR_FAILURE,
  payload: error,
});

export const editModeratorRequest = (values: EditModeratorValues) => ({
  type: types.EDIT_MODERATOR_REQUEST,
  payload: values,
});

export const editModeratorResponse = (
  submissionErrors?: Partial<EditModeratorValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.EDIT_MODERATOR_RESPONSE,
  payload: submissionErrors,
});

export const editModeratorSuccess = (payload: Moderator) => ({
  type: types.EDIT_MODERATOR_SUCCESS,
  payload,
});

export const editModeratorFailure = (error: string) => ({
  type: types.EDIT_MODERATOR_FAILURE,
  payload: error,
});

export const changeStatusModeratorRequest = (
  id: Moderator['id'],
  status: boolean,
) => ({
  type: types.CHANGE_STATUS_MODERATOR_REQUEST,
  payload: {
    id,
    status,
  },
});

export const changeStatusModeratorSuccess = (payload: Moderator) => ({
  type: types.CHANGE_STATUS_MODERATOR_SUCCESS,
  payload,
});

export const changeStatusModeratorFailure = (error: string) => ({
  type: types.CHANGE_STATUS_MODERATOR_FAILURE,
  payload: error,
});

export type Actions =
  | ReturnType<typeof getModeratorsRequest>
  | ReturnType<typeof getModeratorsSuccess>
  | ReturnType<typeof getModeratorsFailure>
  | ReturnType<typeof createModeratorRequest>
  | ReturnType<typeof createModeratorSuccess>
  | ReturnType<typeof createModeratorFailure>
  | ReturnType<typeof editModeratorRequest>
  | ReturnType<typeof editModeratorSuccess>
  | ReturnType<typeof editModeratorFailure>
  | ReturnType<typeof changeStatusModeratorRequest>
  | ReturnType<typeof changeStatusModeratorSuccess>
  | ReturnType<typeof changeStatusModeratorFailure>;
