import {
  DistributorDetails,
  DocumentPreviewType,
  StatusWithNotice,
  Pagination,
  DistributorStats,
} from '@rtt-libs/types';
import isEqual from 'lodash/fp/isEqual';
import xor from 'lodash/fp/xor';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeDistributor,
  changeDistributorStatus,
  getDistributorDetails,
  getDistributorNotices,
  getDistributors,
  editActiveDistributor,
  Distributor,
  getDistributorStats,
} from '../../api/distributors';
import * as actions from './actions';
import { getDistributorDetailsSel } from './selectors';
import { selectAllCategories } from '../../categoriesTree/duck/selectors';
import * as TYPES from './types';

/**
 * Fetch distributors list to render table
 */
function* distributorListWorker({
  payload,
}: ReturnType<typeof actions.getDistributorsRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: Distributor[]; meta: { pagination: Pagination } } = yield call(
      getDistributors,
      payload,
    );

    yield all([put(actions.getDistributorsSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.getDistributorsFailure(e.message));
  }
}

/**
 * Fetch detailed distributor info, available statuses & last notice to render detail page
 */
function* distributorDetailsWorker({
  payload,
}: ReturnType<typeof actions.getDistributorDetailsRequest>) {
  try {
    const [distributor, noticeValues]: [
      DistributorDetails & DistributorStats & { notices?: string | null },
      StatusWithNotice[],
    ] = yield all([
      call(getDistributorDetails, payload),
      call(getDistributorNotices, payload),
    ]);

    // Display last declined notice after distributor updated his info
    const lastNotice = noticeValues[0];
    const declinedNotice =
      lastNotice && distributor.status.value === 'updated'
        ? lastNotice.notices
        : null;

    const notices = distributor.notices || declinedNotice;

    yield all([
      put(
        actions.getDistributorDetailsSuccess(distributor, {
          notices,
        }),
      ),
    ]);
  } catch (e) {
    yield put(actions.getDistributorDetailsFailure(e.message));
  }
}

/**
 * Change distributor status described with notices (if required)
 */
function* distributorStatusWorker({
  payload,
}: ReturnType<typeof actions.changeDistributorStatusRequest>) {
  try {
    const status: StatusWithNotice = yield call(
      changeDistributorStatus,
      payload,
    );

    yield all([
      put(
        actions.changeDistributorStatusSuccess(status, {
          id: payload.id,
          status: status.status,
        }),
      ),
    ]);
  } catch (e) {
    if (e.response) {
      yield put(
        actions.changeDistributorStatusFailure(e.response.data.message),
      );
      return;
    }
    yield put(actions.changeDistributorStatusFailure(e.message));
  }
}

/**
 * Edit distributor info.
 */
function* updateWorker({
  payload,
}: ReturnType<typeof actions.changeDistributorRequest>) {
  try {
    const initialValues: DistributorDetails | undefined = yield select(
      getDistributorDetailsSel,
    );

    const { allCategories } = yield select(selectAllCategories);

    const filterCategories =
      payload.categories &&
      payload.categories.filter(
        categoryId => !allCategories[categoryId].is_deleted && categoryId,
      );

    const { documents = [], categories = [], status } = initialValues || {};

    const { addedDocuments, deletedDocuments } = getUpdatedDocuments(
      documents,
      payload.documents,
    );

    // Do not send any categories if them didn't changed.
    const updatedCategories = isEqual(categories, filterCategories)
      ? undefined
      : filterCategories;

    if (status?.value === 'active') {
      const data: DistributorDetails & DistributorStats = yield call(
        editActiveDistributor,
        payload.id,
        payload,
      );
      yield all([put(actions.changeDistributorSuccess(data))]);
    } else {
      const data: DistributorDetails & DistributorStats = yield call(
        changeDistributor,
        payload.id,
        payload,
        addedDocuments,
        deletedDocuments,
        updatedCategories,
      );
      yield all([put(actions.changeDistributorSuccess(data))]);
    }
  } catch (e) {
    yield put(actions.changeDistributorFailure(e));
  }
}

/**
 * Get filtered distributor's
 */
function* statisticsWorker({
  payload: { id, params },
}: ReturnType<typeof actions.getDistributorStatsRequest>) {
  try {
    const stats: DistributorStats['stats'] = yield call(
      getDistributorStats,
      id,
      params,
    );

    yield put(actions.getDistributorStatsSuccess(stats));
  } catch (e) {
    if (e !== null && typeof e === 'object') {
      const errorStrings = Object.values(e);
      yield put(actions.getDistributorStatsFailure(errorStrings.join(', ')));
    }
    yield put(actions.getDistributorStatsFailure(e.message));
  }
}

/**
 * Fetch distributors list to render table
 */
function* distributorOptionsWorker({
  payload,
}: ReturnType<typeof actions.getDistributorOptionsRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: Distributor[]; meta: { pagination: Pagination } } = yield call(
      getDistributors,
      payload,
    );

    yield all([put(actions.getDistributorOptionsSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.getDistributorOptionsFailure(e.message));
  }
}

/**
 * Keeps all sub-sagas inside watcher saga.
 * Specify any takeLatest, takeEvery... right here.
 */
export default function* watcher() {
  yield takeLatest(TYPES.GET_DISTRIBUTOR_REQUEST, distributorListWorker);
  yield takeLatest(
    TYPES.CHANGE_DISTRIBUTOR_STATUS_REQUEST,
    distributorStatusWorker,
  );
  yield takeLatest(
    TYPES.GET_DISTRIBUTOR_DETAILS_REQUEST,
    distributorDetailsWorker,
  );
  yield takeLatest(TYPES.CHANGE_DISTRIBUTOR_REQUEST, updateWorker);
  yield takeLatest(TYPES.DISTRIBUTORS_GET_STATS_REQUEST, statisticsWorker);
  yield takeLatest(
    TYPES.DISTRIBUTORS_GET_OPTIONS_REQUEST,
    distributorOptionsWorker,
  );
}

/**
 * Extract added & deleted documents
 */
function getUpdatedDocuments(
  initialDocuments: (File | DocumentPreviewType)[],
  newDocuments: (File | DocumentPreviewType)[],
) {
  const documentsChanged = xor<File | DocumentPreviewType>(
    initialDocuments,
    newDocuments,
  );
  const addedDocuments = documentsChanged.filter(
    doc => doc instanceof File,
  ) as File[];
  const deletedDocuments = documentsChanged
    .filter(doc => !(doc instanceof File))
    .map(doc => (doc as DocumentPreviewType).id);
  return { addedDocuments, deletedDocuments };
}
