import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDistributorsRequest } from '../duck/actions';
import { getTableContentSel } from '../duck/selectors';
import DistributorsTable from './DistributorsTable';
import { FilterStatus, StatusGroup } from '../../types';

type Props = {
  status?: FilterStatus;
  statusGroup?: StatusGroup;
};

/**
 * Container to fetch & filter distributors & render them in table.
 */
const DistributorContainer: React.FC<Props> = ({ status, statusGroup }) => {
  const dispatch = useDispatch();

  const { distributors, pagination, loading } = useSelector(getTableContentSel);

  const onTableStateChange = useCallback(
    (options: Record<string, string | number>) => {
      dispatch(getDistributorsRequest(options));
    },
    [dispatch],
  );

  return (
    <DistributorsTable
      onStateChange={onTableStateChange}
      status={status}
      statusGroup={statusGroup}
      distributors={distributors}
      pagination={pagination}
      loading={loading}
    />
  );
};

export default DistributorContainer;
