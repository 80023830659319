import { Pagination } from '@rtt-libs/types';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createModerator,
  getAllModerators,
  changeModeratorStatus,
  editModerator,
  Moderator,
} from '../../api/moderators';
import * as actions from './actions';
import * as types from './types';

function* getModeratorsWorker({
  payload,
}: ReturnType<typeof actions.getModeratorsRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: Moderator[]; meta: { pagination: Pagination } } = yield call(
      getAllModerators,
      payload,
    );
    yield all([put(actions.getModeratorsSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.getModeratorsFailure(e.message));
  }
}

function* createModeratorWorker({
  payload,
}: ReturnType<typeof actions.createModeratorRequest>) {
  try {
    const moderator: Moderator = yield call(createModerator, payload);

    yield all([
      put(actions.createModeratorSuccess(moderator)),
      put(actions.createModeratorResponse()),
    ]);
  } catch (errors) {
    yield put(actions.createModeratorResponse(errors));
  }
}

function* editModeratorWorker({
  payload,
}: ReturnType<typeof actions.editModeratorRequest>) {
  try {
    const moderator: Moderator = yield call(editModerator, payload);

    yield all([
      put(actions.editModeratorSuccess(moderator)),
      put(actions.editModeratorResponse()),
    ]);
  } catch (errors) {
    yield put(actions.editModeratorResponse(errors));
  }
}

function* changeStatusModeratorWorker({
  payload,
}: ReturnType<typeof actions.changeStatusModeratorRequest>) {
  try {
    const moderator: Moderator = yield call(changeModeratorStatus, payload);

    yield all([put(actions.changeStatusModeratorSuccess(moderator))]);
  } catch (e) {
    yield put(actions.changeStatusModeratorFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.CREATE_MODERATOR_REQUEST, createModeratorWorker);
  yield takeLatest(types.GET_MODERATORS_REQUEST, getModeratorsWorker);
  yield takeLatest(
    types.CHANGE_STATUS_MODERATOR_REQUEST,
    changeStatusModeratorWorker,
  );
  yield takeLatest(types.EDIT_MODERATOR_REQUEST, editModeratorWorker);
}
