/* eslint-disable @typescript-eslint/camelcase */

import {
  FetchedPagination,
  Pagination as PaginationType,
  Paginated,
} from '@rtt-libs/types';

class Pagination {
  total: number;
  perPage: number;
  currentPage: number;
  totalPages: number;

  constructor(pagination: FetchedPagination) {
    this.total = pagination?.total;
    this.perPage = pagination?.per_page;
    this.currentPage = pagination?.current_page;
    this.totalPages = pagination?.total_pages;
  }
}

export const mapPagination = (pagination: FetchedPagination): PaginationType =>
  new Pagination(pagination);

// TODO: Replace duplicated in distributor & rtt
export const mapPaginatedData = <T = unknown, R = T>(
  data: Paginated<T>,
  mapData?: (data: T[]) => R,
) => ({
  data: (mapData
    ? mapData(data.data)
    : data.data) as typeof mapData extends undefined ? T[] : R,
  meta: {
    pagination: mapPagination(data.meta?.pagination),
  },
});
