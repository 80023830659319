import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import {
  FieldWithMessages as Field,
  LoadingButton,
  TextFieldAdapter,
} from '@rtt-libs/views';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CreateModeratorValues } from '../../../api/moderators';

type Props = FormRenderProps<CreateModeratorValues> & { onClose(): void };

const CreateModeratorForm: React.FC<Props> = ({
  handleSubmit,
  submitError,
  onClose,
  submitting,
}) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle id="add-moderator-dialog-title">
        {t('admin.moderators.add.dialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('admin.moderators.add.dialogDescription')}
        </DialogContentText>
        <Field
          autoFocus
          margin="dense"
          name="login"
          type="text"
          fullWidth
          required
          component={TextFieldAdapter}
          inputProps={{
            pattern: '^[a-zA-Z0-9-_]+$',
          }}
          label={t('admin.moderators.add.login.label')}
          placeholder={t('admin.moderators.add.login.placeholder')}
        />
        <Field
          component={TextFieldAdapter}
          name="firstName"
          margin="dense"
          type="text"
          fullWidth
          required
          label={t('admin.moderators.add.firstName.label')}
        />
        <Field
          component={TextFieldAdapter}
          name="lastName"
          margin="dense"
          type="text"
          fullWidth
          required
          label={t('admin.moderators.add.lastName.label')}
        />
        <Field
          component={TextFieldAdapter}
          name="email"
          margin="dense"
          type="email"
          fullWidth
          required
          label={t('admin.moderators.add.email.label')}
          placeholder={t('admin.moderators.add.email.placeholder')}
        />
        <Field
          component={TextFieldAdapter}
          name="phone"
          margin="dense"
          type="phone"
          initialValue="+380"
          inputProps={{
            pattern: '^(\\+\\d{1,2})?\\d{10}$',
          }}
          fullWidth
          required
          label={t('admin.moderators.add.phone.label')}
          placeholder={t('admin.moderators.add.phone.placeholder')}
        />

        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('controls.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={submitting}
        >
          {t('controls.add')}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default CreateModeratorForm;
