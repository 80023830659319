import type { Pagination } from '@rtt-libs/types';
import { ProductInfo, ProductSearchParams } from '../types';
import * as types from './types';

export const searchProductsAsRttRequest = (
  rttId: number,
  params?: ProductSearchParams,
) => ({
  type: types.PRODUCTS_AS_RTT_SEARCH_REQUEST,
  payload: { rttId, params },
});

export const searchProductsAsRttSuccess = (
  payload: ProductInfo[],
  meta: { pagination: Pagination },
) => ({
  type: types.PRODUCTS_AS_RTT_SEARCH_SUCCESS,
  payload,
  meta,
});

export const searchProductsAsRttFailure = (error: string) => ({
  type: types.PRODUCTS_AS_RTT_SEARCH_FAILURE,
  payload: error,
});

export const clearProductsState = () => ({
  type: types.PRODUCTS_CLEAR_STATE,
});

export type Actions = ReturnType<
  | typeof searchProductsAsRttRequest
  | typeof searchProductsAsRttSuccess
  | typeof searchProductsAsRttFailure
  | typeof clearProductsState
>;
