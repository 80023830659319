/* eslint-disable @typescript-eslint/camelcase */
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import { ENDPOINTS } from '../environment';
import type * as NotificationTypes from '../notifications/types';
import api from './apiSetup';

export const getNotificationsAPI = (): Promise<
  NotificationTypes.NotificationStats
> =>
  api
    .get<NotificationTypes.NotificationsAPI>(ENDPOINTS.notifications)
    .then(({ data }) => mapNotifications(data))
    .catch(() => {
      return mapNotifications({} as NotificationTypes.Notifications);
    })
    .catch(e => {
      throw e.response.data.message || e.message;
    });

const mapNotifications = (
  fetched: NotificationTypes.Notifications,
): NotificationTypes.NotificationStats => {
  const { qty_new_messages, ...rest } = fetched;

  return {
    newMessages: qty_new_messages ?? 0,
    ...mapKeys(rest, (_, key) => camelCase(key)),
  };
};
