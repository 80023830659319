import React, { useState } from 'react';
import { Moderator } from '../../../api/moderators';
import { EditModeratorContext } from './EditModeratorContext';
import EditModeratorDialog from './EditModeratorDialog';
import ModeratorActionsMenu from './ModeratorActionsMenu';

type Props = {
  /** id of current entry */
  id: string | number | symbol;
  /** Value received from format fn in tableConfig  */
  value?: unknown;
};

const EditModeratorActions: React.FC<Props> = props => {
  const [dialogState, changeDialogState] = useState<boolean>(false);

  const handleDialogOpen = () => changeDialogState(true);
  const handleDialogClose = () => changeDialogState(false);

  // eslint-disable-next-line react/destructuring-assignment
  const moderator = props.value instanceof Moderator ? props.value : undefined;

  return (
    <>
      <ModeratorActionsMenu {...props} onEditClick={handleDialogOpen} />

      <EditModeratorContext.Provider value={moderator}>
        <EditModeratorDialog open={dialogState} onClose={handleDialogClose} />
      </EditModeratorContext.Provider>
    </>
  );
};

export default EditModeratorActions;
