import {
  notificationsReducer as chatNotifications,
  reducer as chats,
} from '@rtt-libs/chats';
import { reducer as commonNotifications } from '@rtt-libs/state-containers';
import { Action, combineReducers } from 'redux';
import { reducer as categories } from '../categoriesTree/duck';
import { reducer as distributors } from '../distributors/duck';
import { reducer as moderators } from '../moderators/duck';
import { reducer as rtt } from '../rtt/duck';
import { reducer as settings } from '../settings/duck';

const notifications = (
  state: ReturnType<typeof commonNotifications>,
  action: Action,
) =>
  chatNotifications(
    commonNotifications(
      state,
      action as Parameters<typeof commonNotifications>[1],
    ),
    action as Parameters<typeof chatNotifications>[1],
  );

const rootReducer = combineReducers({
  categories,
  distributors,
  moderators,
  rtt,
  settings,
  notifications,
  chats,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
