import { ENDPOINTS } from '../environment';
import type {
  ChannelType,
  FetchedMessage,
  InfinitePaginationParams,
  InfinitePaginationResponse,
  PostMessageData,
  UUID,
} from '../types';
import api from './apiSetup';
import {
  mapChannelTypeToAPI,
  mapInfinitePaginatedData,
  mapPostMessage,
  Message,
  mapInfinitePaginationParams,
} from './mappers';

export const getMessageList = (
  channelId: UUID,
  channelType: ChannelType,
  params?: InfinitePaginationParams,
) =>
  api
    .get<InfinitePaginationResponse<FetchedMessage>>(
      ENDPOINTS.messages(channelId, mapChannelTypeToAPI(channelType)),
      { params: mapInfinitePaginationParams(params) },
    )
    .then(({ data }) =>
      mapInfinitePaginatedData((v: FetchedMessage) => new Message(v))(data),
    )
    .catch(e => {
      throw e.response.data.message || e.message;
    });

export const sendMessage = (
  channelId: UUID,
  channelType: ChannelType,
  messageData: PostMessageData,
) =>
  api
    .post<FetchedMessage>(
      ENDPOINTS.messages(channelId, mapChannelTypeToAPI(channelType)),
      mapPostMessage(messageData),
    )
    .then(({ data }) => new Message(data))
    .catch(e => {
      throw e.response.data.message || e.message;
    });

export const editMessage = (
  channelId: UUID,
  channelType: ChannelType,
  id: UUID,
  messageData: PostMessageData,
) =>
  api
    .put<FetchedMessage>(
      `${ENDPOINTS.messages(
        channelId,
        mapChannelTypeToAPI(channelType),
      )}/${id}`,
      mapPostMessage(messageData),
    )
    .then(({ data }) => new Message(data))
    .catch(e => {
      throw e.response.data.message || e.message;
    });
