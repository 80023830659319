import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { getProfile } from '../apiHandlers';
import { getAuthToken } from '../cookieHandlers';
import { logoutHere } from '../Logout';
import { RoleRules, UserInfoType } from '../types';
import check from './check';

type Props = {
  rules: RoleRules;
  fallback?: React.ReactElement;
};

const initialState = {
  loading: true,
  user: null as UserInfoType | null,
  error: null as string | null,
};

const AuthContext = React.createContext<
  {
    logout: typeof logoutHere;
    updateUser: (user: UserInfoType) => void;
    isPermittedTo: <P extends string = string>(
      action: P | P[],
      data?: Record<string, unknown>,
    ) => boolean;
  } & typeof initialState
>({
  ...initialState,
  logout: logoutHere,
  isPermittedTo: _action => false,
  updateUser: () => undefined,
});

const reducer = (
  state: typeof initialState,
  action:
    | { type: 'set'; payload: UserInfoType | null }
    | { type: 'error'; payload: string }
    | { type: 'request' },
) => {
  switch (action.type) {
    case 'set':
      return {
        loading: false,
        user: action.payload,
        error: null,
      };
    case 'error':
      return {
        loading: false,
        user: state.user,
        error: action.payload,
      };
    case 'request':
      return {
        loading: true,
        user: state.user,
        error: null,
      };
    default:
      return state;
  }
};

const AuthProvider: React.FC<Props> = ({
  rules,
  children,
  fallback = null,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const token = getAuthToken();

  useEffect(() => {
    const getUser = async () => {
      try {
        const profile = await getProfile(token);

        dispatch({ type: 'set', payload: profile });
      } catch (e) {
        dispatch({ type: 'error', payload: e.message });
      }
    };

    getUser();
  }, [token]);

  const updateUser = useCallback(
    (user: UserInfoType) => dispatch({ type: 'set', payload: user }),
    [dispatch],
  );

  const isPermittedTo = useCallback(
    <P extends string = string>(
      action: P | P[],
      data?: Record<string, unknown>,
    ) => {
      return check(rules, state.user, action, data);
    },
    [rules, state.user],
  );

  if (state.loading) {
    return fallback;
  }

  const contextValue = {
    ...state,
    isPermittedTo,
    updateUser,
    logout: logoutHere,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
