import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { StatusValue } from '@rtt-libs/types';
import { TextFieldAdapter } from '@rtt-libs/views';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

type Props = {
  applyingStatus?: StatusValue;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeStatus: (values: { notices?: string }) => void;
  isOpen: boolean;
};

/**
 * Dialog for confirm status & add notice if admin have chosen declined status
 */
const ChangeStatusDialog: React.FC<Props> = ({
  applyingStatus,
  onClose,
  onChangeStatus,
  isOpen,
}) => {
  const [t] = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={e => e.stopPropagation()}
      maxWidth="sm"
      fullWidth
    >
      <Form onSubmit={onChangeStatus}>
        {({ handleSubmit }) => (
          <form
            onSubmit={e => {
              e.stopPropagation();
              handleSubmit(e);
            }}
          >
            <DialogTitle>
              {t('admin.tables.common.dialogChangeStatus')}
            </DialogTitle>
            {applyingStatus === 'declined' ? (
              <DialogContent>
                <Field
                  name="notices"
                  component={TextFieldAdapter}
                  label={t('admin.tables.common.dialogChangeStatus')}
                  placeholder={t('admin.tables.common.dialogChangeStatus')}
                  required
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                />
              </DialogContent>
            ) : null}
            <DialogActions>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {t('controls.change')}
              </Button>
              <Button onClick={onClose}>{t('controls.cancel')}</Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default ChangeStatusDialog;
