import { StatusValue } from '@rtt-libs/types';

export const getDistributorAvailableStatuses = (
  status: StatusValue,
): StatusValue[] => {
  switch (status) {
    case 'new':
      return [/* 'new', */ 'declined', /* 'updated', */ 'active'];
    case 'declined':
      return [/* 'declined', 'updated', */ 'active'];
    case 'updated':
      return [/* 'updated', */ 'declined', 'active'];
    case 'active':
      return ['deactivated', 'disabled', 'deletion'];
    case 'deactivated':
      return ['active', 'disabled', 'deletion'];
    case 'disabled':
      return ['active', 'deactivated', 'deletion'];
    case 'deletion':
      return ['active', 'deactivated', 'disabled'];
    default:
      return [];
  }
};

export const getRttAvailableStatuses = (status: StatusValue): StatusValue[] => {
  switch (status) {
    case 'new':
      return ['declined', 'active'];
    case 'declined':
      return ['active'];
    case 'updated':
      return ['declined', 'active'];
    case 'active':
      return ['deactivated'];
    case 'deactivated':
      return ['active'];
    default:
      return [];
  }
};

export const isCompanyRegistered = (status: StatusValue): boolean => {
  return ['active', 'deactivated', 'disabled', 'deletion'].includes(status);
};

export const isCompanyActive = (status: StatusValue): boolean => {
  return ['active', 'deactivated', 'deletion'].includes(status);
};
