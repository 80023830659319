import { createMuiTheme } from '@material-ui/core';

const customTheme = createMuiTheme({
  palette: {
    background: {
      default: '#F5F6F8',
    },
    primary: {
      main: '#109AEF',
    },
    secondary: {
      main: '#18AF51',
    },
    error: {
      main: '#F7664F',
    },
    text: {
      primary: '#334D6E',
      secondary: '#90A0B7',
      hint: '#C2CFE0',
      disabled: '#D5D5D5',
    },
    divider: '#EBEFF2',
    // contrastThreshold: 1.5,
  },
  typography: {
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 13,
    button: {
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    h3: {
      fontSize: 14,
      fontWeight: 500,
    },
    h6: {
      fontSize: 15,
      fontWeight: 500,
    },
    body1: {
      fontSize: 13,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 11,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '10px 24px',
      },
      text: {
        padding: '10px 24px',
      },
      outlined: {
        padding: '10px 24px',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#C2CFE0',
      },
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: '#334D6E',
        borderRadius: 4,
        fontSize: 9,
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: '#EBEFF2',
      },
      head: {
        opacity: 0.5,
        fontWeight: 600,
      },
    },
    MuiTableRow: {
      root: {
        '&.MuiTableRow-hover:hover': {
          backgroundColor: 'rgba(16, 156, 241, 0.05)', // #F2F9FF ~~ thru primary with opacity
        },
      },
    },
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
      size: 'small',
    },
    MuiSvgIcon: {
      fontSize: 'small',
    },
  },
});

export default customTheme;
