import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  connectToSystemChannel,
  disconnectFromSystemChannel,
} from '../duck/actions';

export const useSystemChannel = (userId?: number) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (userId) dispatch(connectToSystemChannel(userId));

    return () => {
      if (userId) dispatch(disconnectFromSystemChannel(userId));
    };
  }, [userId, dispatch]);
};
