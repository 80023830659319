import { Button, ButtonGroup, FormHelperText, Grid } from '@material-ui/core';
import { StatusValue } from '@rtt-libs/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusChangeHandler } from '../types';
import ChangeStatusDialog from './ChangeStatusDialog';

type Props = {
  id: number;
  status: StatusValue;
  error: string | undefined;
  onStatusChange: StatusChangeHandler;
  availableStatuses: StatusValue[];
};

/**
 * Button group with Action dialog to change distributor/rtt status
 * with administrator notice about last declination of the distributor/rtt.
 */
const ChangeStatusButtons: React.FC<Props> = ({
  id,
  error,
  onStatusChange,
  availableStatuses,
}) => {
  const [t] = useTranslation();

  const [applyingStatus, setApplyingStatus] = useState<StatusValue | undefined>(
    undefined,
  );

  const onClick = (statusNew: StatusValue | undefined) => () => {
    setApplyingStatus(statusNew);
  };

  const onClose = () => {
    setApplyingStatus(undefined);
  };

  const onChangeStatus = ({ notices }: { notices?: string }) => {
    onStatusChange({
      id,
      status: applyingStatus as StatusValue,
      notices,
    });
    onClose();
  };

  return (
    <Grid item xs={12} container justify="center">
      <Grid item>
        <ButtonGroup color="primary" aria-label="change status">
          {availableStatuses.map(statusValue => (
            <Button key={statusValue} onClick={onClick(statusValue)}>
              {t(`status.change.${statusValue}`)}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <FormHelperText error={!!error}>{error || ' '}</FormHelperText>
      </Grid>

      <ChangeStatusDialog
        applyingStatus={applyingStatus}
        onClose={onClose}
        onChangeStatus={onChangeStatus}
        isOpen={!!applyingStatus}
      />
    </Grid>
  );
};

export default ChangeStatusButtons;
