import {
  createStyles,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const useStyles = makeStyles(
  createStyles({
    passwordField: {
      minWidth: 120,
    },
  }),
);

const ModeratorPassword: React.FC<Props> = ({ id, value }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [showPassword, setPasswordState] = useState(false);

  if (value) {
    const handleClickShowPassword = () => {
      setPasswordState(!showPassword);
    };

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();
    };

    const renderVisibilityIcon = (
      <InputAdornment position="end">
        <IconButton
          aria-label={t('controls.togglePasswordVisibility')}
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );

    return (
      <Input
        className={classes.passwordField}
        type={showPassword ? 'text' : 'password'}
        value={value}
        readOnly
        endAdornment={renderVisibilityIcon}
      />
    );
  }

  return null;
};

export default ModeratorPassword;
