import { createLocation, Location } from 'history';
import { LinkProps, matchPath, useLocation } from 'react-router-dom';
import { IsActiveFn } from './types';

const resolveToLocation = (to: LinkProps['to'], currentLocation: Location) =>
  typeof to === 'function' ? to(currentLocation) : to;

const normalizeToLocation = (
  to: string | ReturnType<typeof resolveToLocation>,
  currentLocation: Location,
) => {
  return typeof to === 'string'
    ? createLocation(to, null, undefined, currentLocation)
    : to;
};

type UseLocationOptions = {
  location?: Location;
  exact?: boolean;
  strict?: boolean;
  isActive?: IsActiveFn;
};

const useActiveLocation = (
  to: LinkProps['to'],
  {
    location: locationProp,
    exact = true,
    strict,
    isActive: isActiveProp,
  }: UseLocationOptions,
) => {
  const location = useLocation();
  const currentLocation = locationProp || location;
  const toLocation = normalizeToLocation(
    resolveToLocation(to, currentLocation),
    currentLocation,
  );
  const { pathname: path } = toLocation;
  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');
  const match = escapedPath
    ? matchPath(currentLocation.pathname, {
        path: escapedPath,
        exact,
        strict,
      })
    : null;
  const isActive = !!(isActiveProp
    ? isActiveProp(match, currentLocation)
    : match);
  return isActive;
};

export default useActiveLocation;
