import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  createStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectChatData,
  selectChatHasMore,
  selectChatLoading,
  selectLastChatId,
} from '../../duck/selectors';
import type {
  ChannelsInfinitePaginationParams,
  CompanyInfo,
  Message,
  OpponentType,
} from '../../types';
import InfiniteScrollWrapper from '../Chats/InfiniteScrollWrapper';

interface Props {
  onSelect(id: string): void;
  opponentType: OpponentType;
  selectedId?: string;
  onLoadMore: (params?: ChannelsInfinitePaginationParams) => void;
  managerInTitle?: boolean;
}

const ChatList = ({
  onSelect,
  opponentType,
  selectedId,
  onLoadMore,
  managerInTitle,
}: Props) => {
  const listRef = useRef<HTMLUListElement>(null);

  const lastChatId = useSelector(selectLastChatId);
  const loading = useSelector(selectChatLoading);
  const hasMore = useSelector(selectChatHasMore);

  const handleLoadMore = useCallback(() => {
    onLoadMore({ id: lastChatId });
  }, [lastChatId, onLoadMore]);

  return (
    <InfiniteScrollWrapper
      hasMore={hasMore}
      loading={loading}
      onLoadMore={handleLoadMore}
      scrollOwnerRef={listRef}
      render={({ sentinel, children }) => (
        <Box clone flex="1 1 0" overflow="auto">
          <List ref={listRef}>
            {children}
            {sentinel}
          </List>
        </Box>
      )}
    >
      <ChatItems
        onSelect={onSelect}
        opponentType={opponentType}
        selectedId={selectedId}
        managerInTitle={managerInTitle}
      />

      {loading && (
        <ListItem>
          <Box clone marginX="auto">
            <CircularProgress />
          </Box>
        </ListItem>
      )}

      {!loading && hasMore && (
        <ListItem>
          <Box marginX="auto">
            <LoadMoreButton
              onLoadMore={handleLoadMore}
              lastElementId={lastChatId}
            />
          </Box>
        </ListItem>
      )}
    </InfiniteScrollWrapper>
  );
};

export default ChatList;

type ChatItemsProps = {
  onSelect(id: string): void;
  opponentType: OpponentType;
  selectedId?: string;
  managerInTitle?: boolean;
};

const ChatItems: React.FC<ChatItemsProps> = ({
  opponentType,
  onSelect,
  selectedId,
  managerInTitle,
  children,
}) => {
  const [t] = useTranslation();
  const chatList = useSelector(selectChatData);

  return (
    <>
      {chatList.map(chat => {
        const renderMessageInfo = chat.lastMessageDate && (
          <ChatLastMessageInfo
            date={chat.lastMessageDate}
            hasUnseenMessages={chat.hasUnseenMessages}
          />
        );

        let chatTitle = t('chats.support.channelTitle', { id: chat.id });

        if (opponentType !== 'admin') {
          const managerAdditionText =
            managerInTitle && chat.manager
              ? ` \u2014 ${representFullName(chat.manager)}`
              : '';

          chatTitle = `${chat[opponentType]?.name}${managerAdditionText} ${
            chat.isBlocked ? `[ ${t('chats.blocked')} ]` : ''
          }`;
        }

        return (
          <ChatListItem
            key={chat.id}
            avatar={
              opponentType !== 'admin' ? (
                <ChatAvatar opponentInfo={chat[opponentType]} />
              ) : undefined
            }
            title={chatTitle}
            lastMessage={
              <ChatLastMessageSummary lastMessage={chat.lastMessage} />
            }
            messageInfo={renderMessageInfo}
            id={chat.id}
            onClick={onSelect}
            selected={selectedId === chat.id}
            resolved={chat.isResolved}
          />
        );
      })}
      {children}
    </>
  );
};

type ChatListItemProps = {
  id: string;
  title: string;
  lastMessage: React.ReactNode;
  avatar?: React.ReactElement;
  messageInfo: React.ReactNode;
  onClick?(id: string): void;
  selected?: boolean;
  resolved?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // prevent overlapping date
    primary: {
      marginRight: theme.spacing(7.5),
    },
  }),
);

const ChatListItem = ({
  id,
  title,
  lastMessage,
  avatar,
  messageInfo,
  onClick,
  selected,
  resolved,
}: ChatListItemProps) => {
  const [t] = useTranslation();

  const classes = useStyles();

  const primaryTypographyProps = {
    title,
    noWrap: true,
  };

  const conditionalProps = resolved
    ? {
        primaryTypographyProps: {
          ...primaryTypographyProps,
          color: 'textSecondary' as const,
        },
        secondary: `[ ${t('chats.support.closeChannel')} ]`,
      }
    : undefined;

  return (
    <ListItem divider button onClick={() => onClick?.(id)} selected={selected}>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText
        classes={classes}
        primary={title}
        primaryTypographyProps={primaryTypographyProps}
        secondary={lastMessage}
        secondaryTypographyProps={{ component: 'div' }}
        {...conditionalProps}
      />
      {messageInfo}
    </ListItem>
  );
};

type ChatAvatarProps = {
  opponentInfo: CompanyInfo;
};

const ChatContentfulAvatar = ({ opponentInfo }: ChatAvatarProps) => {
  const nameAbbr = opponentInfo.name
    .split(' ')
    .map((v: string) => v[0])
    .join('');

  return (
    <Avatar
      alt={`${opponentInfo.name} logo`}
      src={opponentInfo.logo ?? undefined}
    >
      {nameAbbr}
    </Avatar>
  );
};

const EmptyAvatar = () => (
  <Avatar>
    <AccountCircleIcon />
  </Avatar>
);

export const ChatAvatar = ({ opponentInfo }: Partial<ChatAvatarProps>) => {
  if (opponentInfo) return <ChatContentfulAvatar opponentInfo={opponentInfo} />;

  return <EmptyAvatar />;
};

type ChatLastMessageInfoProps = {
  date: string;
  hasUnseenMessages?: boolean;
};

const ChatLastMessageInfo = ({
  date,
  hasUnseenMessages,
}: ChatLastMessageInfoProps) => {
  const dateString = new Date(date).toLocaleDateString();

  return (
    <ListItemSecondaryAction>
      <Typography variant="caption" component="div">
        {dateString}
      </Typography>
      <Box clone width="100%">
        <Badge
          color="primary"
          invisible={!hasUnseenMessages}
          badgeContent=" "
          variant="dot"
        />
      </Box>
    </ListItemSecondaryAction>
  );
};

type LoadMoreButtonProps = {
  onLoadMore: (params?: ChannelsInfinitePaginationParams) => void;
  lastElementId?: string;
  loading?: boolean;
  disabled?: boolean;
};

const LoadMoreButton = ({ lastElementId, onLoadMore }: LoadMoreButtonProps) => {
  const [t] = useTranslation();

  const handleLoadMore = () => {
    onLoadMore({ id: lastElementId });
  };

  if (lastElementId === undefined) return null;

  return (
    <Button type="button" onClick={handleLoadMore} size="small">
      {t('controls.loadMore')}
    </Button>
  );
};

type ChatLastMessageSummaryProps = {
  lastMessage?: Message;
};

const ChatLastMessageSummary = ({
  lastMessage,
}: ChatLastMessageSummaryProps) => {
  const [t] = useTranslation();

  if (lastMessage?.data.imgSrc) {
    return (
      <Typography variant="body2" component="div">
        {`[${t('chats.messages.imageSummary')}]`}
      </Typography>
    );
  }

  if (lastMessage?.data.product) {
    return (
      <Typography variant="body2" component="div">
        {`[${t('chats.messages.productSummary')}: ${
          lastMessage?.data.product.sku
        }]`}
      </Typography>
    );
  }

  if (lastMessage?.data.message) {
    return (
      <Box maxWidth={320}>
        <Typography noWrap component="div">
          {lastMessage.data.message}
        </Typography>
      </Box>
    );
  }

  return null;
};

const representFullName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => `${firstName} ${lastName}`;
