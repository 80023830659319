import { Collapse, List } from '@material-ui/core';
import React from 'react';
import LinkItem from './LinkItem';
import { NavigationLink } from './types';

type NestedLinkListProps = { links: NavigationLink[]; expanded?: boolean };

const NestedLinkList: React.FC<NestedLinkListProps> = ({
  links,
  expanded = false,
}) => {
  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {links.map(link => (
          <LinkItem key={link.label} child {...link} />
        ))}
      </List>
    </Collapse>
  );
};

export default NestedLinkList;
