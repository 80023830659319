import React from 'react';
import ProductSelectModalContent from '../../../productsAddition/view/ProductSelectModalContent';
import { ChatMessageWithPostData } from '../../../types';
import type { ProductMessagePayload } from '../../../types';

type ProductMessageModalContentProps = {
  onSend?(messages: Partial<ChatMessageWithPostData>[]): void;
  onClose(): void;
  rttId: number;
};

const ProductMessageModalContent = ({
  onSend,
  onClose,
  rttId,
}: ProductMessageModalContentProps) => {
  const handleSubmit = (product: ProductMessagePayload) => {
    if (onSend) {
      onSend([
        {
          postData: {
            type: 'product',
            data: {
              productId: product.id,
            },
          },
          product,
        },
      ]);
    }

    onClose();
  };

  return (
    <ProductSelectModalContent
      rttId={rttId}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default ProductMessageModalContent;
