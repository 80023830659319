import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { branchName } from './const';
import { SettingsState } from './reducer';

export type PartialRootState = {
  [branchName]: SettingsState;
};

export const selectEmailSettingsData: Selector<
  PartialRootState,
  { email?: string; loading: boolean; error: string | null }
> = get(branchName);
