import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { assets } from '@rtt-libs/constants';
import React from 'react';

type Props = {
  imgSrc?: string;
  title?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: theme.spacing(6),
      maxHeight: theme.spacing(6),
      height: 'auto',
      paddingRight: theme.spacing(1),
      objectFit: 'contain',
    },
  }),
);

const Logo: React.FC<Props> = ({
  imgSrc = assets.DEFAULT_LOGO,
  title = 'OSA: online sales agent',
}) => {
  const classes = useStyles();

  return (
    <Box width="100%" display="flex" alignItems="center">
      <img
        src={imgSrc}
        alt="company logo"
        height="48px"
        width="48px"
        className={classes.logo}
        onError={e => {
          (e.target as HTMLImageElement).onerror = null;
          (e.target as HTMLImageElement).src = assets.DEFAULT_LOGO;
        }}
      />

      <Typography variant="h6" component="h2" noWrap>
        {title}
      </Typography>
    </Box>
  );
};

export default Logo;
