import { formErrorTransform } from '@rtt-libs/api-services';
import type { AttachmentDocumentType } from '@rtt-libs/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';

export const uploadImageAPI = (formValues: File) => {
  const formData = new FormData();

  formData.append('image', formValues);

  return api
    .post<AttachmentDocumentType>(ENDPOINTS.uploadImage, formData)
    .then(({ data }) => data)
    .catch(e => {
      throw formErrorTransform(e, undefined, undefined, 'message');
    });
};
