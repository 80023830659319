import {
  DEFAULT_LNG,
  I18N_LANG_KEY,
  SUPPORTED_LOCALES,
} from '@rtt-libs/constants';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_API_URL, COOKIE_NAME, MOCK_API_CALLS } from '../environment';
import applyApiMock from './__stubs__/mockSetup';

export const getAcceptLanguage = () => {
  const defaultHeaderValue = `${DEFAULT_LNG};q=0.8, en;q=0.5, *;q=0.3`;
  const currentLocale = localStorage.getItem(I18N_LANG_KEY)?.slice(0, 2) || '';

  if (SUPPORTED_LOCALES.includes(currentLocale)) {
    return `${currentLocale}, ${defaultHeaderValue}`;
  }

  return defaultHeaderValue;
};

const setupApi = (token?: string, baseURL?: string) =>
  axios.create({
    baseURL,
    headers: {
      'Accept-language': getAcceptLanguage(),
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  });

export const getAuthToken = (): string | undefined => Cookies.get(COOKIE_NAME);

const token = getAuthToken();

const api = setupApi(token, BASE_API_URL);

if (process.env.NODE_ENV !== 'production' && MOCK_API_CALLS) {
  applyApiMock(api);
}

export default api;

export const setApiPostHeader = (headerName: string, value?: string) => {
  api.defaults.headers.post[headerName] = value;
};
