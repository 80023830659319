import { Button, Container, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as Sentry from '@sentry/browser';
import React from 'react';
import {
  ErrorBoundary,
  ErrorBoundaryProps,
  FallbackProps,
} from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

const ErrorBoundaryWithFallback: React.FC<Partial<
  ErrorBoundaryProps
>> = props => {
  return <ErrorBoundary FallbackComponent={ErrorFallback} {...props} />;
};

export default ErrorBoundaryWithFallback;

const ErrorFallback = ({
  error,
  componentStack,
  resetErrorBoundary,
}: FallbackProps) => {
  const [t] = useTranslation();

  if (error) {
    handleChunkErrorReload(error.message);
  }

  Sentry.withScope(scope => {
    scope.setExtra('componentStack', componentStack);
    Sentry.captureException(error);
  });

  return (
    <Container maxWidth="sm">
      <Alert role="alert" severity="error">
        <AlertTitle>{t('common.errorOccurred')}</AlertTitle>
        {t('common.errorOccurredDescription')}
      </Alert>

      <Box my={2}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={resetErrorBoundary}
        >
          {t('common.errorTryAgain')}
        </Button>
      </Box>
    </Container>
  );
};

const chunkLoadRegexp = /Loading chunk .* failed/;

const handleChunkErrorReload = (errorMessage: string) => {
  if (chunkLoadRegexp.test(errorMessage)) {
    window.location.reload();
  }
};
