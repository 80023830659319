import { Theme, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import customTheme from './customTheme';

type Props = {
  theme?: Theme;
};

const CustomThemeProvider: React.FC<Props> = ({
  children,
  theme = customTheme,
}) => <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;

export default CustomThemeProvider;
