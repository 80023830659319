import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const useStyles = makeStyles(
  createStyles({
    nameString: {
      whiteSpace: 'nowrap',
    },
  }),
);

const ModeratorName: React.FC<Props> = ({ id, value }) => {
  const classes = useStyles();

  if (typeof value !== 'string') return null;

  return <Typography className={classes.nameString}>{value}</Typography>;
};

export default ModeratorName;
