import { call, put } from 'redux-saga/effects';
import * as api from '../../../api';
import type { Channel, InfinitePaginationWrapper } from '../../../types';
import {
  closeSupportChannelFailure,
  closeSupportChannelRequest,
  closeSupportChannelSuccess,
  getChatListFailure,
  getChatListRequest,
  getChatListSuccess,
  loadMoreChatListFailure,
  loadMoreChatListRequest,
  loadMoreChatListSuccess,
  makeChannelReadFailure,
  makeChannelReadRequest,
  makeChannelReadSuccess,
  resolveChatFailure,
  resolveChatRequest,
  resolveChatSuccess,
  assignSupportChannelRequest,
  assignSupportChannelSuccess,
  assignSupportChannelFailure,
} from '../../actions';

export function* getChatListWorker({
  payload,
  meta: channelType,
}: ReturnType<typeof getChatListRequest>) {
  try {
    const chats: InfinitePaginationWrapper<Channel> = yield call(
      api.getChatList,
      channelType,
      payload,
    );
    yield put(getChatListSuccess(chats));
  } catch (e) {
    yield put(getChatListFailure(e));
  }
}

export function* loadMoreChatListWorker({
  payload,
  meta: channelType,
}: ReturnType<typeof loadMoreChatListRequest>) {
  try {
    const chats: InfinitePaginationWrapper<Channel> = yield call(
      api.getChatList,
      channelType,
      payload,
    );
    yield put(loadMoreChatListSuccess(chats));
  } catch (e) {
    yield put(loadMoreChatListFailure(e));
  }
}

export function* resolveChatWorker({
  payload,
}: ReturnType<typeof resolveChatRequest>) {
  try {
    const chat: Channel = yield call(api.resolveChat, payload);
    yield put(resolveChatSuccess(chat));
  } catch (e) {
    yield put(resolveChatFailure(e.message));
  }
}

export function* makeChannelReadWorker({
  payload,
}: ReturnType<typeof makeChannelReadRequest>) {
  try {
    yield call(api.markChatReadAPI, payload);
    yield put(makeChannelReadSuccess(payload.channelId));
  } catch (e) {
    yield put(makeChannelReadFailure(e.message));
  }
}

export function* assignSupportChannelWorker({
  payload,
}: ReturnType<typeof assignSupportChannelRequest>) {
  try {
    const channel: Channel = yield call(api.assignSupportChannelAPI, payload);
    yield put(assignSupportChannelSuccess(channel));
  } catch (e) {
    yield put(assignSupportChannelFailure(e.message));
  }
}

export function* closeSupportChannelWorker({
  payload,
}: ReturnType<typeof closeSupportChannelRequest>) {
  try {
    yield call(api.closeSupportChannelAPI, payload);
    yield put(closeSupportChannelSuccess(payload));
  } catch (e) {
    yield put(closeSupportChannelFailure(e.message));
  }
}
