import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelsInfinitePaginationParams, OpponentType } from '../../types';
import ChatList from './ChatList';
import SearchChatRow from './SearchChatRow';

const ChatListSectionHeader: React.FC<{ actions: React.ReactNode }> = ({
  children,
  actions,
}) => {
  const [t] = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography component="h2" variant="h5">
        {children || t('distributor.chats.sectionTitle', 'Chats')}
      </Typography>

      {actions}
    </Box>
  );
};

interface Props {
  onParamsChange: (params?: ChannelsInfinitePaginationParams) => void;
  onLoadMore: (params?: ChannelsInfinitePaginationParams) => void;
  onSelect(id: string): void;
  selectedId?: string;
  opponentType: OpponentType;
  headerAction?: React.ReactNode;
  title?: React.ReactNode;
  filterRow?: React.ReactNode;
  managerInTitle?: boolean;
}

const ChatListSection = ({
  onParamsChange,
  onLoadMore,
  onSelect,
  selectedId,
  opponentType,
  headerAction,
  title,
  filterRow,
  managerInTitle,
}: Props) => {
  return (
    <>
      <ChatListSectionHeader actions={headerAction}>
        {title}
      </ChatListSectionHeader>

      <SearchChatRow onChange={search => onParamsChange({ search })} />

      {filterRow}

      <ChatList
        onSelect={onSelect}
        selectedId={selectedId}
        opponentType={opponentType}
        onLoadMore={onLoadMore}
        managerInTitle={managerInTitle}
      />
    </>
  );
};

export default ChatListSection;
