import { getAcceptLanguageHeader, setupApi } from '@rtt-libs/api-services';
import { getAuthToken } from '@rtt-libs/auth';
import type { LocaleValue } from '@rtt-libs/types';
import { BASE_API_URL } from '../environment';

const token = getAuthToken();

const api = setupApi(token, BASE_API_URL);

export default api;

export const setAcceptLanguageHeader = (lng: LocaleValue) => {
  api.defaults.headers['Accept-language'] = getAcceptLanguageHeader(lng);
};
