export const CATEGORIES_REQUEST = '@rtt[A]/CATEGORIES_REQUEST' as const;
export const CATEGORIES_SUCCESS = '@rtt[A]/CATEGORIES_SUCCESS' as const;
export const CATEGORIES_FAILURE = '@rtt[A]/CATEGORIES_FAILURE' as const;

export const CATEGORIES_ADD_REQUEST = '@rtt[A]/CATEGORIES_ADD_REQUEST' as const;
export const CATEGORIES_ADD_RESPONSE = '@rtt[A]/CATEGORIES_ADD_RESPONSE' as const;
export const CATEGORIES_ADD_SUCCESS = '@rtt[A]/CATEGORIES_ADD_SUCCESS' as const;
export const CATEGORIES_ADD_FAILURE = '@rtt[A]/CATEGORIES_ADD_FAILURE' as const;

export const CATEGORIES_EDIT_REQUEST = '@rtt[A]/CATEGORIES_EDIT_REQUEST' as const;
export const CATEGORIES_EDIT_RESPONSE = '@rtt[A]/CATEGORIES_EDIT_RESPONSE' as const;
export const CATEGORIES_EDIT_SUCCESS = '@rtt[A]/CATEGORIES_EDIT_SUCCESS' as const;
export const CATEGORIES_EDIT_FAILURE = '@rtt[A]/CATEGORIES_EDIT_FAILURE' as const;

export const CATEGORIES_MOVE_REQUEST = '@rtt[A]/CATEGORIES_MOVE_REQUEST' as const;
export const CATEGORIES_MOVE_SUCCESS = '@rtt[A]/CATEGORIES_MOVE_SUCCESS' as const;
export const CATEGORIES_MOVE_FAILURE = '@rtt[A]/CATEGORIES_MOVE_FAILURE' as const;

export const CATEGORIES_DELETE_REQUEST = '@rtt[A]/CATEGORIES_DELETE_REQUEST' as const;
export const CATEGORIES_DELETE_RESPONSE = '@rtt[A]/CATEGORIES_DELETE_RESPONSE' as const;
export const CATEGORIES_DELETE_SUCCESS = '@rtt[A]/CATEGORIES_DELETE_SUCCESS' as const;
export const CATEGORIES_DELETE_FAILURE = '@rtt[A]/CATEGORIES_DELETE_FAILURE' as const;

export const ALL_CATEGORIES_REQUEST = '@rtt[D]/ALL_CATEGORIES_REQUEST' as const;
export const ALL_CATEGORIES_SUCCESS = '@rtt[D]/ALL_CATEGORIES_SUCCESS' as const;
export const ALL_CATEGORIES_FAILURE = '@rtt[D]/ALL_CATEGORIES_FAILURE' as const;
