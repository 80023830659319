import { Token, UsedLocales, UserInfoType } from '../types';
import getUserProfile from '../api/getUserProfile';
import { getAuthToken } from '../cookieHandlers';
import { profileLocalStorage } from '../profileStorage';

/**
 * Fetch an user profile data, which is bound to the token
 * @param token - if skipped will be got from cookie
 * @param locale - locale lang string
 */
export const getProfile = async (
  token?: Token,
  locale?: UsedLocales,
): Promise<UserInfoType | null> => {
  if (typeof token !== 'string' && token !== undefined)
    throw new Error('token is unacceptable');

  const checkedToken = token === undefined ? getAuthToken() : token;
  if (!checkedToken) return null;

  try {
    const { data } = await getUserProfile(token || checkedToken, locale);

    // save profile to localStorage
    profileLocalStorage.set(data);

    return data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e.message);
    return null;
  }
};

/**
 * Fetch an user role of token owner.
 */
export const getUserRole = (token?: Token, locale?: UsedLocales) =>
  getProfile(token, locale).then(profile => profile && profile.role);
