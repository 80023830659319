import { chatReceiveSystemNotification } from '../../actions';
import { isNotificationEnabled } from '../../../utils';

const notification = new Audio(`${process.env.PUBLIC_URL}/notification.mp3`);

export function systemNotificationWorker({
  payload: { type },
}: ReturnType<typeof chatReceiveSystemNotification>) {
  if (type !== 'new_message' || !isNotificationEnabled()) return;

  try {
    notification.play();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Sound error');
  }
}
