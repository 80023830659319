import React from 'react';
import {
  Field,
  Html5ValidationFieldProps,
  Messages,
} from 'react-final-form-html5-validation';
import { useTranslation } from 'react-i18next';

const FieldWithMessages: React.FC<Html5ValidationFieldProps> = props => {
  const [t] = useTranslation();

  const messages: Messages = {
    badInput: t('validation.badInput'),
    patternMismatch: t('validation.patternMismatch'),
    rangeOverflow: t('validation.rangeOverflow'),
    rangeUnderflow: t('validation.rangeUnderflow'),
    stepMismatch: t('validation.stepMismatch'),
    tooLong: t('validation.tooLong'),
    tooShort: t('validation.tooShort'),
    typeMismatch: t('validation.typeMismatch'),
    valueMissing: t('validation.valueMissing'),
  };

  return <Field {...messages} {...props} />;
};

export default FieldWithMessages;
