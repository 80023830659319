import { ProductMessagePayload } from '../../types';
import { ProductInfo } from '../types';

export const mapProductToMessagePayload = ({
  id,
  sku,
  title,
  price,
  saleMeasurement,
  image,
}: ProductInfo): ProductMessagePayload => ({
  id,
  sku,
  title,
  price,
  saleMeasurement,
  img: image?.related.data.find(img => img.is_thumbnail)?.src || image?.src,
});
