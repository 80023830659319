import {
  DistributorDetails,
  DistributorStats,
  Pagination,
  Status,
  StatusValue,
  StatusWithNotice,
} from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import {
  Distributor,
  DistributorsGetParams,
  StatsFilterDate,
} from '../../api/distributors';
import * as TYPES from './types';

export const getDistributorsRequest = (
  payload?: Partial<DistributorsGetParams>,
) => ({
  type: TYPES.GET_DISTRIBUTOR_REQUEST,
  payload,
});

export const getDistributorsSuccess = (
  payload: Distributor[],
  meta: { pagination: Pagination },
) => ({
  type: TYPES.GET_DISTRIBUTOR_SUCCESS,
  payload,
  meta,
});

export const getDistributorsFailure = (error: string) => ({
  type: TYPES.GET_DISTRIBUTOR_FAILURE,
  payload: error,
});

export const getDistributorDetailsRequest = (payload: number) => ({
  type: TYPES.GET_DISTRIBUTOR_DETAILS_REQUEST,
  payload,
});

export const getDistributorDetailsSuccess = (
  payload: DistributorDetails & DistributorStats,
  meta?: { notices: string | null },
) => ({
  type: TYPES.GET_DISTRIBUTOR_DETAILS_SUCCESS,
  payload,
  meta,
});

export const getDistributorDetailsFailure = (error: string) => ({
  type: TYPES.GET_DISTRIBUTOR_DETAILS_FAILURE,
  payload: error,
});

export const changeDistributorStatusRequest = (payload: {
  id: number;
  status: StatusValue;
  notices?: string;
}) => ({
  type: TYPES.CHANGE_DISTRIBUTOR_STATUS_REQUEST,
  payload,
});

export const changeDistributorStatusSuccess = (
  payload: StatusWithNotice,
  meta: { id: number; status: Status },
) => ({
  type: TYPES.CHANGE_DISTRIBUTOR_STATUS_SUCCESS,
  payload,
  meta,
});

export const changeDistributorStatusFailure = (error: string) => ({
  type: TYPES.CHANGE_DISTRIBUTOR_STATUS_FAILURE,
  payload: error,
});

export const changeDistributorRequest = (
  payload: Omit<DistributorDetails, 'status' | 'phone'>,
) => ({
  type: TYPES.CHANGE_DISTRIBUTOR_REQUEST,
  payload,
});

export const changeDistributorSuccess = (
  payload: DistributorDetails & DistributorStats,
) => ({
  type: TYPES.CHANGE_DISTRIBUTOR_SUCCESS,
  payload,
});

export const changeDistributorFailure = (
  errors: Partial<Record<keyof DistributorDetails | typeof FORM_ERROR, string>>,
) => ({
  type: TYPES.CHANGE_DISTRIBUTOR_FAILURE,
  payload: errors,
});

export const getDistributorStatsRequest = (payload: {
  id: number;
  params: StatsFilterDate;
}) => ({
  type: TYPES.DISTRIBUTORS_GET_STATS_REQUEST,
  payload,
});

export const getDistributorStatsSuccess = (
  payload: DistributorStats['stats'],
) => ({
  type: TYPES.DISTRIBUTORS_GET_STATS_SUCCESS,
  payload,
});

export const getDistributorStatsFailure = (errors: string) => ({
  type: TYPES.DISTRIBUTORS_GET_STATS_FAILURE,
  payload: errors,
});

export const getDistributorOptionsRequest = (
  payload?: Partial<DistributorsGetParams>,
) => ({
  type: TYPES.DISTRIBUTORS_GET_OPTIONS_REQUEST,
  payload,
});

export const getDistributorOptionsSuccess = (
  payload: Distributor[],
  meta: { pagination: Pagination },
) => ({
  type: TYPES.DISTRIBUTORS_GET_OPTIONS_SUCCESS,
  payload,
  meta,
});

export const getDistributorOptionsFailure = (error: string) => ({
  type: TYPES.DISTRIBUTORS_GET_OPTIONS_FAILURE,
  payload: error,
});

export type Actions =
  | ReturnType<typeof getDistributorsRequest>
  | ReturnType<typeof getDistributorsSuccess>
  | ReturnType<typeof getDistributorsFailure>
  | ReturnType<typeof getDistributorDetailsRequest>
  | ReturnType<typeof getDistributorDetailsSuccess>
  | ReturnType<typeof getDistributorDetailsFailure>
  | ReturnType<typeof changeDistributorStatusRequest>
  | ReturnType<typeof changeDistributorStatusSuccess>
  | ReturnType<typeof changeDistributorStatusFailure>
  | ReturnType<typeof changeDistributorRequest>
  | ReturnType<typeof changeDistributorSuccess>
  | ReturnType<typeof changeDistributorFailure>
  | ReturnType<typeof getDistributorStatsRequest>
  | ReturnType<typeof getDistributorStatsSuccess>
  | ReturnType<typeof getDistributorStatsFailure>
  | ReturnType<typeof getDistributorOptionsRequest>
  | ReturnType<typeof getDistributorOptionsSuccess>
  | ReturnType<typeof getDistributorOptionsFailure>;
