import { Pagination, RttDetails, RttStats } from '@rtt-libs/types';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { Rtt } from '../../api/rtt';
import { branchName, mainReducerKey } from './const';
import { State } from './reducer';

export type PartialRootState = {
  rtt: State;
};

export const getRttSel: Selector<PartialRootState, Rtt[]> = get([
  branchName,
  mainReducerKey,
]);

export const getPaginationSel: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const getLoadingStateSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const getErrorSel: Selector<PartialRootState, string | undefined> = get([
  branchName,
  'error',
]);

export const getRttDetailsSel: Selector<
  PartialRootState,
  (RttDetails & RttStats) | undefined
> = get([branchName, 'current']);

export const getNoticesSel: Selector<PartialRootState, string | null> = get([
  branchName,
  'currentNotices',
]);

export const getTableContentSel = createSelector(
  [getRttSel, getPaginationSel, getLoadingStateSel, getErrorSel],
  (rtt, pagination, loading, error) => ({
    rtt,
    pagination,
    loading,
    error,
  }),
);

export const getRttDetailsPageSel = createSelector(
  [getRttDetailsSel, getLoadingStateSel, getErrorSel],
  (rtt, loading, error) => ({
    rtt,
    loading,
    error,
  }),
);
