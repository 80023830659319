import { createStyles, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { ComposerProps, SendProps } from 'react-web-gifted-chat';

const useStyles = makeStyles(() =>
  createStyles({
    textInput: {
      marginLeft: 10,
      fontSize: 16,
      lineHeight: '16px',
      paddingTop: 3,
      paddingBottom: 3,
      outline: 'none',
      border: 'none',
      overflow: 'auto',
      boxShadow: 'none',
      resize: 'none',
      width: '100%',
    },
  }),
);

const CustomComposer = ({
  onTextChanged,
  placeholder,
  multiline = true,
  text,
  textInputStyle,
  textInputProps,
  onSend,
}: ComposerProps & SendProps) => {
  const classes = useStyles();

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (
      typeof onSend === 'function' &&
      e.keyCode === 13 &&
      e.shiftKey === false
    ) {
      const message = text?.trim();
      if (message) {
        onSend({ text: message }, true);
      }
      e.preventDefault();
    }
  };

  return (
    <TextField
      onChange={e => onTextChanged?.(e.target.value)}
      placeholder={placeholder}
      multiline={multiline}
      value={text}
      className={classes.textInput}
      style={textInputStyle}
      rowsMax={2}
      onKeyDown={onKeyDown}
      {...textInputProps}
    />
  );
};

export default CustomComposer;
