import {
  NOT_CONFIRMED_STATUSES,
  RTT_REGISTERED_STATUSES,
  DISTR_REGISTERED_STATUSES,
} from '@rtt-libs/constants';
import { ACTIVATION_REQUEST_STATUS, REGISTERED_STATUS } from '../environment';
import { StatusGroup } from '../types';

export const getRttStatusesFromGroup = (groupName: StatusGroup | undefined) => {
  switch (groupName) {
    case ACTIVATION_REQUEST_STATUS:
      return NOT_CONFIRMED_STATUSES;
    case REGISTERED_STATUS:
      return RTT_REGISTERED_STATUSES;
    default:
      return undefined;
  }
};

export const getDistributorsStatusesFromGroup = (
  groupName: StatusGroup | undefined,
) => {
  switch (groupName) {
    case ACTIVATION_REQUEST_STATUS:
      return NOT_CONFIRMED_STATUSES;
    case REGISTERED_STATUS:
      return DISTR_REGISTERED_STATUSES;
    default:
      return undefined;
  }
};
