import { createStyles, makeStyles, Theme } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import LinkItem from './LinkItem';
import NestedLinkList from './NestedLinkList';
import { TopNavigationLink } from './types';
import useActiveLocation from './useActiveLocation';

type MainLinkProps = TopNavigationLink;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandIcon: {
      paddingLeft: theme.spacing(1),
    },
  }),
);

const MainLink: React.FC<MainLinkProps> = ({
  link,
  nested,
  isExpanded: isExpandedProp,
  ...props
}) => {
  const isExpanded = useActiveLocation(link, {
    exact: false,
    isActive: isExpandedProp,
  });
  const classes = useStyles();

  return (
    <>
      <LinkItem link={link} {...props}>
        {nested && !isExpanded ? (
          <ExpandMore className={classes.expandIcon} />
        ) : null}
      </LinkItem>
      {nested && <NestedLinkList links={nested} expanded={isExpanded} />}
    </>
  );
};

export default MainLink;
