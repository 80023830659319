import { Pagination, RttDetails, RttStats } from '@rtt-libs/types';
import { Reducer } from 'redux';
import { Rtt } from '../../api/rtt';
import { Actions } from './actions';
import { mainReducerKey } from './const';
import * as TYPES from './types';

const initialState = {
  loading: false,
  [mainReducerKey]: [] as Rtt[],
  current: undefined as (RttDetails & RttStats) | undefined,
  currentNotices: null as string | null,
  pagination: {} as Pagination,
  error: undefined as string | undefined,
};

export type State = typeof initialState;

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_RTT_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case TYPES.GET_RTT_SUCCESS:
      return {
        ...state,
        loading: false,
        [mainReducerKey]: action.payload,
        pagination: action.meta.pagination,
      };
    case TYPES.GET_RTT_FAILURE:
    case TYPES.GET_RTT_DETAILS_FAILURE:
    case TYPES.CHANGE_RTT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        [mainReducerKey]: [],
      };
    case TYPES.GET_RTT_DETAILS_REQUEST:
      return {
        ...state,
        current: undefined,
        loading: true,
        error: undefined,
      };
    case TYPES.CHANGE_RTT_SUCCESS:
      return {
        ...state,
        current: action.payload,
      };
    case TYPES.GET_RTT_DETAILS_SUCCESS:
      return {
        ...state,
        current: action.payload,
        currentNotices: action.meta ? action.meta.notices : null,
        loading: false,
      };
    case TYPES.CHANGE_RTT_STATUS_SUCCESS:
      return {
        ...state,
        current: state.current
          ? {
              ...state.current,
              status: action.payload.status,
            }
          : state.current,
        currentNotices: action.payload.notices,
        loading: false,
        [mainReducerKey]: state[mainReducerKey].map(rtt => {
          if (rtt.id === action.meta.id) {
            return {
              ...rtt,
              status: action.payload.status,
            };
          }
          return rtt;
        }),
      };
    default:
      return state;
  }
};

export default reducer;
