import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import { ActionsMenu, LoadingButton } from '@rtt-libs/views';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Moderator } from '../../../api/moderators';
import { promiseListener } from '../../../setup';
import { types } from '../../duck';

type Props = {
  /** id of current entry */
  id: string | number | symbol;
  /** Value received from format fn in tableConfig  */
  value?: unknown;
  onEditClick(): void;
};

/**
 * Menu for Moderators with available status options & confirmation dialog
 */
const ModeratorActionsMenu: React.FC<Props> = ({ id, value, onEditClick }) => {
  const [t] = useTranslation();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errorMessage, setError] = useState<string | null>(null);
  const [loading, changeLoading] = useState(false);

  const handleOpen = () => setConfirmOpen(true);
  const handleClose = () => {
    setConfirmOpen(false);
    setError(null);
  };

  if (!(value instanceof Moderator)) {
    return null;
  }

  const changeStatusOption = {
    label: value.isActive
      ? t(`admin.moderators.change.deactivateLabel`)
      : t(`admin.moderators.change.activateLabel`),
    onClick: () => handleOpen(),
  };

  const optionsAssigned = [
    {
      label: t(`admin.moderators.change.editLabel`),
      onClick: onEditClick,
    },
  ];

  const options = optionsAssigned.concat(changeStatusOption);

  return (
    <>
      <ActionsMenu id={id} options={options} />

      <Dialog
        open={confirmOpen}
        onClose={handleClose}
        onExited={() => changeLoading(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {t('admin.moderators.change.confirmStatusTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {t(
              `admin.moderators.change.confirmStatusDescription${
                value.isActive ? 'Inactive' : 'Active'
              }`,
            )}
          </DialogContentText>
          <FormHelperText error>{errorMessage}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('controls.cancel')}</Button>
          <MakeAsyncFunction
            listener={promiseListener}
            start={types.CHANGE_STATUS_MODERATOR_REQUEST}
            resolve={types.CHANGE_STATUS_MODERATOR_SUCCESS}
            reject={types.CHANGE_STATUS_MODERATOR_FAILURE}
          >
            {onClick => (
              <LoadingButton
                onClick={() => {
                  changeLoading(true);
                  onClick({ id: value.id, status: !value.isActive })
                    .then(handleClose)
                    .catch(error => {
                      changeLoading(false);
                      setError(error);
                    });
                }}
                loading={loading}
                color="primary"
                autoFocus
              >
                {t('controls.agree')}
              </LoadingButton>
            )}
          </MakeAsyncFunction>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModeratorActionsMenu;
