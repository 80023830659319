import { Reducer } from 'redux';
import type * as NotificationTypes from '../types';
import type { Actions } from './actions';
import * as types from './types';

export const NOTIFICATIONS_INITIAL_STATE = {
  loading: false,
  data: {} as NotificationTypes.NotificationStats,
  error: null as string | null,
};

export type NotificationsSliceState = typeof NOTIFICATIONS_INITIAL_STATE;

const notificationReducer: Reducer<NotificationsSliceState, Actions> = (
  state = NOTIFICATIONS_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case types.NOTIFICATIONS_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.NOTIFICATIONS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.NOTIFICATIONS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default notificationReducer;
