import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FeedbackEmail from './FeedbackEmail';

const SettingsCollection = () => {
  const [t] = useTranslation();

  return (
    <Box clone p={2} mb={2}>
      <Paper>
        <Typography component="h2" variant="h6">
          {t('admin.settings.feedback.heading')}
        </Typography>

        <FeedbackEmail />
      </Paper>
    </Box>
  );
};

export default SettingsCollection;
