import {
  Box,
  BoxProps,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ProductMessagePayload } from '../../../types';

type ProductPreviewCardProps = {
  product: ProductMessagePayload;
  actions?: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContentRoot: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      flexGrow: 1,
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
  }),
);

const ProductPreviewCard = ({
  product,
  actions,
  ...props
}: ProductPreviewCardProps & BoxProps) => {
  const [t] = useTranslation();

  const classes = useStyles();

  const renderProductInfo = (
    <>
      <Box clone flexShrink={0} width="102px">
        <CardMedia image={product.img}>
          {!product.img && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <WallpaperIcon fontSize="large" />
            </Box>
          )}
        </CardMedia>
      </Box>

      <CardContent className={classes.cardContentRoot}>
        <Typography variant="caption">
          {t('product.sku')}: {product.sku}
        </Typography>

        <Typography variant="subtitle2">{product.title}</Typography>

        {product.price && (
          <Typography variant="caption">
            {product.price}
            {'\u00A0'}
            {t('common.mainCurrency')} /{' '}
            {t([
              `product.values.${product.saleMeasurement}`,
              'product.values.default',
            ])}
          </Typography>
        )}
      </CardContent>

      {actions && <CardActions>{actions}</CardActions>}
    </>
  );

  return (
    <Box clone display="flex" width="auto" {...props}>
      <Card>{renderProductInfo}</Card>
    </Box>
  );
};

export default ProductPreviewCard;
