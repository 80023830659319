import React from 'react';
import { Form } from 'react-final-form';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { promiseListener } from '../../../setup';
import { types } from '../../duck';
import CreateModeratorForm from './CreateModeratorForm';
import { CreateModeratorValues } from '../../../api/moderators';

type Props = {
  onClose(): void;
};

const CreateModeratorContainer: React.FC<Props> = ({ onClose }) => {
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.CREATE_MODERATOR_REQUEST}
      resolve={types.CREATE_MODERATOR_RESPONSE}
      reject={types.CREATE_MODERATOR_FAILURE}
    >
      {onSubmit => (
        <Form
          onSubmit={async (values: CreateModeratorValues) => {
            const errors = await onSubmit(values);
            if (!errors) {
              onClose();
            }
            return errors;
          }}
          component={props => (
            <CreateModeratorForm onClose={onClose} {...props} />
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default CreateModeratorContainer;
