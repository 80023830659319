import {
  Pagination,
  RttDetails,
  RttStats,
  Status,
  StatusValue,
  StatusWithNotice,
} from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import { Rtt, RttGetParams } from '../../api/rtt';
import * as TYPES from './types';

/*
  GET RTT
*/

export const getRttRequest = (payload?: Partial<RttGetParams>) => ({
  type: TYPES.GET_RTT_REQUEST,
  payload,
});

export const getRttSuccess = (
  payload: Rtt[],
  meta: { pagination: Pagination },
) => ({
  type: TYPES.GET_RTT_SUCCESS,
  payload,
  meta,
});

export const getRttFailure = (error: string) => ({
  type: TYPES.GET_RTT_FAILURE,
  payload: error,
});

/*
  CHANGE RTT STATUS
*/

export const changeRttStatusRequest = (payload: {
  id: number;
  status: StatusValue;
  notices?: string;
}) => ({
  type: TYPES.CHANGE_RTT_STATUS_REQUEST,
  payload,
});

export const changeRttStatusSuccess = (
  payload: StatusWithNotice,
  meta: { id: number; status: Status },
) => ({
  type: TYPES.CHANGE_RTT_STATUS_SUCCESS,
  payload,
  meta,
});

export const changeRttStatusFailure = (error: string) => ({
  type: TYPES.CHANGE_RTT_STATUS_FAILURE,
  payload: error,
});

/*
 * RTT DETAILS
 */
export const getRttDetailsRequest = (payload: number) => ({
  type: TYPES.GET_RTT_DETAILS_REQUEST,
  payload,
});

export const getRttDetailsSuccess = (
  payload: RttDetails & RttStats,
  meta?: { notices: string | null },
) => ({
  type: TYPES.GET_RTT_DETAILS_SUCCESS,
  payload,
  meta,
});

export const getRttDetailsFailure = (error: string) => ({
  type: TYPES.GET_RTT_DETAILS_FAILURE,
  payload: error,
});

/* RTT CHANGE  */

export const changeRttRequest = (
  payload: Omit<RttDetails, 'status' | 'phone'>,
) => ({
  type: TYPES.CHANGE_RTT_REQUEST,
  payload,
});

export const changeRttSuccess = (payload: RttDetails & RttStats) => ({
  type: TYPES.CHANGE_RTT_SUCCESS,
  payload,
});

export const changeRttFailure = (
  errors: Partial<Record<keyof RttDetails | typeof FORM_ERROR, string>>,
) => ({
  type: TYPES.CHANGE_RTT_FAILURE,
  payload: errors,
});

export type Actions = ReturnType<
  | typeof getRttRequest
  | typeof getRttSuccess
  | typeof getRttFailure
  | typeof changeRttStatusRequest
  | typeof changeRttStatusSuccess
  | typeof changeRttStatusFailure
  | typeof getRttDetailsRequest
  | typeof getRttDetailsSuccess
  | typeof getRttDetailsFailure
  | typeof changeRttRequest
  | typeof changeRttSuccess
  | typeof changeRttFailure
>;
