import React from 'react';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

const GoBackButton: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation();

  const [pathname] = location.pathname.split('/').filter(item => !!item);

  const goBack = () => {
    if (window.history.length > 2 && !pathname) {
      history.goBack();
      return;
    }
    history.push(`/${pathname}`);
  };

  return (
    <Button onClick={goBack} variant="text" startIcon={<ArrowBackIcon />}>
      {children || t('navigation.backToFullList')}
    </Button>
  );
};

export default GoBackButton;
