import type * as NotificationsTypes from '../types';
import * as types from './types';

export const notificationsGetRequest = () => ({
  type: types.NOTIFICATIONS_GET_REQUEST,
});

export const notificationsGetSuccess = (
  payload: NotificationsTypes.NotificationStats,
) => ({
  type: types.NOTIFICATIONS_GET_SUCCESS,
  payload,
});

export const notificationsGetFailure = (error: string) => ({
  type: types.NOTIFICATIONS_GET_FAILURE,
  payload: error,
});

export type Actions = ReturnType<
  | typeof notificationsGetRequest
  | typeof notificationsGetSuccess
  | typeof notificationsGetFailure
>;
