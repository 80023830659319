import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductPreviewCard from '../../components/Chats/customChatComponents/ProductPreviewCard';
import type { ProductMessagePayload } from '../../types';
import type { ProductInfo } from '../types';
import { mapProductToMessagePayload } from '../utils';
import SearchProduct from './SearchProduct';

type Props = {
  rttId: number;
  onClose(): void;
  onSubmit(product: ProductMessagePayload): void;
};

const ProductSelectModalContent: React.FC<Props> = ({
  rttId,
  onClose,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const [selectedProduct, selectProduct] = useState<ProductInfo | undefined>(
    undefined,
  );

  const handleSubmit = () => {
    if (selectedProduct) onSubmit(mapProductToMessagePayload(selectedProduct));
  };

  return (
    <>
      <DialogTitle>
        {t('chats.product.selectModal.title')}
        {selectedProduct && (
          <ProductPreviewCard
            product={mapProductToMessagePayload(selectedProduct)}
          />
        )}
      </DialogTitle>
      <DialogContent>
        <SearchProduct
          onSubmit={selectProduct}
          rttId={rttId}
          selectedId={selectedProduct?.id}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedProduct}
        >
          {t('controls.send')}
        </Button>
        <Button type="button" onClick={onClose}>
          {t('controls.cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

export default ProductSelectModalContent;
