import {
  Box,
  FormHelperText,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import { debounce, isNil, pickBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { searchProductsAsRttRequest } from '../duck/actions';
import { selectProductsForRtt } from '../duck/selectors';
import type { ProductInfo, ProductSearchParams } from '../types';
import TablePaginationWrapper from './TablePagination';

const PRODUCTS_COUNT = 10;

type Props = {
  rttId: number;
  onSubmit(product: ProductInfo): void;
  selectedId?: ProductInfo['id'];
};

const SearchProduct: React.FC<Props> = ({ rttId, onSubmit, selectedId }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [displayOptions, changeOptions] = useState<ProductSearchParams>({
    perPage: PRODUCTS_COUNT,
    isActive: true,
    isInvalid: false,
  });

  useEffect(() => {
    dispatch(searchProductsAsRttRequest(rttId, displayOptions));
  }, [dispatch, displayOptions, rttId]);

  const onChange = useCallback((params: Partial<ProductSearchParams>) => {
    changeOptions(prevParams =>
      pickBy({ ...prevParams, page: 1, ...params }, value => !isNil(value)),
    );
  }, []);

  const { data, pagination, loading, error } = useSelector(
    selectProductsForRtt,
  );

  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ search: e.target.value });
  };

  const localeLabels = {
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  return (
    <>
      <TextField
        name="search"
        label={t('distributor.rtt.search', 'Search')}
        onChange={onSearchChange}
        fullWidth
      />

      <Box minHeight={8}>{loading && <LinearProgress />}</Box>

      {!!error && <FormHelperText error>{error}</FormHelperText>}

      <List>
        {data.map(product => {
          const labelId = `checkbox-list-label-${product.sku}`;

          return (
            <ListItem
              key={product.id}
              role={undefined}
              dense
              button
              onClick={() => onSubmit(product)}
              disabled={product.id === selectedId}
            >
              <ListItemText
                id={labelId}
                primary={product.title}
                secondary={`${t('product.skuContracted')}: ${product.sku}`}
              />
            </ListItem>
          );
        })}
      </List>

      {!!data.length && (
        <TablePaginationWrapper
          count={pagination.total || 0}
          onChange={onChange}
          initialRowsPerPage={PRODUCTS_COUNT}
          paginationProps={{
            labelDisplayedRows: localeLabels.displayedRows,
            labelRowsPerPage: localeLabels.rowsPerPage,
          }}
        />
      )}
    </>
  );
};

export default SearchProduct;
