import { table } from '@rtt-libs/constants';
import { Pagination } from '@rtt-libs/types';
import { TableWithHistory } from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Moderator, SearchParams } from '../../api/moderators';
import FilterRow from './FilterRow';
import moderatorsTableConfig from './tableConfig';

type Props = {
  moderators: Moderator[];
  pagination: Pagination;
  loading: boolean;
  onStateChange: (state: SearchParams) => void;
};

/**
 * Wrapper component to pass moderators data into common table component
 * Also handles routing to Moderator detail page.
 */
const ModeratorsTable: React.FC<Props> = ({
  moderators,
  pagination,
  loading = false,
  onStateChange,
}) => {
  const [t] = useTranslation();

  const columnConfig = moderatorsTableConfig(t);

  const { perPage, currentPage } = pagination;

  const initialParams = useMemo(
    () => ({
      perPage: perPage || table.PER_PAGE,
      page: currentPage,
      isActive: '1' as const,
    }),
    [perPage, currentPage],
  );

  const localeTableLabels = {
    sort: t('admin.tables.common.sort', 'Sort'),
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  return (
    <TableWithHistory
      filterRow={({ onChange, displayOptions }) => (
        <FilterRow onChange={onChange} initialFilterValues={displayOptions} />
      )}
      loading={loading}
      entries={moderators}
      columnsConfig={columnConfig}
      perPageOptions={table.PAGINATION_OPTIONS}
      initial={initialParams}
      totalEntriesCount={pagination.total}
      localeLabels={localeTableLabels}
      onStateChange={onStateChange}
    />
  );
};

export default ModeratorsTable;
