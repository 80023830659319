import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import SelectOpponentModal from './SelectOpponentModal';

interface Props {
  selectField: React.ReactNode;
  wrapperForm: React.ElementType;
  actionControls: React.ElementType<{ onClose(): void }>;
}

const ResolveChatBySelect: React.FC<Props> = ({
  children,
  selectField,
  wrapperForm,
  actionControls,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button type="button" onClick={() => setOpen(true)}>
        {children}
      </Button>

      <SelectOpponentModal
        open={open}
        onClose={() => setOpen(false)}
        wrapperForm={wrapperForm}
        actionControls={actionControls}
      >
        {selectField}
      </SelectOpponentModal>
    </>
  );
};

export default ResolveChatBySelect;
