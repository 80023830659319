/* eslint-disable @typescript-eslint/camelcase */

import {
  extractThumbnail,
  formErrorTransform,
  mapPagination,
  TransformAPIError,
} from '@rtt-libs/api-services';
import {
  AttachmentDocumentType,
  CategoryItemId,
  DistributorDetails,
  DistributorStats,
  FetchedContact as Contact,
  FetchedNotices,
  FetchedPagination,
  OrderParam,
  Status,
  StatusValue,
  StatusWithNotice,
} from '@rtt-libs/types';
import { ENDPOINTS } from '../environment';
import { FilterStatus, StatusGroup } from '../types';
import { getDistributorsStatusesFromGroup } from '../utils/getStatusesFromGroup';
import api from './apiSetup';

type FetchedStats = {
  manager_qty: number;
  driver_qty: number;
  supervisor_qty: number;
  order_qty: number;
  return_qty: number;
  balance_qty: number;
};
export type FetchedDistributor = {
  id: number;
  created_at: string;
  name: string;
  address: string;
  status: Status;
  contact?: Contact;
  documents?: AttachmentDocumentType[];
  categories?: CategoryItemId[];
  notices?: StatusWithNotice;
  stats?: FetchedStats;
};

export type DistributorsResponseType = {
  data: FetchedDistributor[];
  meta: {
    pagination: FetchedPagination;
  };
};

export type Distributor = {
  id: number;
  createdAt: string;
  name: string;
  address: string;
  status: Status;
  contactName?: string;
  phone?: string;
};

export type DistributorsGetParams = {
  page: number;
  perPage: number | null;
  name: string;
  address: string;
  status: FilterStatus;
  orderBy: 'name' | 'address' | 'status';
  order: OrderParam;
  statusGroup: StatusGroup;
  include: string;
};

type StatsFilterDateAPI = {
  start_date?: string;
  end_date?: string;
};

export type StatsFilterDate = {
  startDate?: string;
  endDate?: string;
};

/**
 * Axios API handlers
 */

/**
 * Fetch paginated distributors list
 */
export const getDistributors = (params: Partial<DistributorsGetParams> = {}) =>
  api
    .get<DistributorsResponseType>(ENDPOINTS.distributorsList, {
      params: mapGetParams(params),
    })
    .then(({ data }) => ({
      data: bulkMapDistributor(data.data),
      meta: {
        pagination: mapPagination(data.meta.pagination),
      },
    }));

/**
 * Fetch full distributor info
 * @param id required distributors id
 */
export const getDistributorDetails = (id: number) =>
  api
    .get<FetchedDistributor>(`${ENDPOINTS.distributors}/${id}`, {
      params: {
        include: 'contact,documents,categories',
      },
    })
    .then(({ data }) => {
      return mapDistributorDetails(data);
    });

/**
 * Fetch administrator notices which were added when distributor was declined.
 * @param id required distributors id
 */
export const getDistributorNotices = (id: number) =>
  api
    .get<FetchedNotices>(ENDPOINTS.distributorAppNotices, {
      params: {
        distributor_id: id,
      },
    })
    .then(({ data }) => {
      return data.data ? data.data : [];
    });

/**
 * Change status of distributor, if declined - with notices.
 * @param data updated data with distributor id & status info
 */
export const changeDistributorStatus = ({
  id,
  status,
  notices,
}: {
  id: number;
  status: StatusValue;
  notices?: string;
}) =>
  api
    .post<StatusWithNotice>(ENDPOINTS.distributorAppChange, {
      distributor_id: id,
      status,
      notices,
    })
    .then(({ data }) => data);

/**
 * Update distributors info
 * Works only if distributor didn't accepted yet.
 */
export const changeDistributor = (
  id: number,
  payload: Omit<DistributorDetails, 'status' | 'phone'>,
  addedDocuments: File[],
  deletedDocuments: string[],
  categories?: CategoryItemId[],
) => {
  const requestData = combineEditDistributorFormData(
    payload,
    addedDocuments,
    deletedDocuments,
    categories,
  );
  return api
    .post<FetchedDistributor>(
      `${ENDPOINTS.distributors}/${id}/update?include=contact,documents,categories`,
      requestData,
    )
    .then(({ data }) => mapDistributorDetails(data))
    .catch(e => {
      throw formErrorTransform(e, transformDistributorErrors);
    });
};

export const editActiveDistributor = (
  id: number,
  payload: Omit<DistributorDetails, 'status' | 'phone'>,
) => {
  return api
    .post<FetchedDistributor>(
      `${ENDPOINTS.distributorActiveEdit(id)}`,
      combineEditDistributorActiveFormData(payload),
    )
    .then(({ data }) => mapDistributorDetails(data))
    .catch(e => {
      throw formErrorTransform(e, transformDistributorErrors);
    });
};

export const getDistributorStats = (id: number, params: StatsFilterDate) => {
  return api
    .get<FetchedStats>(`${ENDPOINTS.distributorStats(id)}`, {
      params: mapDateFilters(params),
    })
    .then(({ data }) => mapDistributorStats(data))
    .catch(e => {
      throw formErrorTransform(e, transformDistributorStatsErrors);
    });
};

/**
 * Mappers
 */

function mapDistributor({
  id,
  name,
  address,
  status,
  contact,
  created_at,
}: FetchedDistributor): Distributor {
  return {
    id,
    createdAt: created_at,
    name,
    status,
    address,
    contactName: contact && `${contact.first_name} ${contact.last_name}`,
    phone: contact && contact.phone,
  };
}

function mapDistributorDetails({
  id,
  name,
  address,
  contact,
  status,
  categories = [],
  documents = [],
  notices = {} as NonNullable<FetchedDistributor['notices']>,
  stats,
}: FetchedDistributor): DistributorDetails &
  DistributorStats & {
    notices?: StatusWithNotice['notices'];
  } {
  return {
    id,
    name,
    address,
    firstName: contact ? contact.first_name : '',
    lastName: contact ? contact.last_name : '',
    categories,
    email: contact ? contact.email : '',
    // eslint-disable-next-line no-shadow
    documents: documents.map(({ id, src, thumb_size, name, related }) => ({
      id,
      src,
      name,
      size: thumb_size,
      ...extractThumbnail(related),
    })),
    phone: contact ? contact.phone : '',
    status,
    notices: notices.notices,
    stats: mapDistributorStats(stats),
  };
}

function bulkMapDistributor(distrArray: FetchedDistributor[]): Distributor[] {
  return distrArray.map(mapDistributor);
}

function mapGetParams(params: Partial<DistributorsGetParams>) {
  const statusGroup = getDistributorsStatusesFromGroup(params.statusGroup);
  return {
    include: params.include ?? 'contact',
    page: params.page,
    per_page: params.perPage,
    name: params.name,
    address: params.address,
    status:
      !params.status && statusGroup ? statusGroup.join(',') : params.status,
    sort: params.orderBy,
    order: params.order,
  };
}

/**
 * Prepare changed distributor data for PUT to API
 */
function combineEditDistributorFormData(
  payload: Omit<DistributorDetails, 'status' | 'phone'>,
  addedDocuments: File[],
  deletedDocuments: string[],
  categories?: CategoryItemId[],
) {
  const data = new FormData();

  // Assign values to key acceptable on API.
  const mappedPayload = {
    name: payload.name,
    address: payload.address,
    email: payload.email,
    'categories[]': categories,
    'documents_added[]': addedDocuments,
    'documents_deleted[]': deletedDocuments,
    first_name: payload.firstName,
    last_name: payload.lastName,
  };

  // Add assign key-value pairs to FormData instance.
  // if value is Array - appends to a specified key in loop
  Object.entries(mappedPayload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      (value as (string | number | File)[]).forEach(valueItem => {
        data.append(key, valueItem as string | Blob);
      });
    } else if (value !== undefined) {
      data.append(key, value);
    }
  });

  return data;
}

function combineEditDistributorActiveFormData(
  payload: Omit<DistributorDetails, 'status' | 'phone'>,
) {
  const data = new FormData();

  // Assign values to key acceptable on API.
  const mappedPayload = {
    name: payload.name,
    address: payload.address,
    email: payload.email,
    first_name: payload.firstName,
    last_name: payload.lastName,
  };

  // Add assign key-value pairs to FormData instance.
  // if value is Array - appends to a specified key in loop
  Object.entries(mappedPayload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      (value as (string | number | File)[]).forEach(valueItem => {
        data.append(key, valueItem as string | Blob);
      });
    } else if (value !== undefined) {
      data.append(key, value);
    }
  });

  return data;
}

const transformDistributorErrors: TransformAPIError<
  Record<
    keyof FetchedDistributor | 'phone' | 'email' | 'first_name' | 'last_name',
    string | string[]
  >,
  Record<keyof DistributorDetails, string>
> = function transformDistributorErrors(e) {
  return {
    id: e.id?.toString(),
    address: e.address?.toString(),
    categories: e.categories?.toString(),
    documents: e.documents?.toString(),
    name: e.name?.toString(),
    status: e.status?.toString(),
    phone: e.phone?.toString(),
    email: e.email?.toString(),
    firstName: e.first_name?.toString(),
    lastName: e.last_name?.toString(),
  };
};

const mapDistributorStats = (
  stats: FetchedDistributor['stats'],
): DistributorStats['stats'] => ({
  balanceQty: stats?.balance_qty ?? 0,
  managerQty: stats?.manager_qty ?? 0,
  orderQty: stats?.order_qty ?? 0,
  returnQty: stats?.return_qty ?? 0,
});

const mapDateFilters = (dates: StatsFilterDate): StatsFilterDateAPI => ({
  start_date: dates.startDate,
  end_date: dates.endDate,
});

const transformDistributorStatsErrors: TransformAPIError<
  Record<keyof StatsFilterDateAPI, string | string[]>,
  Record<keyof StatsFilterDate, string>
> = function transformDistributorStatsErrors(e) {
  return {
    startDate: e.start_date?.toString(),
    endDate: e.end_date?.toString(),
  };
};
