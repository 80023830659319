import {
  CategoryItem,
  CategoryItemId,
  CategoriesItemDictionary,
} from '@rtt-libs/types';
import { omit } from 'lodash/fp';
import { Reducer } from 'redux';
import { Actions } from './actions';
import { mainReducerKey } from './const';
import * as TYPES from './types';

const initialState = {
  loading: false,
  [mainReducerKey]: {} as Record<CategoryItemId, CategoryItem>,
  error: null as string | null,
  /* keys is child category id, values - its parent */
  parentCategories: {} as Record<CategoryItemId, CategoryItemId>,
  allCategories: {} as CategoriesItemDictionary,
};

const parentCategoriesReducer = (
  acc: Record<CategoryItemId, CategoryItemId>,
  { id: parentId = 'root', children: childrenIds }: CategoryItem,
): Record<CategoryItemId, CategoryItemId> => {
  let parentCategoriesSlice: Record<CategoryItemId, CategoryItemId> = {};

  childrenIds.forEach(id => {
    parentCategoriesSlice = {
      ...parentCategoriesSlice,
      [id]: parentId,
    };
  });
  return { ...acc, ...parentCategoriesSlice };
};

const omitChildFromParent = (
  state: typeof initialState,
  id: CategoryItemId,
) => {
  return {
    [state.parentCategories[id]]: {
      ...state[mainReducerKey][state.parentCategories[id]],
      children: state[mainReducerKey][
        state.parentCategories[id]
      ].children.filter(childId => childId !== id),
    },
  };
};

export type State = typeof initialState;

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CATEGORIES_REQUEST:
    case TYPES.CATEGORIES_ADD_REQUEST:
    case TYPES.CATEGORIES_EDIT_REQUEST:
    case TYPES.CATEGORIES_MOVE_REQUEST:
    case TYPES.CATEGORIES_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategories: action.payload,
      };
    case TYPES.CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        [mainReducerKey]: action.payload,
        parentCategories: Object.values(action.payload).reduce(
          parentCategoriesReducer,
          {},
        ),
      };
    case TYPES.CATEGORIES_ADD_SUCCESS: {
      const { parentId = 'root' } = action.meta;

      return {
        ...state,
        loading: false,
        [mainReducerKey]: {
          ...state[mainReducerKey],
          [action.payload.id]: action.payload,
          [parentId]: {
            ...state[mainReducerKey][parentId],
            children: [
              ...state[mainReducerKey][parentId].children,
              action.payload.id,
            ],
          },
        },
        parentCategories: {
          ...state.parentCategories,
          [action.payload.id]: parentId,
        },
      };
    }
    case TYPES.CATEGORIES_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        [mainReducerKey]: {
          ...state[mainReducerKey],
          [action.payload.id]: {
            ...state[mainReducerKey][action.payload.id],
            ...action.payload,
          },
        },
      };
    case TYPES.CATEGORIES_MOVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.CATEGORIES_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        [mainReducerKey]: {
          ...omit(action.payload.id, state[mainReducerKey]),
          ...omitChildFromParent(state, action.payload.id),
        },
      };
    case TYPES.CATEGORIES_MOVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TYPES.CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
