import { StatusValue } from '@rtt-libs/types';
import { FilterSection, FilterOption } from '@rtt-libs/views';
import { TFunction } from 'i18next';

/**
 * Configure status labels for filtering distributor data.
 * @param t i18next translation function
 */
const statusFilterConfig = (
  t: TFunction,
  statuses: StatusValue[],
): FilterSection<StatusValue | ''>[] => {
  const defaultStatusOption: FilterOption<''> = {
    value: '',
    label: t('status.all'),
  };
  const availableStatusesOptions: FilterOption<StatusValue>[] = [
    { value: 'new', label: t('status.new') },
    { value: 'declined', label: t('status.declined') },
    { value: 'updated', label: t('status.updated') },
    { value: 'active', label: t('status.active') },
    { value: 'deactivated', label: t('status.deactivated') },
    { value: 'disabled', label: t('status.disabled') },
    { value: 'deletion', label: t('status.deletion') },
  ];

  const displayedStatuses = availableStatusesOptions.filter(option =>
    statuses.includes(option.value),
  );

  return [
    {
      name: 'status',
      title: t('status.filterTitle'),
      options: [defaultStatusOption, ...displayedStatuses],
    },
  ];
};

export default statusFilterConfig;
