import {
  Button,
  ButtonProps,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(
  createStyles({
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

type Props = ButtonProps & { loading?: boolean };

const LoadingButton: React.FC<Props> = ({ loading, children, ...props }) => {
  const classes = useStyles();
  return (
    <Button disabled={loading} {...props}>
      {loading && <CircularProgress size={24} className={classes.progress} />}
      {children}
    </Button>
  );
};

export default LoadingButton;
