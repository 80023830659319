import { Pagination } from '@rtt-libs/types';
import { keyBy } from 'lodash/fp';
import { Reducer } from 'redux';
import { Moderator } from '../../api/moderators';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: {
    moderators: false,
  },
  collection: {} as Record<Moderator['id'], Moderator>,
  error: null as string | null,
  listIds: [] as Moderator['id'][],
  pagination: {} as Pagination,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.GET_MODERATORS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, moderators: false },
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        listIds: action.payload.map(moderator => moderator.id),
        pagination: action.meta.pagination,
      };
    case types.GET_MODERATORS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, moderators: true },
        error: null,
      };
    case types.GET_MODERATORS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, moderators: false },
        error: action.payload,
      };
    case types.CREATE_MODERATOR_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
        listIds: [action.payload.id, ...state.listIds],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
      };
    case types.EDIT_MODERATOR_SUCCESS:
    case types.CHANGE_STATUS_MODERATOR_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
