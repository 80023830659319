import { Button, ButtonGroup } from '@material-ui/core';
import { setUserLocale } from '@rtt-libs/auth';
import { SUPPORTED_LOCALES } from '@rtt-libs/constants';
import { LocaleValue } from '@rtt-libs/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setAcceptLanguageHeader } from '../api/apiSetup';
import useSnackbarError from './useSnackbarError';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const showError = useSnackbarError();

  const [loading, setLoading] = useState(false);

  const changeLanguage = async (lng: LocaleValue) => {
    setLoading(true);

    try {
      await setUserLocale(lng);

      setAcceptLanguageHeader(lng);

      i18n.changeLanguage(lng, () => {
        document.documentElement.lang = lng;
      });
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  return (
    <ButtonGroup
      size="small"
      aria-label="lang switcher"
      color="primary"
      fullWidth
    >
      {(SUPPORTED_LOCALES as LocaleValue[]).map(lang => (
        <Button
          key={lang}
          aria-disabled={i18n.language === lang}
          disabled={loading}
          type="button"
          onClick={
            i18n.language === lang ? undefined : () => changeLanguage(lang)
          }
          variant={i18n.language === lang ? 'contained' : 'outlined'}
        >
          {t(`language.${lang}`)}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default LanguageSwitcher;
