import {
  CategoryItem,
  CategoryItemId,
  CategoriesItemDictionary,
} from '@rtt-libs/types';
import { SubmissionErrors } from 'final-form';
import {
  AddCategoryFormValues,
  EditCategoryFormValues,
  MoveCategoryItem,
} from '../types';
import * as TYPES from './types';

export const categoriesRequest = () => ({
  type: TYPES.CATEGORIES_REQUEST,
});

export const categoriesSuccess = (
  payload: Record<CategoryItemId, CategoryItem>,
) => ({
  type: TYPES.CATEGORIES_SUCCESS,
  payload,
});

export const categoriesFailure = () => ({
  type: TYPES.CATEGORIES_FAILURE,
});

export const categoriesAddRequest = (payload: AddCategoryFormValues) => ({
  type: TYPES.CATEGORIES_ADD_REQUEST,
  payload,
});

export const categoriesAddSuccess = (
  payload: CategoryItem,
  meta: { parentId?: CategoryItemId },
) => ({
  type: TYPES.CATEGORIES_ADD_SUCCESS,
  payload,
  meta,
});

export const categoriesAddResponse = (payload?: SubmissionErrors) => ({
  type: TYPES.CATEGORIES_ADD_RESPONSE,
  payload,
});

export const categoriesAddFailure = (error: string) => ({
  type: TYPES.CATEGORIES_ADD_FAILURE,
  payload: error,
});

export const categoriesEditRequest = (payload: EditCategoryFormValues) => ({
  type: TYPES.CATEGORIES_EDIT_REQUEST,
  payload,
});

export const categoriesEditSuccess = (payload: CategoryItem) => ({
  type: TYPES.CATEGORIES_EDIT_SUCCESS,
  payload,
});

export const categoriesEditResponse = (payload?: SubmissionErrors) => ({
  type: TYPES.CATEGORIES_EDIT_RESPONSE,
  payload,
});

export const categoriesEditFailure = (error: string) => ({
  type: TYPES.CATEGORIES_EDIT_FAILURE,
  payload: error,
});

export const categoriesMoveRequest = (
  payload: MoveCategoryItem,
  meta: Record<CategoryItemId, CategoryItem>,
) => ({
  type: TYPES.CATEGORIES_MOVE_REQUEST,
  payload,
  meta,
});

export const categoriesMoveSuccess = (payload: MoveCategoryItem) => ({
  type: TYPES.CATEGORIES_MOVE_SUCCESS,
  payload,
});

export const categoriesMoveFailure = (error: string) => ({
  type: TYPES.CATEGORIES_MOVE_FAILURE,
  payload: error,
});

export const categoriesDeleteRequest = (payload: CategoryItemId) => ({
  type: TYPES.CATEGORIES_DELETE_REQUEST,
  payload,
});

export const categoriesDeleteResponse = (payload?: SubmissionErrors) => ({
  type: TYPES.CATEGORIES_DELETE_RESPONSE,
  payload,
});

export const categoriesDeleteSuccess = (payload: { id: CategoryItemId }) => ({
  type: TYPES.CATEGORIES_DELETE_SUCCESS,
  payload,
});

export const categoriesDeleteFailure = (error: string) => ({
  type: TYPES.CATEGORIES_DELETE_FAILURE,
  payload: error,
});

export const getAllCategoriesRequest = () => ({
  type: TYPES.ALL_CATEGORIES_REQUEST,
});

export const getAllCategoriesSuccess = (payload: CategoriesItemDictionary) => ({
  type: TYPES.ALL_CATEGORIES_SUCCESS,
  payload,
});

export const getAllCategoriesFailure = (error: string) => ({
  type: TYPES.ALL_CATEGORIES_FAILURE,
  payload: error,
});

export type Actions =
  | ReturnType<typeof categoriesRequest>
  | ReturnType<typeof categoriesSuccess>
  | ReturnType<typeof categoriesFailure>
  | ReturnType<typeof categoriesAddRequest>
  | ReturnType<typeof categoriesAddSuccess>
  | ReturnType<typeof categoriesAddResponse>
  | ReturnType<typeof categoriesAddFailure>
  | ReturnType<typeof categoriesEditRequest>
  | ReturnType<typeof categoriesEditSuccess>
  | ReturnType<typeof categoriesEditResponse>
  | ReturnType<typeof categoriesEditFailure>
  | ReturnType<typeof categoriesMoveRequest>
  | ReturnType<typeof categoriesMoveSuccess>
  | ReturnType<typeof categoriesMoveFailure>
  | ReturnType<typeof categoriesDeleteRequest>
  | ReturnType<typeof categoriesDeleteResponse>
  | ReturnType<typeof categoriesDeleteSuccess>
  | ReturnType<typeof categoriesDeleteFailure>
  | ReturnType<typeof getAllCategoriesRequest>
  | ReturnType<typeof getAllCategoriesSuccess>
  | ReturnType<typeof getAllCategoriesFailure>;
