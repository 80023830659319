import {
  FormControlLabel,
  Switch,
  FormControlLabelProps,
} from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNotificationEnabled, setNotificationPlayState } from '../utils';

const ChatNotificationSwitch = (
  props: Omit<FormControlLabelProps, 'control' | 'onChange' | 'label'>,
) => {
  const [t] = useTranslation();

  const handleChange = (_: React.ChangeEvent<{}>, checked: boolean) => {
    setNotificationPlayState(checked);
  };

  return (
    <FormControlLabel
      control={<Switch defaultChecked={isNotificationEnabled()} />}
      label={<NotificationsActiveIcon color="action" fontSize="small" />}
      title={t('common.notificationsSwitch')}
      labelPlacement="start"
      onChange={handleChange}
      {...props}
    />
  );
};

export default ChatNotificationSwitch;
