import { ColumnConfig, Status } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import { Moderator } from '../../api/moderators';
import { ModeratorContacts, ModeratorName, ModeratorPassword } from './Cells';
import { EditModeratorActions } from './EditModerator';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const moderatorsTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'lastName',
    label: t('admin.tables.moderators.name', 'Name'),
    options: {
      format: (_: unknown, entry: Moderator) =>
        `${entry.lastName} ${entry.firstName}`,
      component: ModeratorName,
    },
  },
  {
    id: 'contacts',
    label: t('admin.tables.moderators.contacts', 'Contacts'),
    options: {
      format: (_: unknown, entry: Moderator) => entry,
      sort: false,
      component: ModeratorContacts,
    },
  },
  {
    id: 'login',
    label: t('admin.tables.moderators.login', 'login'),
  },
  {
    id: 'password',
    label: t('admin.tables.moderators.password', 'password'),
    options: {
      sort: false,
      component: ModeratorPassword,
    },
  },
  {
    id: 'isActive',
    label: t('admin.tables.moderators.isActive.label', 'isActive'),
    options: {
      align: 'center',
      format: value => {
        const status = value ? 'active' : 'deactivated';
        return { value: status, title: t(`status.${status}`) };
      },
      component: Status,
    },
  },
  {
    id: 'actions',
    label: '',
    options: {
      align: 'right',
      format: (_: unknown, entry: Moderator) => entry,
      component: EditModeratorActions,
      sort: false,
    },
  },
];

export default moderatorsTableConfig;
