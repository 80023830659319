import React from 'react';
import type { BubbleProps } from 'react-web-gifted-chat';
import type { ChatMessageWithPostData } from '../../../types';
import ProductMessageView from './ProductMessageView';

const CustomMessageView = ({
  currentMessage,
}: BubbleProps<ChatMessageWithPostData>) => {
  if (!currentMessage?.product) return null;

  return <ProductMessageView product={currentMessage.product} />;
};

export default CustomMessageView;
