import {
  DEFAULT_LNG,
  I18N_LANG_KEY,
  SUPPORTED_LOCALES,
} from '@rtt-libs/constants';
import type { LocaleValue } from '@rtt-libs/types';
import axios from 'axios';

export const getAcceptLanguageHeader = (language?: LocaleValue) => {
  const defaultHeaderValue = `${DEFAULT_LNG};q=0.8, en;q=0.5, *;q=0.3`;
  const currentLocale = localStorage.getItem(I18N_LANG_KEY) || '';

  if (language) {
    return `${language}, ${defaultHeaderValue}`;
  }

  if (SUPPORTED_LOCALES.includes(currentLocale)) {
    return `${currentLocale}, ${defaultHeaderValue}`;
  }

  return defaultHeaderValue;
};

export const setupApi = (token?: string, baseURL?: string) =>
  axios.create({
    baseURL,
    headers: {
      'Accept-language': getAcceptLanguageHeader(),
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  });
