import { AttachmentDocumentType } from '@rtt-libs/types';
import { call, put } from 'redux-saga/effects';
import { uploadImageAPI } from '../../../api';
import {
  uploadImageFailure,
  uploadImageRequest,
  uploadImageSuccess,
} from '../../actions';

export function* uploadImageWorker({
  payload,
}: ReturnType<typeof uploadImageRequest>) {
  try {
    const imageData: AttachmentDocumentType = yield call(
      uploadImageAPI,
      payload,
    );

    yield put(uploadImageSuccess(imageData));
  } catch (e) {
    yield put(uploadImageFailure(e.image || e.message));
  }
}
