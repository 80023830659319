export const GET_DISTRIBUTOR_REQUEST = `[admin]distr/GET_DISTRIBUTOR_REQUEST` as const;
export const GET_DISTRIBUTOR_SUCCESS = `[admin]distr/GET_DISTRIBUTOR_SUCCESS` as const;
export const GET_DISTRIBUTOR_FAILURE = `[admin]distr/GET_DISTRIBUTOR_FAILURE` as const;

export const GET_DISTRIBUTOR_DETAILS_REQUEST = `[admin]distr/GET_DISTRIBUTOR_DETAILS_REQUEST` as const;
export const GET_DISTRIBUTOR_DETAILS_SUCCESS = `[admin]distr/GET_DISTRIBUTOR_DETAILS_SUCCESS` as const;
export const GET_DISTRIBUTOR_DETAILS_FAILURE = `[admin]distr/GET_DISTRIBUTOR_DETAILS_FAILURE` as const;

export const CHANGE_DISTRIBUTOR_STATUS_REQUEST = `[admin]distr/CHANGE_DISTRIBUTOR_STATUS_REQUEST` as const;
export const CHANGE_DISTRIBUTOR_STATUS_SUCCESS = `[admin]distr/CHANGE_DISTRIBUTOR_STATUS_SUCCESS` as const;
export const CHANGE_DISTRIBUTOR_STATUS_FAILURE = `[admin]distr/CHANGE_DISTRIBUTOR_STATUS_FAILURE` as const;

export const CHANGE_DISTRIBUTOR_REQUEST = `[admin]distr/CHANGE_DISTRIBUTOR_REQUEST` as const;
export const CHANGE_DISTRIBUTOR_SUCCESS = `[admin]distr/CHANGE_DISTRIBUTOR_SUCCESS` as const;
export const CHANGE_DISTRIBUTOR_FAILURE = `[admin]distr/CHANGE_DISTRIBUTOR_FAILURE` as const;

export const DISTRIBUTORS_GET_STATS_REQUEST = `[admin]distr/DISTRIBUTORS_GET_STATS_REQUEST` as const;
export const DISTRIBUTORS_GET_STATS_SUCCESS = `[admin]distr/DISTRIBUTORS_GET_STATS_SUCCESS` as const;
export const DISTRIBUTORS_GET_STATS_FAILURE = `[admin]distr/DISTRIBUTORS_GET_STATS_FAILURE` as const;

export const DISTRIBUTORS_GET_OPTIONS_REQUEST = `[admin]distr/DISTRIBUTORS_GET_OPTIONS_REQUEST` as const;
export const DISTRIBUTORS_GET_OPTIONS_SUCCESS = `[admin]distr/DISTRIBUTORS_GET_OPTIONS_SUCCESS` as const;
export const DISTRIBUTORS_GET_OPTIONS_FAILURE = `[admin]distr/DISTRIBUTORS_GET_OPTIONS_FAILURE` as const;
