import { FORM_ERROR, SubmissionErrors } from 'final-form';
import noop from 'lodash/noop';

type Error = {
  message: string;
  response?: {
    data: {
      message: string;
      errors?: {
        [field: string]: string[];
      };
    };
  };
};

type Callback = (message: string | undefined) => void;
type Transform = (
  errors?: Record<string, string[] | string>,
) => Record<string, string>;

const formErrorTransform = (
  e: Error,
  callback: Callback = noop,
  transform?: Transform,
): SubmissionErrors => {
  let errors: Record<string, string[] | string> = {};
  if (e.response) {
    callback(e.response.data.message);
    errors = {
      ...e.response.data.errors,
      [FORM_ERROR]: e.response.data.message,
    };

    if (transform) {
      errors = {
        ...transform(errors),
      };
    }
  } else {
    callback(e.message);
    errors = {
      [FORM_ERROR]: e.message,
    };
  }

  return errors;
};

export default formErrorTransform;
