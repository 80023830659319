import { Dialog, IconButton } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import React, { useCallback, useState } from 'react';
import type { ActionsProps } from 'react-web-gifted-chat';
import { ChatMessageWithPostData, MessageType } from '../../../types';
import ImageUploadModalContent from './ImageUploadModalContent';
import ProductMessageModalContent from './ProductMessageModalContent';

interface InputActionsProps extends ActionsProps {
  disabled: boolean;
  rttId?: number;
  showAddProduct?: boolean;
}

const InputActions = ({
  onSend,
  disabled,
  rttId,
  showAddProduct,
}: InputActionsProps) => {
  const [modalId, setModalId] = useState<
    Exclude<MessageType, 'text'> | undefined
  >();

  const handleClose = useCallback(() => setModalId(undefined), [setModalId]);

  return (
    <>
      <IconButton
        disabled={disabled}
        aria-label="add image"
        onClick={() => setModalId('image')}
      >
        <AddPhotoAlternateIcon />
      </IconButton>
      {showAddProduct && (
        <IconButton
          disabled={disabled}
          aria-label="add product"
          onClick={() => setModalId('product')}
        >
          <AddShoppingCartIcon />
        </IconButton>
      )}

      <InputActionModal
        onSend={onSend}
        modalId={modalId}
        onClose={handleClose}
        rttId={rttId}
      />
    </>
  );
};

export default InputActions;

type InputActionModalProps = {
  onSend?(messages: Partial<ChatMessageWithPostData>[]): void;
  onClose(): void;
  modalId?: 'image' | 'product';
  rttId?: number;
};

const InputActionModal = ({
  onSend,
  onClose,
  modalId,
  rttId,
}: InputActionModalProps) => {
  return (
    <Dialog open={!!modalId} onClose={onClose} maxWidth="xs" fullWidth>
      {modalId === 'image' && (
        <ImageUploadModalContent onClose={onClose} onSend={onSend} />
      )}

      {modalId === 'product' && rttId !== undefined && (
        <ProductMessageModalContent
          rttId={rttId}
          onClose={onClose}
          onSend={onSend}
        />
      )}
    </Dialog>
  );
};
