import { AttachmentDocumentType } from '@rtt-libs/types';

export default function extractThumbnail(related: {
  data: AttachmentDocumentType[];
}) {
  const thumbnails = related.data.filter(({ is_thumbnail }) => is_thumbnail);

  if (thumbnails.length > 0) {
    return { thumbSrc: thumbnails[0].src, thumbSize: thumbnails[0].thumb_size };
  }
  return {};
}
