import { call, put, takeLatest } from 'redux-saga/effects';
import { getFeedbackEmailApi, setFeedbackEmailApi } from '../../api/settings';
import * as actions from './actions';
import * as TYPES from './types';

/**
 * Get feedback email used on landing page on feedback form
 */
function* feedbackEmailGetWorker() {
  try {
    const email: string = yield call(getFeedbackEmailApi);

    yield put(actions.getFeedbackEmailSuccess(email));
  } catch (e) {
    yield put(actions.getFeedbackEmailFailure(e.message));
  }
}

/**
 * Set email for feedback on landing page
 */
function* feedbackEmailSetWorker({
  payload,
}: ReturnType<typeof actions.setFeedbackEmailRequest>) {
  try {
    const email: string = yield call(setFeedbackEmailApi, payload);

    yield put(actions.setFeedbackEmailSuccess(email));
  } catch (e) {
    yield put(actions.setFeedbackEmailFailure(e.message));
  }
}

/**
 * Keeps all sub-sagas inside watcher saga.
 * Specify any takeLatest, takeEvery... right here.
 */
export default function* watcher() {
  yield takeLatest(TYPES.SETTINGS_EMAIL_GET_REQUEST, feedbackEmailGetWorker);
  yield takeLatest(TYPES.SETTINGS_EMAIL_SET_REQUEST, feedbackEmailSetWorker);
}
