import React from 'react';
import { useDispatch } from 'react-redux';
import { ActionsMenuComponent } from '../../common';
import { changeRttStatusRequest } from '../duck/actions';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

/**
 * Menu for RTT with available status options & confirmation dialog
 */
const RttActionsMenu = (props: Props) => {
  const dispatch = useDispatch();

  const onStatusChange = (
    values: Parameters<typeof changeRttStatusRequest>[0],
  ) => {
    dispatch(changeRttStatusRequest(values));
  };
  return <ActionsMenuComponent {...props} onStatusChange={onStatusChange} />;
};

export default RttActionsMenu;
