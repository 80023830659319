import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose(): void;
  wrapperForm: React.ElementType;
  actionControls: React.ElementType<{ onClose(): void }>;
}

const SelectOpponentModal: React.FC<Props> = ({
  open,
  onClose,
  wrapperForm: Wrapper,
  actionControls: Actions,
  children,
}) => {
  const [t] = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <Wrapper>
        <DialogTitle>{t('chats.resolveChat.modalTitle')}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Actions onClose={onClose} />
        </DialogActions>
      </Wrapper>
    </Dialog>
  );
};

export default SelectOpponentModal;
