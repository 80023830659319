import Echo from 'laravel-echo';
import io from 'socket.io-client';
import { setApiPostHeader } from '../../../api/apiSetup';
import { SOCKET_API_URL, SOCKET_PATH } from '../../../environment';

export const createEcho = (token: string) =>
  new Echo({
    broadcaster: 'socket.io',
    client: io,
    host: SOCKET_API_URL,
    path: SOCKET_PATH,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

export const initializeEchoSocket = (echo: Echo) => {
  const socket = echo.connector.socket as SocketIOClient.Socket;

  getSocketId(echo).then((socketId: string) => {
    socket.io.opts.transportOptions = {
      polling: {
        extraHeaders: {
          'X-Socket-Id': socketId,
        },
      },
    };

    setApiPostHeader('X-Socket-Id', socketId);
  });

  return socket;
};

async function getSocketId(echo: Echo) {
  return new Promise<string>(resolve =>
    echo.connector.socket.on('connect', () => resolve(echo.socketId())),
  );
}

export const connectToChannel = (echo: Echo, channelId: string) => {
  echo.private(`channel.${channelId}`);
};

export const connectToSystem = (echo: Echo, userId: number) => {
  echo.private(`system.${userId}`);
};

export const disconnectFromChannel = (echo: Echo, channelId: string) => {
  echo.leave(`channel.${channelId}`);
};

export const disconnectFromSystem = (echo: Echo, userId: number) => {
  echo.leave(`system.${userId}`);
};
