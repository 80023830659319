import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as TYPES from '../../types';
import {
  assignSupportChannelWorker,
  closeSupportChannelWorker,
  getChatListWorker,
  loadMoreChatListWorker,
  makeChannelReadWorker,
  resolveChatWorker,
} from './chats';
import { uploadImageWorker } from './images';
import {
  editMessageWorker,
  getMessageListWorker,
  loadMoreMessageListWorker,
  sendMessageWorker,
} from './messages';

export default function* restListener() {
  yield all([
    takeLatest(TYPES.CHATS_CHANNELS_GET_LIST_REQUEST, getChatListWorker),
    takeLatest(
      TYPES.CHATS_CHANNELS_GET_MORE_LIST_REQUEST,
      loadMoreChatListWorker,
    ),
    takeLatest(TYPES.CHATS_CHANNELS_RESOLVE_REQUEST, resolveChatWorker),
    takeLatest(TYPES.CHATS_CHANNELS_MAKE_READ_REQUEST, makeChannelReadWorker),
    takeLatest(TYPES.CHATS_SUPPORT_ASSIGN_REQUEST, assignSupportChannelWorker),
    takeLatest(TYPES.CHATS_SUPPORT_CLOSE_REQUEST, closeSupportChannelWorker),
    takeLatest(TYPES.CHATS_MESSAGES_GET_LIST_REQUEST, getMessageListWorker),
    takeLatest(
      TYPES.CHATS_MESSAGES_GET_MORE_LIST_REQUEST,
      loadMoreMessageListWorker,
    ),
    takeEvery(TYPES.CHATS_MESSAGES_SEND_REQUEST, sendMessageWorker),
    takeEvery(TYPES.CHATS_MESSAGES_EDIT_REQUEST, editMessageWorker),
    takeLatest(TYPES.CHATS_IMAGES_UPLOAD_REQUEST, uploadImageWorker),
  ]);
}
