import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import Header from './Header';
import Logo from './Logo';
import Main from './Main';
import SideDrawer from './SideDrawer';

export type Props = {
  /** set the header title content */
  header: React.ReactNode;
  /** navigation component */
  navigation: React.ReactElement;
  /** manually set Header, Drawer & Main components into children to modify as you wish */
  custom?: boolean;
  /** do not show navigation panel */
  hideNav?: boolean;
  logo?: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        background: theme.palette.background.default,
        marginTop: 0,
        marginBottom: 0,
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
      html: {
        height: '100%',
      },
    },
    root: {
      display: 'flex',
      minHeight: '100%',
    },
  }),
);

/**
 * Layout component with fixed Header & aside Drawer,
 * Could be manually filled with any component with `custom` prop.
 */
const Layout: React.FC<Props> = ({
  children,
  custom,
  header,
  navigation,
  hideNav,
  logo,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  if (custom) {
    return <div className={classes.root}>{children}</div>;
  }

  return (
    <div className={classes.root}>
      <Header open={!hideNav && open}>{header}</Header>
      {!hideNav && (
        <SideDrawer
          handleDrawerToggle={handleDrawerToggle}
          open={open}
          renderLogo={logo || <Logo />}
        >
          {navigation}
        </SideDrawer>
      )}
      <Main>{children}</Main>
    </div>
  );
};

export default Layout;
