import type { UserRole } from '@rtt-libs/types';
import type { ChannelType, OpponentType } from '../types';

export const deriveOpponentDomain = (
  chatType: ChannelType,
  userRole: UserRole,
): OpponentType => {
  // TODO: add RBAC resolution
  if (chatType === 'chat') {
    switch (true) {
      case userRole.includes('distributor'):
        return 'rtt';
      case userRole.includes('rtt'):
        return 'distributor';
      default:
        throw new Error('Unexpected user chat behavior');
    }
  }
  if (chatType === 'support') {
    switch (true) {
      case ['distributor_owner', 'distributor_admin'].includes(userRole):
        return 'admin';
      case ['admin', 'moderator'].includes(userRole):
        return 'distributor';
      default:
        throw new Error('Unexpected user support behavior');
    }
  }
  throw new Error('Unexpected userRole & chat combination');
};
