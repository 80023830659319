import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { restrictions } from '@rtt-libs/constants';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editButton: {
      color: theme.palette.primary.contrastText,
      transform: `translateY(-${theme.spacing(0.5)}px)`,
    },
  }),
);

const isAbleToEdit = (createdAt: Date) => {
  const now = Date.now();

  const lastEditTime =
    createdAt.valueOf() + restrictions.CHATS_MESSAGE_EDIT_TIMEOUT;

  return lastEditTime > now;
};

interface TimeHiddenComponentProps {
  createdAt: Date;
  onClick(): void;
}

const TimeHiddenButton = ({ createdAt, onClick }: TimeHiddenComponentProps) => {
  const classes = useStyles();

  const [showEditButton, setEditButtonDisplayState] = useState<boolean>(
    isAbleToEdit(createdAt),
  );

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (showEditButton) {
      timerId = setInterval(
        () => setEditButtonDisplayState(isAbleToEdit(createdAt)),
        500,
      );
    }

    return () => {
      clearInterval(timerId);
    };
  }, [createdAt, showEditButton]);

  if (!showEditButton) return null;

  return (
    <IconButton
      onClick={onClick}
      aria-label="edit message"
      className={classes.editButton}
      size="small"
    >
      <EditIcon fontSize="inherit" />
    </IconButton>
  );
};

export default TimeHiddenButton;
