/* eslint-disable @typescript-eslint/camelcase */
import { formErrorTransform } from '@rtt-libs/api-services';
import { ENDPOINTS } from '../environment';
import { FeedbackEmailFormValues } from '../settings/types';
import api from './apiSetup';

type FeedbackEmailResponse = {
  id: number;
  email: string;
};

/**
 * Axios API handlers
 */

/**
 * Fetch current feedback email settings
 */
export const getFeedbackEmailApi = () =>
  api
    .get<FeedbackEmailResponse>(ENDPOINTS.contactForm)
    .then(({ data }) => data.email);

/**
 * Set feedback email
 */
export const setFeedbackEmailApi = (payload: FeedbackEmailFormValues) =>
  api
    .post<FeedbackEmailResponse>(ENDPOINTS.contactForm, payload)
    .then(({ data }) => data.email)
    .catch(formErrorTransform);
