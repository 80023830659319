import { ENDPOINTS } from '../environment';
import { UsedLocales, Token, UserInfoType } from '../types';
import genLocaleHeader from './localeHeader';
import apiInstance from './setup';

const userLocaleAPI = (token: Token, locale: UsedLocales) =>
  apiInstance
    .post<UserInfoType>(
      ENDPOINTS.locale,
      {
        localization: locale.replace(/-/gi, '_'),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...genLocaleHeader(locale),
        },
      },
    )
    .then(data => data.data)
    .catch(e => {
      if (e.response?.data) {
        throw String(
          e.response.data.errors?.localization?.toString() ||
            e.response.data.message,
        );
      }
      throw e.message;
    });

export default userLocaleAPI;
