import { SUPPORTED_LOCALES } from '@rtt-libs/constants';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { ru, uk } from '@rtt-libs/locale';

export default () => {
  i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      detection: {
        order: [
          'querystring',
          'cookie',
          'localStorage',
          'navigator',
          'htmlTag',
          'path',
          'subdomain',
        ],
      },
      resources: {
        'ru-RU': {
          translation: ru,
        },
        'uk-UA': {
          translation: uk,
        },
      },
      fallbackLng: SUPPORTED_LOCALES,
      debug: process.env.NODE_ENV === 'development',

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default

        format: (value, format, lng) => {
          if (format === 'intlDate') {
            return new Intl.DateTimeFormat(lng).format(
              value instanceof Date ? value : new Date(value),
            );
          }
          if (format === 'intlDateTime') {
            return (value instanceof Date
              ? value
              : new Date(value)
            ).toLocaleString(lng);
          }

          return value;
        },
      },
    });
};
