import {
  DocumentPreviewType,
  Pagination,
  RttDetails,
  RttStats,
  StatusWithNotice,
} from '@rtt-libs/types';
import isEqual from 'lodash/fp/isEqual';
import xor from 'lodash/fp/xor';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeRtt,
  changeRttActive,
  changeRttStatus,
  getRtt,
  getRttDetails,
  getRttNotices,
  Rtt,
} from '../../api/rtt';
import { selectAllCategories } from '../../categoriesTree/duck/selectors';
import * as actions from './actions';
import { getRttDetailsSel } from './selectors';
import * as TYPES from './types';

/**
 * Fetch rtt list to render table
 */
function* rttListWorker({ payload }: ReturnType<typeof actions.getRttRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: Rtt[]; meta: { pagination: Pagination } } = yield call(
      getRtt,
      payload,
    );

    yield all([put(actions.getRttSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.getRttFailure(e.message));
  }
}

/**
 * Change rtt status described with notices (if required)
 */
function* rttStatusWorker({
  payload,
}: ReturnType<typeof actions.changeRttStatusRequest>) {
  try {
    const status: StatusWithNotice = yield call(changeRttStatus, payload);

    yield all([
      put(
        actions.changeRttStatusSuccess(status, {
          id: payload.id,
          status: status.status,
        }),
      ),
    ]);
  } catch (e) {
    if (e.response) {
      yield put(actions.changeRttStatusFailure(e.response.data.message));
      return;
    }
    yield put(actions.changeRttStatusFailure(e.message));
  }
}

/**
 * Fetch detailed rtt info, available statuses & last notice to render detail page
 */
function* rttDetailsWorker({
  payload,
}: ReturnType<typeof actions.getRttDetailsRequest>) {
  try {
    const [rtt, noticeValues]: [
      RttDetails & { notices?: string | null } & RttStats,
      StatusWithNotice[],
    ] = yield all([call(getRttDetails, payload), call(getRttNotices, payload)]);

    // Display last declined notice after rtt updated his info
    const lastNotice = noticeValues[0];
    const declinedNotice =
      lastNotice && rtt.status.value === 'updated' ? lastNotice.notices : null;

    const notices = rtt.notices || declinedNotice;

    yield all([
      put(
        actions.getRttDetailsSuccess(rtt, {
          notices,
        }),
      ),
    ]);
  } catch (e) {
    yield put(actions.getRttDetailsFailure(e.message));
  }
}

/**
 * Edit rtt info.
 */
function* updateWorker({
  payload,
}: ReturnType<typeof actions.changeRttRequest>) {
  try {
    const initialValues: RttDetails | undefined = yield select(
      getRttDetailsSel,
    );
    const { allCategories } = yield select(selectAllCategories);
    const { documents = [], categories = [], showcases = [], status } =
      initialValues || {};

    const filterCategories =
      payload.categories &&
      payload.categories.filter(
        categoryId => !allCategories[categoryId].is_deleted && categoryId,
      );

    const {
      added: addedDocuments,
      deleted: deletedDocuments,
    } = getUpdatedAttachments(documents, payload.documents);

    const {
      added: addedShowcases,
      deleted: deletedShowcases,
    } = getUpdatedAttachments(showcases, payload.showcases);

    // Do not send any categories if them didn't changed.
    const updatedCategories = isEqual(categories, filterCategories)
      ? undefined
      : filterCategories;

    if (status?.value === 'active') {
      const data: RttDetails &
        RttStats & {
          notices?: StatusWithNotice['notices'];
        } = yield call(
        changeRttActive,
        payload.id,
        payload,
        addedShowcases,
        deletedShowcases,
        updatedCategories,
      );
      yield all([put(actions.changeRttSuccess(data))]);
    } else {
      const data: RttDetails &
        RttStats & {
          notices?: StatusWithNotice['notices'];
        } = yield call(
        changeRtt,
        payload.id,
        payload,
        addedDocuments,
        deletedDocuments,
        addedShowcases,
        deletedShowcases,
        updatedCategories,
      );
      yield all([put(actions.changeRttSuccess(data))]);
    }
  } catch (e) {
    yield put(actions.changeRttFailure(e));
  }
}

/**
 * Extract added & deleted attachments
 */
function getUpdatedAttachments(
  initialAttachments: (File | DocumentPreviewType)[],
  newAttachments: (File | DocumentPreviewType)[],
) {
  const changed = xor<File | DocumentPreviewType>(
    initialAttachments,
    newAttachments,
  );

  const added = changed.filter(doc => doc instanceof File) as File[];
  const deleted = changed
    .filter(doc => !(doc instanceof File))
    .map(doc => (doc as DocumentPreviewType).id);

  return { added, deleted };
}

/**
 * Keeps all sub-sagas inside watcher saga.
 * Specify any takeLatest, takeEvery... right here.
 */
export default function* watcher() {
  yield takeLatest(TYPES.GET_RTT_REQUEST, rttListWorker);
  yield takeLatest(TYPES.CHANGE_RTT_STATUS_REQUEST, rttStatusWorker);
  yield takeLatest(TYPES.GET_RTT_DETAILS_REQUEST, rttDetailsWorker);
  yield takeLatest(TYPES.CHANGE_RTT_REQUEST, updateWorker);
}
