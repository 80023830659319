import type { Channel } from 'redux-saga';
import {
  call,
  cancelled,
  delay,
  fork,
  join,
  put,
  race,
  take,
} from 'redux-saga/effects';
import createSocketChannel from './sagaChannel';

// wrapping functions for socket events (connect, disconnect, reconnect)
const connect = (socket: SocketIOClient.Socket) =>
  new Promise(resolve => {
    socket.on('connect', () => {
      resolve(socket);
    });
  });

const disconnect = (socket: SocketIOClient.Socket) =>
  new Promise(resolve => {
    socket.on('disconnect', () => {
      resolve(socket);
    });
  });

const reconnect = (socket: SocketIOClient.Socket) =>
  new Promise(resolve => {
    socket.on('reconnect', () => {
      resolve(socket);
    });
  });

// connection monitoring sagas
function* listenDisconnectSaga(socket: SocketIOClient.Socket) {
  while (true) {
    yield call(disconnect, socket);
  }
}

function* listenConnectSaga(socket: SocketIOClient.Socket) {
  while (true) {
    yield call(reconnect, socket);
  }
}

function* listenChannelSaga(channel: Channel<unknown>) {
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

// Saga to switch on channel.
export default function* socketApiListener(socket: SocketIOClient.Socket) {
  try {
    const createdChannel = yield call(createSocketChannel, socket);
    const channelTask = yield fork(listenChannelSaga, createdChannel);

    // TODO: Add reconnect & disconnect handlers if needed
    yield fork(listenConnectSaga, socket);
    yield fork(listenDisconnectSaga, socket);

    yield race({
      connected: call(connect, socket),
      timeout: delay(10000),
    });

    yield join(channelTask);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    if (yield cancelled()) {
      if (socket && socket.disconnect) {
        socket.disconnect();
      }
    }
  }
}
