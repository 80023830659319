import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type DocumentPreviewThumbnailType = {
  thumbSrc: string;
  thumbSize: string;
};

export type DocumentPreviewType = {
  id: string;
  src: string;
  size: string;
  name: string | null;
} & Partial<DocumentPreviewThumbnailType>;

type Props = {
  imgBlob: File | DocumentPreviewType;
  onClick: () => void;
  readOnly?: boolean;
};

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    minWidth: 128,
  },
  media: {
    height: 140,
  },
});

const ImagePreview: React.FC<Props> = ({
  imgBlob,
  onClick,
  readOnly = false,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const { name } = imgBlob;

  const blobSrc = useMemo(() => {
    if (imgBlob instanceof File) {
      // mimic DocumentPreviewType
      return { src: URL.createObjectURL(imgBlob), thumbSrc: undefined };
    }

    return imgBlob;
  }, [imgBlob]);

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={() => window.open(blobSrc.src, '_blank')}>
        <CardMedia
          className={classes.media}
          image={blobSrc.thumbSrc || blobSrc.src}
          title={name || t('defaults.documentTitle')}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {name || t('defaults.documentTitle')}
          </Typography>
        </CardContent>
      </CardActionArea>
      {!readOnly && (
        <CardActions>
          <Button size="small" color="primary" onClick={onClick}>
            {t('controls.delete')}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ImagePreview;
