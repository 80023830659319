import { call, takeEvery } from 'redux-saga/effects';
import type { EmitSocketEventAction } from '../../actions';
import { CHATS_EMIT_EVENT } from '../../types';

function* socketEmitWorker(
  socket: SocketIOClient.Socket,
  action: EmitSocketEventAction,
) {
  yield call([socket, 'emit'], action.payload.event, action.payload.data);
}

export default function* socketEmitListener(socket: SocketIOClient.Socket) {
  yield takeEvery(CHATS_EMIT_EVENT, socketEmitWorker, socket);
}
