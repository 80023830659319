import BusinessIcon from '@material-ui/icons/Business';
import CategoryIcon from '@material-ui/icons/Category';
import ChatIcon from '@material-ui/icons/Chat';
import HomeIcon from '@material-ui/icons/Home';
import InboxIcon from '@material-ui/icons/Inbox';
import SettingsIcon from '@material-ui/icons/Settings';
import StoreIcon from '@material-ui/icons/Store';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { useAuth } from '@rtt-libs/auth';
import { selectors } from '@rtt-libs/state-containers';
import { Navigation as NavigationExt, NavigationConfig } from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ACTIVATION_REQUEST_STATUS, REGISTERED_STATUS } from '../environment';
import routes from '../pages/routeStrings';
import { PERMISSIONS } from '../rules';

const Navigation: React.FC = () => {
  const [t] = useTranslation();
  const { isPermittedTo } = useAuth();

  const { newMessages } = useSelector(selectors.selectNotifications);

  const linkConfig = useMemo<NavigationConfig>(
    () =>
      [
        {
          label: t('navigation.home'),
          link: routes.home,
          icon: <HomeIcon />,
          shown: isPermittedTo(PERMISSIONS.APP_ACCESS),
        },
        {
          label: t('navigation.distributors.main'),
          link: routes.distributors,
          icon: <BusinessIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_DISTR),
          nested: [
            {
              label: t('navigation.distributors.requested'),
              link: `${routes.distributors}/${ACTIVATION_REQUEST_STATUS}`,
              icon: <InboxIcon />,
              exact: false,
            },
            {
              label: t('navigation.distributors.registered'),
              link: `${routes.distributors}/${REGISTERED_STATUS}`,
              icon: <VerifiedUserIcon />,
              exact: false,
            },
          ],
        },
        {
          label: t('navigation.rtt.main'),
          link: routes.rtt,
          icon: <StoreIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_RTT),
          nested: [
            {
              label: t('navigation.rtt.requested'),
              link: `${routes.rtt}/${ACTIVATION_REQUEST_STATUS}`,
              icon: <InboxIcon />,
              exact: false,
            },
            {
              label: t('navigation.rtt.registered'),
              link: `${routes.rtt}/${REGISTERED_STATUS}`,
              icon: <VerifiedUserIcon />,
              exact: false,
            },
          ],
        },
        {
          label: t('navigation.categories'),
          link: routes.categories,
          icon: <CategoryIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_CATEGORIES),
        },
        {
          label: t('navigation.moderators'),
          link: routes.moderators,
          icon: <SupervisorAccountIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_MODERATORS),
        },
        {
          label: t('navigation.support'),
          link: routes.support,
          icon: <ChatIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_SUPPORT),
          badgeProps: { badgeContent: newMessages },
        },
        {
          label: t('navigation.settings'),
          link: routes.settings,
          icon: <SettingsIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_SETTINGS),
        },
      ].filter(link => link.shown),
    [t, isPermittedTo, newMessages],
  );

  return <NavigationExt links={linkConfig} />;
};

export default Navigation;
