import { StatusValue } from '@rtt-libs/types';
import { ActionsMenu } from '@rtt-libs/views';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeStatusDialog from './ChangeStatusDialog';
import { StatusChangeHandler } from '../types';

type Props = {
  id: string | number | symbol;
  value?: unknown;
  onStatusChange: StatusChangeHandler;
};

/**
 * Menu with available status options & confirmation dialog
 */
const ActionsMenuComponent: React.FC<Props> = ({
  id,
  value = [],
  onStatusChange,
}) => {
  const [t] = useTranslation();

  const [applyingStatus, setApplyingStatus] = useState<StatusValue | undefined>(
    undefined,
  );

  const onClick = (statusNew: StatusValue | undefined) => () => {
    setApplyingStatus(statusNew);
  };

  const onClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }
    setApplyingStatus(undefined);
  };

  const onChangeStatus = ({ notices }: { notices?: string }) => {
    onStatusChange({
      id: typeof id === 'number' ? id : parseInt(id.toString(), 10),
      status: applyingStatus as StatusValue,
      notices,
    });
    onClose();
  };

  const options = (value as StatusValue[]).map(statusValue => ({
    label: t(`status.change.${statusValue}`),
    onClick: onClick(statusValue),
  }));

  return (
    <>
      <ActionsMenu id={id} options={options} />
      <ChangeStatusDialog
        applyingStatus={applyingStatus}
        isOpen={!!applyingStatus}
        onClose={onClose}
        onChangeStatus={onChangeStatus}
      />
    </>
  );
};

export default ActionsMenuComponent;
