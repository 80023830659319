const routes = {
  home: '/',
  categories: '/categories',
  distributors: '/distributors',
  moderators: '/moderators',
  rtt: '/rtt',
  settings: '/settings',
  support: '/support',
};

export default routes;
