import {
  Box,
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import Image from 'material-ui-image';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { IMessage } from 'react-web-gifted-chat';
import { uploadImageCleanup, uploadImageRequest } from '../../../duck/actions';
import {
  selectImageUploadError,
  selectImageUploadLoading,
  selectLastUploadedImage,
} from '../../../duck/selectors';
import { PostMessageData } from '../../../types';

type ImageUploadModalContentProps = {
  onSend?(messages: Partial<IMessage & { postData?: PostMessageData }>[]): void;
  onClose(): void;
};

const useStyles = makeStyles(() =>
  createStyles({
    hidden: {
      display: 'none',
    },
    disabled: {
      pointerEvents: 'none',
    },
  }),
);

const ImageUploadModalContent = ({
  onSend,
  onClose,
}: ImageUploadModalContentProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = () => {
    const imageFile = imageInputRef.current?.files?.[0];

    if (imageFile) dispatch(uploadImageRequest(imageFile));
  };

  const [imagePreview, changeImagePreview] = useState<string>();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeImagePreview(
      event.target?.files?.[0] && URL.createObjectURL(event.target?.files?.[0]),
    );
  };

  const uploadingError = useSelector(selectImageUploadError);

  const lastUploadedImage = useSelector(selectLastUploadedImage);
  const isImageUploadLoading = useSelector(selectImageUploadLoading);

  useEffect(() => {
    if (lastUploadedImage && onSend) {
      onSend([
        {
          image: lastUploadedImage.src,
          postData: {
            type: 'image',
            data: {
              imgId: lastUploadedImage.id,
            },
          },
        },
      ]);

      onClose();
    }
  }, [dispatch, onSend, lastUploadedImage, onClose]);

  useEffect(
    () => () => {
      dispatch(uploadImageCleanup());
    },
    [dispatch],
  );

  return (
    <>
      <DialogTitle>{t('distributor.tables.orders.image')}</DialogTitle>
      <DialogContent
        className={isImageUploadLoading ? classes.disabled : undefined}
      >
        <Box component="label" display="block" overflow="hidden">
          <input
            ref={imageInputRef}
            type="file"
            name="image"
            accept="image/*"
            required
            className={classes.hidden}
            onChange={handleImageChange}
          />
          <Image
            src={imagePreview || ''}
            imageStyle={{ height: '100%', objectFit: 'contain' }}
            alt="preview uploaded"
            loading={<ImageSearchIcon style={{ fontSize: '4rem' }} />}
          />
          <Button component="span">{t('controls.select')}</Button>
        </Box>

        {uploadingError && (
          <FormHelperText error>{uploadingError}</FormHelperText>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={!imagePreview || isImageUploadLoading}
          onClick={handleSubmit}
        >
          {t('controls.send')}
        </Button>
        <Button type="button" onClick={onClose}>
          {t('controls.cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

export default ImageUploadModalContent;
