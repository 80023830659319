/* eslint-disable @typescript-eslint/camelcase */
import type { ActionCreator, ActionCreatorsMapObject } from 'redux';
import { eventChannel } from 'redux-saga';
import * as actions from '../../actions';
import type { SocketChatsAction } from '../../actions';

// Every key name corresponds to socket.io message title
// Value is actions which will throw if message is taken.
const socketEventHandlers: ActionCreatorsMapObject<SocketChatsAction> = {
  message: actions.chatReceiveMessage,
  system_message: actions.chatReceiveSystemNotification,
  subscription_error: actions.chatReceiveError,
};

// Assign redux actionCreators as socket.io event listeners
const createSocketChannel = (socket: SocketIOClient.Socket) =>
  eventChannel(emit => {
    // TODO: [refactor] - could be extracted pure fns
    const handler = (actionCreator: ActionCreator<SocketChatsAction>) => (
      ...data: unknown[]
    ) => {
      emit(actionCreator(...data));
    };

    const setSocketEventListeners = (method: 'on' | 'off') =>
      Object.keys(socketEventHandlers).forEach(event => {
        socket[method](event, handler(socketEventHandlers[event]));
      });

    setSocketEventListeners('on');

    return () => {
      setSocketEventListeners('off');
    };
  });

export default createSocketChannel;
