/* eslint-disable @typescript-eslint/camelcase */

import {
  extractThumbnail,
  mapPagination,
  formErrorTransform,
  TransformAPIError,
} from '@rtt-libs/api-services';
import {
  AttachmentDocumentType,
  CategoryItemId,
  FetchedContact as Contact,
  FetchedNotices,
  FetchedPagination,
  GeoLocation,
  OrderParam,
  RttDetails,
  Status,
  StatusValue,
  StatusWithNotice,
  RttStats,
} from '@rtt-libs/types';
import { ENDPOINTS } from '../environment';
import { FilterStatus, StatusGroup } from '../types';
import { getRttStatusesFromGroup } from '../utils/getStatusesFromGroup';
import api from './apiSetup';

export type FetchedRtt = {
  id: number;
  created_at: string;
  name: string;
  address: string;
  geo: GeoLocation;
  status: Status;
  contact?: Contact;
  documents?: AttachmentDocumentType[];
  categories?: CategoryItemId[];
  showcases?: AttachmentDocumentType[];
  notices?: StatusWithNotice;
  stats?: { order_qty: number };
};

export type RttResponseType = {
  data: FetchedRtt[];
  meta: {
    pagination: FetchedPagination;
  };
};

export type Rtt = {
  id: number;
  createdAt: string;
  name: string;
  address: string;
  status: Status;
  contactName?: string;
  phone?: string;
};

export type RttGetParams = {
  page: number;
  perPage: number | null;
  name: string;
  address: string;
  status: FilterStatus;
  orderBy: 'name' | 'address' | 'status';
  order: OrderParam;
  statusGroup: StatusGroup;
};

/**
 * Axios API handlers
 */

/**
 * Fetch paginated rtt list
 */
export const getRtt = (params: Partial<RttGetParams> = {}) =>
  api
    .get<RttResponseType>(ENDPOINTS.rttList, {
      params: mapGetParams(params),
    })
    .then(({ data }) => ({
      data: bulkMapRtt(data.data),
      meta: {
        pagination: mapPagination(data.meta.pagination),
      },
    }));

/**
 * Change status of rtt, if declined - with notices.
 * @param data updated data with rtt id & status info
 */
export const changeRttStatus = ({
  id,
  status,
  notices,
}: {
  id: number;
  status: StatusValue;
  notices?: string;
}) =>
  api
    .post<StatusWithNotice>(ENDPOINTS.rttAppChange, {
      rtt_id: id,
      status,
      notices,
    })
    .then(({ data }) => data);

/**
 * Fetch full rtt info
 * @param id required rtt id
 */
export const getRttDetails = (id: number) =>
  api
    .get<FetchedRtt>(`${ENDPOINTS.rtt}/${id}`, {
      params: {
        include: 'contact,documents,categories,showcases',
      },
    })
    .then(({ data }) => {
      return mapRttDetails(data);
    });

/**
 * Fetch administrator notices which were added when rtt was declined.
 * @param id required rtt id
 */
export const getRttNotices = (id: number) =>
  api
    .get<FetchedNotices>(ENDPOINTS.rttAppNotices, {
      params: {
        rtt_id: id,
      },
    })
    .then(({ data }) => {
      return data.data ? data.data : [];
    });

/**
 * Update rtt info
 * Works only if rtt didn't accepted yet.
 */
export const changeRtt = (
  id: number,
  payload: Omit<RttDetails, 'status' | 'phone'>,
  addedDocuments: File[],
  deletedDocuments: string[],
  addedShowcases: File[],
  deletedShowcases: string[],
  categories?: CategoryItemId[],
) => {
  const requestData = combineEditRttFormData(
    payload,
    addedDocuments,
    deletedDocuments,
    addedShowcases,
    deletedShowcases,
    categories,
  );
  return api
    .post<FetchedRtt>(
      `${ENDPOINTS.rtt}/${id}/update?include=contact,documents,categories,showcases`,
      requestData,
    )
    .then(({ data }) => mapRttDetails(data))
    .catch(e => {
      throw formErrorTransform(e, transformRttErrors);
    });
};

export const changeRttActive = (
  id: number,
  payload: Omit<RttDetails, 'status' | 'phone'>,
  addedShowcases: File[],
  deletedShowcases: string[],
  categories?: CategoryItemId[],
) => {
  const requestData = combineEditRttActiveFormData(
    payload,
    addedShowcases,
    deletedShowcases,
    categories,
  );
  return api
    .post<FetchedRtt>(ENDPOINTS.rttEditActive(id), requestData)
    .then(({ data }) => mapRttDetails(data))
    .catch(e => {
      throw formErrorTransform(e, transformRttErrors);
    });
};

/**
 * Mappers
 */

function mapRtt({
  id,
  name,
  address,
  status,
  contact,
  created_at,
}: FetchedRtt): Rtt {
  return {
    id,
    createdAt: created_at,
    name,
    status,
    address,
    contactName: contact && `${contact.first_name} ${contact.last_name}`,
    phone: contact && contact.phone,
  };
}

const mapAttachmentFiles = (attachments: AttachmentDocumentType[]) =>
  attachments.map(({ id, src, thumb_size, name, related }) => ({
    id,
    src,
    name,
    size: thumb_size,
    ...extractThumbnail(related),
  }));

function mapRttDetails({
  id,
  name,
  geo,
  address,
  contact,
  status,
  categories = [],
  documents = [],
  showcases = [],
  notices = {} as NonNullable<FetchedRtt['notices']>,
  created_at,
  stats,
}: FetchedRtt): RttDetails &
  RttStats & {
    notices?: StatusWithNotice['notices'];
  } {
  return {
    id,
    name,
    address,
    geo,
    firstName: contact ? contact.first_name : '',
    lastName: contact ? contact.last_name : '',
    categories,
    email: contact ? contact.email : '',
    documents: mapAttachmentFiles(documents),
    showcases: mapAttachmentFiles(showcases),
    phone: contact ? contact.phone : '',
    status,
    notices: notices.notices,
    createdAt: created_at,
    stats: { orderQty: stats?.order_qty ?? 0 },
  };
}

function bulkMapRtt(rttArray: FetchedRtt[]): Rtt[] {
  return rttArray.map(mapRtt);
}

function mapGetParams(params: Partial<RttGetParams>) {
  const statusGroup = getRttStatusesFromGroup(params.statusGroup);
  return {
    include: 'contact',
    page: params.page,
    per_page: params.perPage,
    name: params.name,
    address: params.address,
    status:
      !params.status && statusGroup ? statusGroup.join(',') : params.status,
    sort: params.orderBy,
    order: params.order,
  };
}

/**
 * Prepare changed rtt data for PUT to API
 */
function combineEditRttFormData(
  payload: Omit<RttDetails, 'status' | 'phone'>,
  addedDocuments: File[],
  deletedDocuments: string[],
  addedShowcases: File[],
  deletedShowcases: string[],
  categories?: CategoryItemId[],
) {
  const data = new FormData();

  // Assign values to key acceptable on API.
  const mappedPayload = {
    name: payload.name,
    address: payload.address,
    'geo[lat]': payload.geo.lat.toString(),
    'geo[lng]': payload.geo.lng.toString(),
    email: payload.email,
    'categories[]': categories,
    'documents_added[]': addedDocuments,
    'documents_deleted[]': deletedDocuments,
    'showcases_added[]': addedShowcases,
    'showcases_deleted[]': deletedShowcases,
    first_name: payload.firstName,
    last_name: payload.lastName,
  };

  // Add assign key-value pairs to FormData instance.
  // if value is Array - appends to a specified key in loop
  Object.entries(mappedPayload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      (value as (string | number | File)[]).forEach(valueItem => {
        data.append(key, valueItem as string | Blob);
      });
    } else if (value !== undefined) {
      data.append(key, value);
    }
  });

  return data;
}

function combineEditRttActiveFormData(
  payload: Omit<RttDetails, 'status' | 'phone'>,
  addedShowcases: File[],
  deletedShowcases: string[],
  categories?: CategoryItemId[],
) {
  const data = new FormData();

  // Assign values to key acceptable on API.
  const mappedPayload = {
    name: payload.name,
    address: payload.address,
    'geo[lat]': payload.geo.lat.toString(),
    'geo[lng]': payload.geo.lng.toString(),
    email: payload.email,
    'categories[]': categories,
    'showcases_added[]': addedShowcases,
    'showcases_deleted[]': deletedShowcases,
    first_name: payload.firstName,
    last_name: payload.lastName,
  };

  // Add assign key-value pairs to FormData instance.
  // if value is Array - appends to a specified key in loop
  Object.entries(mappedPayload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      (value as (string | number | File)[]).forEach(valueItem => {
        data.append(key, valueItem as string | Blob);
      });
    } else if (value !== undefined) {
      data.append(key, value);
    }
  });

  return data;
}

const transformRttErrors: TransformAPIError<
  Record<
    keyof FetchedRtt | 'phone' | 'email' | 'first_name' | 'last_name',
    string | string[]
  >,
  Record<keyof RttDetails, string>
> = function transformRttErrors(e) {
  return {
    id: e.id?.toString(),
    address: e.address?.toString(),
    geo: e.geo?.toString(),
    categories: e.categories?.toString(),
    documents: e.documents?.toString(),
    showcases: e.showcases?.toString(),
    name: e.name?.toString(),
    email: e.email?.toString(),
    firstName: e.first_name?.toString(),
    lastName: e.last_name?.toString(),
  };
};
