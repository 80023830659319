import { Grid, TextField } from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import { FilterMenu } from '@rtt-libs/views';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchParams } from '../../api/moderators';
import { CreateModerator } from './CreateModerator';

type Props = {
  onChange(params: SearchParams): void;
  initialFilterValues: Record<string, string>;
};

/**
 * Component to handle filtering of table data.
 */
const FilterRow: React.FC<Props> = ({
  onChange,
  initialFilterValues: { isActive = '', search = '' },
}) => {
  const [t] = useTranslation();
  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ search: e.target.value });
  };

  const filters = [
    {
      name: 'isActive',
      title: t('admin.tables.moderators.isActive.label'),
      options: [
        {
          value: '',
          label: t('common.resetOption'),
        },
        {
          value: '1',
          label: t('admin.tables.moderators.isActive.active'),
        },
        {
          value: '0',
          label: t('admin.tables.moderators.isActive.inactive'),
        },
      ],
    },
  ];

  return (
    <>
      <Grid item xs={1}>
        <FilterMenu
          filters={filters}
          onChange={onChange}
          initialValues={{ isActive: isActive || '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          defaultValue={search}
          name="search"
          label={t('admin.moderators.search')}
          onChange={onSearchChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={3}>
        <CreateModerator />
      </Grid>
    </>
  );
};

export default FilterRow;
