import { UsedLocales, UserInfoType } from '../types';
import { getAuthToken } from '../cookieHandlers';
import { userLocaleAPI } from '../api';

/**
 * Set locale of user interface
 */
export const setUserLocale = async (
  locale: UsedLocales,
): Promise<UserInfoType> => {
  const token = getAuthToken();

  if (!token) throw new Error('no token');

  return userLocaleAPI(token, locale);
};
