import { call, put, takeEvery } from 'redux-saga/effects';
import { getNotificationsAPI } from '../../api';
import type * as NotificationTypes from '../types';
import * as actions from './actions';
import * as types from './types';

export function* notificationsGetWorker(
  _: ReturnType<typeof actions.notificationsGetRequest>,
) {
  try {
    const notifications: NotificationTypes.NotificationStats = yield call(
      getNotificationsAPI,
    );

    yield put(actions.notificationsGetSuccess(notifications));
  } catch (e) {
    yield put(actions.notificationsGetFailure(e.message));
  }
}

export default function* watcher() {
  yield takeEvery(types.NOTIFICATIONS_GET_REQUEST, notificationsGetWorker);
}
