import {
  createStyles,
  Divider,
  FormControlLabel,
  IconButton,
  ListSubheader,
  makeStyles,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Theme,
} from '@material-ui/core';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import React, { useState } from 'react';
import { FilterSection } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      width: '100%',
      margin: theme.spacing(-0.75, -2, -0.75, -2),
      padding: theme.spacing(0.75, 4, 0.75, 0.5),
    },
  }),
);

type Props = {
  filters: FilterSection[];
  initialValues?: Record<string, string>;
  onChange(values: Record<string, string>): void;
};

const FilterMenu: React.FC<Props> = ({
  filters = [],
  initialValues = {},
  onChange,
}) => {
  const classes = useStyles();
  const [values, changeValues] = useState(initialValues);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newValues = {
      ...values,
      [name]: value,
    };
    changeValues(newValues);
    onChange(newValues);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-controls="filters-menu"
        color="primary"
        onClick={handleClick}
      >
        <FilterListOutlinedIcon />
      </IconButton>
      <Menu
        id="filters-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {filters.map(({ name, title, options = [] }, i) => (
          <div key={name}>
            {Boolean(i) && <Divider />}
            <ListSubheader>{title}</ListSubheader>
            <RadioGroup
              aria-label={title}
              name={name}
              value={values[name]}
              onChange={handleChange}
            >
              {options.map(({ label, value }) => (
                <MenuItem key={value}>
                  <FormControlLabel
                    className={classes.label}
                    value={value}
                    control={<Radio color="primary" />}
                    label={label}
                  />
                </MenuItem>
              ))}
            </RadioGroup>
          </div>
        ))}
      </Menu>
    </>
  );
};

export default FilterMenu;
