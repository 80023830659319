import {
  CircularProgress,
  createStyles,
  FormHelperText,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { GeoLocation } from '@rtt-libs/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    fillContainer: {
      height: '300px',
    },
  }),
);

const MAP_LIBRARIES = ['drawing'];

/** center of Zaporizhzhia */
const MAP_INITIAL_CENTER = {
  lat: 47.834,
  lng: 35.11,
};

type Props = {
  markerPosition?: GeoLocation;
  onChange: (event: React.ChangeEvent<HTMLElement> | GeoLocation) => void;
  googleMapsApiKey: string | undefined;
  readOnly: boolean;
};

const initMap = (
  onSuccess: (pos: GeoLocation) => void,
  onError: (support: boolean) => void,
) => {
  // Try HTML5 geolocation.
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        onSuccess(pos);
      },
      () => {
        onError(true);
      },
    );
  } else {
    // Browser doesn't support Geolocation
    onError(false);
  }
};

const LocationMap: React.FC<Props> = ({
  markerPosition,
  onChange,
  googleMapsApiKey,
  readOnly,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [currentLocation, setCurrentLocation] = useState(
    markerPosition || MAP_INITIAL_CENTER,
  );
  const [browserGeolocationError, setBrowserGeolocationError] = useState('');

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries: MAP_LIBRARIES,
  });

  useEffect(() => {
    if (!markerPosition) {
      const handleLocationError = (browserHasGeolocation: boolean) => {
        const message = browserHasGeolocation
          ? t('map.geolocationFailedError')
          : t('map.geolocationSupportError');

        setBrowserGeolocationError(message);
      };

      initMap(setCurrentLocation, handleLocationError);
    }
  }, [markerPosition, t]);

  const onMarkerMoveEnd = (e: google.maps.MouseEvent): void => {
    const geo = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    onChange(geo);
  };

  const renderMap = () => {
    return (
      <Paper className={classes.fillContainer}>
        <GoogleMap
          id="geomap"
          zoom={12}
          center={currentLocation}
          mapContainerClassName={classes.fillContainer}
        >
          <Marker
            position={currentLocation}
            draggable={!readOnly}
            onDragEnd={onMarkerMoveEnd}
          />
        </GoogleMap>
        <FormHelperText error>{browserGeolocationError}</FormHelperText>
      </Paper>
    );
  };

  if (loadError) {
    return <FormHelperText error>{t('map.mapLoadingError')}</FormHelperText>;
  }

  return isLoaded ? renderMap() : <CircularProgress />;
};

export default LocationMap;
