import { Dialog } from '@material-ui/core';
import React from 'react';
import EditModeratorContainer from './EditModeratorContainer';

type Props = {
  open: boolean;
  onClose(): void;
};

const EditModeratorDialog: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-moderator-dialog-title"
    >
      <EditModeratorContainer onClose={onClose} />
    </Dialog>
  );
};

export default EditModeratorDialog;
