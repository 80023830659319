import { Container } from '@material-ui/core';
import { table } from '@rtt-libs/constants';
import { Pagination } from '@rtt-libs/types';
import { TableWithHistory } from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Rtt } from '../../api/rtt';
import routes from '../../pages/routeStrings';
import { FilterStatus, StatusGroup } from '../../types';
import FilterRow from './FilterRow';
import rttTableConfig from './tableConfig';

type Props = {
  rtt: Rtt[];
  pagination: Pagination;
  status?: FilterStatus;
  statusGroup?: StatusGroup;
  loading: boolean;
  onStateChange: (state: Record<string, string | number>) => void;
};

/**
 * Wrapper component to pass distributors data into common table component
 * Also handles routing to Distributor detail page.
 */
const RttTable: React.FC<Props> = ({
  rtt,
  pagination,
  status,
  statusGroup,
  loading = false,
  onStateChange,
}) => {
  const [t] = useTranslation();
  const history = useHistory();

  const columnConfig = rttTableConfig(t);

  const initialParams = useMemo(
    () => ({
      perPage: 10,
      page: 1,
      status,
      statusGroup,
    }),
    [status, statusGroup],
  );

  const onRowClick = (id: number) => {
    history.push(`${routes.rtt}/${id}`);
  };

  const localeTableLabels = {
    sort: t('admin.tables.common.sort', 'Sort'),
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  return (
    <Container>
      <TableWithHistory
        key={statusGroup}
        filterRow={({ onChange, displayOptions }) => (
          <FilterRow onChange={onChange} initialFilterValues={displayOptions} />
        )}
        loading={loading}
        onRowClick={onRowClick}
        entries={rtt}
        columnsConfig={columnConfig}
        perPageOptions={table.PAGINATION_OPTIONS}
        initial={initialParams}
        totalEntriesCount={pagination.total}
        localeLabels={localeTableLabels}
        statusGroup={statusGroup}
        onStateChange={onStateChange}
      />
    </Container>
  );
};

export default RttTable;
