/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { someBooleanToInt } from '@rtt-libs/api-services';
import type * as MassMail from '../massMail/types';
import type * as Chats from '../types';

export interface ChatUser extends Chats.ChatUser {}
export interface ProductMessagePayload extends Chats.ProductMessagePayload {}
export interface Message extends Chats.Message {}
export interface Channel extends Chats.Channel {}

export class ChatUser {
  constructor(fetched: Chats.FetchedChatUser) {
    this.id = fetched.id;
    this.firstName = fetched.first_name;
    this.lastName = fetched.last_name;
    this.role = fetched.role;
    this.img = fetched.img;
  }
}

export class ProductMessagePayload {
  constructor(fetched: Chats.FetchedProductMessagePayload) {
    this.id = fetched.id;
    this.sku = fetched.sku;
    this.title = fetched.title;
    this.price = fetched.price;
    this.img = fetched.img;
    this.saleMeasurement = fetched.sale_measurement;
  }
}

export class Message {
  constructor(fetched: Chats.FetchedMessage) {
    this.id = fetched.id;
    this.channelId = fetched.channel_id;
    this.type = fetched.type;
    this.date = fetched.date;
    this.data = {
      message: fetched.data.message,
      imgSrc: fetched.data.img_src ?? undefined,
      product:
        fetched.data.product && new ProductMessagePayload(fetched.data.product),
    };
    this.isUnread = fetched.is_unread;
    this.sender = fetched.sender && new ChatUser(fetched.sender);
  }
}

export class Channel {
  constructor(fetched: Chats.FetchedChannel) {
    this.id = fetched.id;
    this.type = fetched.type;
    this.lastMessage =
      fetched.last_message && new Message(fetched.last_message);
    this.lastMessageDate = fetched.last_message_date;
    this.hasUnseenMessages = fetched.has_unseen_messages;
    this.isResolved = fetched.is_resolved;
    this.members = Array.isArray(fetched.members)
      ? fetched.members.map(m => new ChatUser(m))
      : undefined;
    this.admin = fetched.admin && new ChatUser(fetched.admin);
    this.distributor = fetched.distributor;
    this.rtt = fetched.rtt;
    this.isBlocked = !!fetched.is_blocked;
    this.manager = fetched.manager && {
      firstName: fetched.manager.first_name,
      lastName: fetched.manager.last_name,
      phone: fetched.manager.phone,
      email: fetched.manager.email,
    };
  }
}

export const mapInfinitePaginationParams = <
  T extends Chats.ChannelsInfinitePaginationParams = Chats.ChannelsInfinitePaginationParams
>(
  params?: T,
): Chats.InfinitePaginationRequest | undefined => {
  if (!params) return undefined;

  const { direction, id, perPage, isResolved, isAssigned, ...rest } = params;

  return {
    ...rest,
    direction,
    id,
    per_page: perPage,
    is_assigned: someBooleanToInt(isAssigned),
    is_resolved: someBooleanToInt(isResolved),
  };
};

export const mapInfinitePaginatedData = <
  FetchedType extends Chats.FetchedChannel | Chats.FetchedMessage =
    | Chats.FetchedChannel
    | Chats.FetchedMessage,
  R extends Message | Channel = FetchedType extends Chats.FetchedChannel
    ? Channel
    : Message
>(
  mapper: (fetched: FetchedType) => R,
) => (
  data: Chats.InfinitePaginationResponse<FetchedType>,
): Chats.InfinitePaginationWrapper<R> => ({
  data: data.data.map(mapper),
  meta: {
    id: data.meta.id,
    hasMore: data.meta.has_more,
    perPage: data.meta.per_page,
  },
});

export const mapChannelTypeToAPI = (
  channelType: Chats.ChannelType,
): Chats.ChannelTypeAPI =>
  channelType === 'support' ? 'supports' : 'channels';

export const mapPostMessage = (
  messageData: Chats.PostMessageData,
): Chats.PostMessageAPI => {
  const { type, data } = messageData;

  const postData: Chats.PostMessageAPI = {
    type,
    data: {
      message: data.message,
    },
  };

  if ('productId' in data) {
    postData.data.product_id = data.productId;
  }

  if ('imgId' in data) {
    postData.data.img_id = data.imgId;
  }

  return postData;
};

export const mapSystemMessage = (
  message: Chats.SystemMessage,
): Chats.SystemChannelMessage => {
  return {
    type: message.type,
    channelId: message.channel_id,
    message: message.message && new Message(message.message),
    channelType: message.channel_type,
    user: message.user && new ChatUser(message.user),
    isBlocked: message.is_blocked,
    isResolved: message.is_resolved,
    admin: message.admin ? new ChatUser(message.admin) : undefined,
  };
};

export const mapMassMailFormValues = (
  values: MassMail.MassMailFormValues,
): MassMail.MassMailPostData => ({
  rtt_list: values.rttList,
  message: values.message,
  image_list: values.imageList?.length ? values.imageList : undefined,
  product_list: values.productList?.length ? values.productList : undefined,
  brand_id: values.brandId,
});

type MassMailPostErrors = {
  [K in keyof MassMail.MassMailPostData]?: string;
};

export const mapMassMailErrors = ({
  rtt_list,
  message,
  image_list,
  product_list,
  brand_id,
  ...errors
}: MassMailPostErrors): MassMail.MassMailFormErrors => ({
  message,
  rttList: rtt_list,
  imageList: image_list,
  productList: product_list,
  brandId: brand_id,
  ...errors,
});
