import {
  AuthProvider,
  logoutHere,
  setupInterceptors,
  useAuth,
} from '@rtt-libs/auth';
import { DEFAULT_LNG, SUPPORTED_LOCALES } from '@rtt-libs/constants';
import { CustomThemeProvider } from '@rtt-libs/views';
import * as Sentry from '@sentry/browser';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import api from './api/apiSetup';
import { ErrorBoundaryWithFallback } from './common';
import Pages from './pages';
import rules from './rules';
import store from './setup/store';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: process.env.NODE_ENV === 'production',
});

setupInterceptors(api, () => logoutHere());

const App: React.FC = () => {
  return (
    <ErrorBoundaryWithFallback>
      <AuthProvider rules={rules}>
        <IntlKeyWrapper />
      </AuthProvider>
    </ErrorBoundaryWithFallback>
  );
};

export default App;

const IntlKeyWrapper = () => {
  const { i18n } = useTranslation();

  const { user } = useAuth();

  useEffect(() => {
    // Backend could give 'ru', 'ru_', 'ru_RU' strings. Acceptable form is 'ru-RU'.
    const localeSubstring = user?.locale?.slice(0, 2);

    const locale =
      SUPPORTED_LOCALES.find(
        loc => !!localeSubstring && loc.startsWith(localeSubstring),
      ) || DEFAULT_LNG;

    document.documentElement.lang = locale;

    if (user && i18n.language !== locale) {
      i18n.changeLanguage(locale);
      return undefined;
    }
    if (!SUPPORTED_LOCALES.includes(i18n.language)) {
      i18n.changeLanguage(DEFAULT_LNG);
      return undefined;
    }
    return undefined;
  }, [user, i18n]);

  return (
    <React.Fragment key={i18n.language}>
      <AppContent />
    </React.Fragment>
  );
};

const AppContent = () => (
  <Provider store={store}>
    <CustomThemeProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Pages />
      </SnackbarProvider>
    </CustomThemeProvider>
  </Provider>
);
