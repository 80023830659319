import { CategoryItem, CategoryItemId } from '@rtt-libs/types';
import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createCategory,
  createRootCategory,
  deleteCategory,
  editCategory,
  getCategories,
  moveCategory,
  moveRootCategory,
  getAllCategories,
} from '../../api';
import formErrorTransform from '../../formErrorTransform';
import { AddCategoryFormValues, EditCategoryFormValues } from '../types';
import * as actions from './actions';
import * as TYPES from './types';

function* getWorker() {
  try {
    const data: Record<CategoryItemId, CategoryItem> = yield call(
      getCategories,
    );

    yield all([put(actions.categoriesSuccess(data))]);
  } catch (e) {
    yield put(actions.categoriesFailure());
  }
}

function* addWorker({
  payload,
}: Action & Record<'payload', AddCategoryFormValues>) {
  try {
    let responseData: AxiosResponse<CategoryItem>;
    if (payload.parentId) {
      responseData = yield call(
        createCategory,
        payload as AddCategoryFormValues,
      );
    } else {
      responseData = yield call(
        createRootCategory,
        payload as AddCategoryFormValues,
      );
    }

    yield all([
      put(actions.categoriesAddResponse()),
      put(
        actions.categoriesAddSuccess(responseData.data, {
          parentId: payload.parentId as CategoryItemId,
        }),
      ),
    ]);
  } catch (e) {
    yield put(actions.categoriesAddResponse(formErrorTransform(e)));
  }
}

function* editWorker({
  payload,
}: ReturnType<typeof actions.categoriesEditRequest>) {
  try {
    const { data } = yield call(
      editCategory,
      payload as EditCategoryFormValues,
    );

    yield all([
      put(actions.categoriesEditResponse()),
      put(actions.categoriesEditSuccess(data)),
    ]);
  } catch (e) {
    yield put(actions.categoriesEditResponse(formErrorTransform(e)));
  }
}

function* moveWorker({
  payload,
  meta,
}: ReturnType<typeof actions.categoriesMoveRequest>) {
  try {
    if (payload.parentId === 'root') {
      yield call(moveRootCategory, payload);
    } else {
      yield call(moveCategory, payload);
    }

    yield put(actions.categoriesMoveSuccess(payload));
  } catch (e) {
    yield put(
      actions.categoriesMoveFailure(
        e.response ? e.response.data.message : e.message,
      ),
    );
    yield put(actions.categoriesSuccess(meta));
  }
}

function* deleteWorker({
  payload,
}: ReturnType<typeof actions.categoriesDeleteRequest>) {
  try {
    yield call(deleteCategory, payload as CategoryItemId);

    yield all([
      put(actions.categoriesDeleteResponse()),
      put(actions.categoriesDeleteSuccess({ id: payload })),
    ]);
  } catch (e) {
    yield put(actions.categoriesDeleteResponse(formErrorTransform(e)));
  }
}

function* getAllCategoriesWorker() {
  try {
    const data: Record<string | number, CategoryItem> = yield call(
      getAllCategories,
    );

    yield all([put(actions.getAllCategoriesSuccess(data))]);
  } catch (e) {
    yield put(actions.getAllCategoriesFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(TYPES.CATEGORIES_REQUEST, getWorker);
  yield takeLatest(TYPES.CATEGORIES_ADD_REQUEST, addWorker);
  yield takeLatest(TYPES.CATEGORIES_EDIT_REQUEST, editWorker);
  yield takeLatest(TYPES.CATEGORIES_MOVE_REQUEST, moveWorker);
  yield takeLatest(TYPES.CATEGORIES_DELETE_REQUEST, deleteWorker);
  yield takeLatest(TYPES.ALL_CATEGORIES_REQUEST, getAllCategoriesWorker);
}
