import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterStatus, StatusGroup } from '../../types';
import { getRttRequest } from '../duck/actions';
import { getTableContentSel } from '../duck/selectors';
import RttTable from './RttTable';

type Props = {
  status?: FilterStatus;
  statusGroup?: StatusGroup;
};

/**
 * Container to fetch & filter distributors & render them in table.
 */
const RttContainer: React.FC<Props> = ({ status, statusGroup }) => {
  const dispatch = useDispatch();
  const { rtt, pagination, loading } = useSelector(getTableContentSel);

  const onTableStateChange = useCallback(
    (options: Record<string, string | number>) => {
      dispatch(getRttRequest(options));
    },
    [dispatch],
  );

  return (
    <RttTable
      status={status}
      statusGroup={statusGroup}
      rtt={rtt}
      pagination={pagination}
      loading={loading}
      onStateChange={onTableStateChange}
    />
  );
};

export default RttContainer;
