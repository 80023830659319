import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { Logout, PrivateRoute, useAuth } from '@rtt-libs/auth';
import { ChatNotificationSwitch, useSystemChannel } from '@rtt-libs/chats';
import { STATUSES } from '@rtt-libs/constants';
import { actions as notificationActions } from '@rtt-libs/state-containers';
import { Layout } from '@rtt-libs/views';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import LanguageSwitcher from '../common/LanguageSwitcher';
import Navigation from '../common/Navigation';
import {
  ACTIVATION_REQUEST_STATUS,
  LOGIN_PAGE,
  REGISTERED_STATUS,
} from '../environment';
import { PERMISSIONS } from '../rules';
import routes from './routeStrings';

const Categories = React.lazy(() => import('./Categories'));
const DistributorDetails = React.lazy(() => import('./DistributorDetails'));
const Distributors = React.lazy(() => import('./Distributors'));
const Moderators = React.lazy(() => import('./Moderators'));
const Rtt = React.lazy(() => import('./Rtt'));
const RttDetails = React.lazy(() => import('./RttDetails'));
const Settings = React.lazy(() => import('./Settings'));
const SupportPage = React.lazy(() => import('./SupportPage'));

const HeaderContent: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h6" component="h2" noWrap>
        {t('admin.mainTitle')}
      </Typography>

      <Box display="flex" alignItems="center">
        <ChatNotificationSwitch />

        <Box px={2}>
          <LanguageSwitcher />
        </Box>

        <Logout component={<Button>{t('common.exit')}</Button>} />
      </Box>
    </div>
  );
};

const routeWithStatusFilter = (route: string) =>
  `${route}/:statusGroup(${ACTIVATION_REQUEST_STATUS}|${REGISTERED_STATUS})/:status(${STATUSES.join(
    '|',
  )})?`;

const Pages: React.FC = () => {
  const { isPermittedTo, user } = useAuth();
  useSystemChannel(user?.id);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(notificationActions.notificationsGetRequest());
  }, [dispatch]);

  // redirects to login if company disabled or employee deactivated
  if (!isPermittedTo(PERMISSIONS.APP_ACCESS)) {
    window.location.href = LOGIN_PAGE;
    return null;
  }

  return (
    <Router>
      <Layout header={<HeaderContent />} navigation={<Navigation />}>
        <Suspense fallback={<CircularProgress />}>
          <Switch>
            <PrivateRoute
              exact
              path={routes.categories}
              component={Categories}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_CATEGORIES)}
              redirectPath={routes.home}
            />
            <PrivateRoute
              exact
              path={routes.distributors}
              component={() => (
                <Redirect
                  to={`${routes.distributors}/${ACTIVATION_REQUEST_STATUS}`}
                />
              )}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_DISTR)}
              redirectPath={routes.home}
            />
            <PrivateRoute
              exact
              path={routeWithStatusFilter(routes.distributors)}
              component={Distributors}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_DISTR)}
              redirectPath={routes.home}
            />
            <PrivateRoute
              exact
              path={`${routes.distributors}/:id(\\d+)`}
              component={DistributorDetails}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_DISTR)}
              redirectPath={routes.home}
            />
            <PrivateRoute
              exact
              path={routes.rtt}
              component={() => (
                <Redirect to={`${routes.rtt}/${ACTIVATION_REQUEST_STATUS}`} />
              )}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_RTT)}
              redirectPath={routes.home}
            />
            <PrivateRoute
              exact
              path={routeWithStatusFilter(routes.rtt)}
              component={Rtt}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_RTT)}
              redirectPath={routes.home}
            />
            <PrivateRoute
              exact
              path={`${routes.rtt}/:id(\\d+)`}
              component={RttDetails}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_RTT)}
              redirectPath={routes.home}
            />
            <PrivateRoute
              exact
              path={routes.home}
              component={Distributors}
              checkAccess={isPermittedTo(PERMISSIONS.APP_ACCESS)}
              redirectPath={LOGIN_PAGE}
            />
            <PrivateRoute
              exact
              path={routes.moderators}
              component={Moderators}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_MODERATORS)}
              redirectPath={routes.home}
            />
            <PrivateRoute
              exact
              path={routes.support}
              component={SupportPage}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_SUPPORT)}
              redirectPath={routes.home}
            />
            <PrivateRoute
              exact
              path={routes.settings}
              component={Settings}
              checkAccess={isPermittedTo(PERMISSIONS.NAV_SETTINGS)}
              redirectPath={routes.home}
            />
            <Route render={() => <Redirect to={routes.home} />} />
          </Switch>
        </Suspense>
      </Layout>
    </Router>
  );
};

export default Pages;
