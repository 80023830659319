import { call, put } from 'redux-saga/effects';
import * as api from '../../../api';
import type { InfinitePaginationWrapper, Message } from '../../../types';
import {
  editMessageFailure,
  editMessageRequest,
  editMessageSuccess,
  getMessageListFailure,
  getMessageListRequest,
  getMessageListSuccess,
  loadMoreMessageListFailure,
  loadMoreMessageListRequest,
  loadMoreMessageListSuccess,
  sendMessageFailure,
  sendMessageRequest,
  sendMessageSuccess,
} from '../../actions';

export function* getMessageListWorker({
  payload: { channelId, channelType, ...params },
}: ReturnType<typeof getMessageListRequest>) {
  try {
    const messages: InfinitePaginationWrapper<Message> = yield call(
      api.getMessageList,
      channelId,
      channelType,
      params,
    );
    yield put(getMessageListSuccess(messages));
  } catch (e) {
    yield put(getMessageListFailure(e));
  }
}

export function* loadMoreMessageListWorker({
  payload: { channelId, channelType, ...params },
}: ReturnType<typeof loadMoreMessageListRequest>) {
  try {
    const messages: InfinitePaginationWrapper<Message> = yield call(
      api.getMessageList,
      channelId,
      channelType,
      params,
    );
    yield put(
      loadMoreMessageListSuccess({
        ...messages,
        meta: { ...messages.meta, channelId, channelType },
      }),
    );
  } catch (e) {
    yield put(loadMoreMessageListFailure(e));
  }
}

export function* sendMessageWorker({
  payload: { channelId, channelType, tempMessage, ...data },
}: ReturnType<typeof sendMessageRequest>) {
  try {
    const message: Message = yield call(
      api.sendMessage,
      channelId,
      channelType,
      data,
    );
    yield put(sendMessageSuccess(message, tempMessage.id));
  } catch (e) {
    yield put(sendMessageFailure(e, tempMessage));
  }
}

export function* editMessageWorker({
  payload: { channelId, channelType, id, ...data },
}: ReturnType<typeof editMessageRequest>) {
  try {
    const message: Message = yield call(
      api.editMessage,
      channelId,
      channelType,
      id,
      data,
    );
    yield put(editMessageSuccess(message));
  } catch (e) {
    yield put(editMessageFailure(e));
  }
}
