import { Reducer } from 'redux';
import { SettingsActions } from './actions';
import * as TYPES from './types';

const initialState = {
  loading: false,
  email: undefined as string | undefined,
  error: null as string | null,
};

export type SettingsState = typeof initialState;

const reducer: Reducer<SettingsState, SettingsActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case TYPES.SETTINGS_EMAIL_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.SETTINGS_EMAIL_GET_SUCCESS:
    case TYPES.SETTINGS_EMAIL_SET_SUCCESS:
      return {
        ...state,
        email: action.payload,
        loading: false,
      };
    case TYPES.SETTINGS_EMAIL_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
