import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getChatListRequest, loadMoreChatListRequest } from '../duck/actions';
import { ChannelsInfinitePaginationParams, ChannelType } from '../types';

export const useChannelListFetch = (
  channelType: ChannelType,
  initialParams: ChannelsInfinitePaginationParams = {},
) => {
  const dispatch = useDispatch();

  const [searchParams, setParams] = useState<ChannelsInfinitePaginationParams>(
    initialParams,
  );

  useEffect(() => {
    dispatch(getChatListRequest(channelType, searchParams));
  }, [channelType, dispatch, searchParams]);

  const onParamsChange = useCallback(
    (params?: ChannelsInfinitePaginationParams) => {
      setParams(prevParams => ({ ...prevParams, ...params }));
    },
    [],
  );

  const onLoadMore = useCallback(
    (params?: ChannelsInfinitePaginationParams) => {
      setParams(prevParams => {
        dispatch(
          loadMoreChatListRequest(channelType, {
            ...prevParams,
            ...params,
          }),
        );

        return prevParams;
      });
    },
    [channelType, dispatch, setParams],
  );

  return { onParamsChange, onLoadMore, params: searchParams };
};
