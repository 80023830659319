import {
  ListItemIconProps,
  ListItemIcon,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import React from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: theme.spacing(4.5),
    },
    icon: {
      fontSize: '1.3333333rem',
    },
  }),
);

type LinkIconProps = {
  icon?: React.ReactElement;
  isActive?: boolean;
} & Omit<ListItemIconProps, 'children'>;

const LinkIcon: React.FC<LinkIconProps> = ({ icon, isActive, ...props }) => {
  const classes = useStyles();

  const newProps = {
    className: classes.icon,
    ...(isActive && { color: 'primary' as const }),
  };

  const styledIcon = icon ? (
    React.cloneElement(icon, newProps)
  ) : (
    <ArrowRightIcon {...newProps} />
  );
  return (
    <ListItemIcon className={classes.listItemIcon} {...props}>
      {styledIcon}
    </ListItemIcon>
  );
};

export default LinkIcon;
