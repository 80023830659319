import {
  CategoryItem,
  CategoryItemId,
  CategoriesItemDictionary,
} from '@rtt-libs/types';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { branchName, mainReducerKey } from './const';
import { State } from './reducer';

type PartialRootState = {
  [branchName]: State;
};

export const getAllCategoriesSel: Selector<
  PartialRootState,
  Record<CategoryItemId, CategoryItem>
> = get([branchName, mainReducerKey]);

const getAllAndDeleteCategoriesSel: Selector<
  PartialRootState,
  CategoriesItemDictionary
> = get([branchName, 'allCategories']);

export const getLoadingStateSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const getErrorSel: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

export const selectCategoryTree = createSelector(
  [getAllCategoriesSel],
  categories => ({
    rootId: 'root' as const,
    items: categories,
  }),
);

export const getRootCategoryOptionsSel = createSelector(
  [
    getAllCategoriesSel as Selector<
      PartialRootState,
      Record<CategoryItemId | 'root', CategoryItem>
    >,
    getLoadingStateSel as Selector<PartialRootState, boolean>,
  ],
  (categories, loading) => {
    const rootOptions = !categories.root
      ? []
      : categories.root.children.map(id => ({
          value: id,
          title: categories[id].data.title,
        }));
    return {
      categoryOptions: rootOptions,
      loading,
    };
  },
);

export const selectAllCategories: Selector<
  PartialRootState,
  {
    allCategories: CategoriesItemDictionary;
  }
> = createSelector([getAllAndDeleteCategoriesSel], allCategories => ({
  allCategories,
}));
