import { Pagination } from '@rtt-libs/types';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { Moderator } from '../../api/moderators';
import { branchName } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const getModeratorCollectionSel: Selector<
  PartialRootState,
  Record<Moderator['id'], Moderator>
> = get([branchName, 'collection']);

const getModeratorIdsSel: Selector<PartialRootState, Moderator['id'][]> = get([
  branchName,
  'listIds',
]);

export const selectModeratorError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

const getModeratorLoadingSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
  'moderators',
]);

const selectModeratorPagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const selectModerators: Selector<
  PartialRootState,
  {
    data: Moderator[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    getModeratorCollectionSel,
    getModeratorIdsSel,
    selectModeratorPagination,
    getModeratorLoadingSel,
    selectModeratorError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: ids.map(id => collection[id]),
    pagination,
    loading,
    error,
  }),
);
