/*
 * https://github.com/jaredpalmer/react-simple-infinite-scroll
 */

/* eslint-disable */
import * as React from 'react';
import { throttle } from 'lodash';

export interface InfiniteScrollProps {
  /**
   * Does the resource have more entities
   */
  hasMore: boolean;

  /**
   * Should show loading
   */
  isLoading: boolean;

  /**
   * Callback to load more entities
   */
  onLoadMore: () => void;

  /**
   * Scroll threshold
   */
  threshold?: number;

  /**
   * Throttle rate
   */
  throttle?: number;

  /** Children */
  children?: React.ReactNode;

  /**
   * Callback for convenient inline rendering and wrapping
   */
  render?: React.FC<{ sentinel: React.ReactNode }>;

  /**
   * A React component to act as wrapper
   */
  component?: React.ElementType;

  scrollOwnerRef?: React.RefObject<EventTarget>;
}

export class InfiniteScroll extends React.Component<InfiniteScrollProps, {}> {
  public static defaultProps: Pick<
    InfiniteScrollProps,
    'threshold' | 'throttle'
  > = {
    threshold: 100,
    throttle: 64,
  };
  private sentinelRef: React.RefObject<HTMLDivElement>;
  private scrollHandler: () => void;
  private resizeHandler: () => void;
  private scrollOwner: EventTarget;

  constructor(props: InfiniteScrollProps) {
    super(props);

    this.scrollHandler = throttle(this.checkWindowScroll, this.props.throttle);
    this.resizeHandler = throttle(this.checkWindowScroll, this.props.throttle);
    this.sentinelRef = React.createRef<HTMLDivElement>();
    this.scrollOwner = this.props.scrollOwnerRef?.current || window;
  }

  private get sentinel() {
    return this.sentinelRef?.current ?? null;
  }

  componentDidMount() {
    this.scrollOwner = this.props.scrollOwnerRef?.current || window;

    this.scrollOwner.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    this.scrollOwner.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate() {
    // This fixes edge case where initial content is not enough to enable scrolling on a large screen.
    this.scrollHandler();
  }

  checkWindowScroll = () => {
    if (this.props.isLoading) {
      return;
    }

    if (
      this.props.hasMore &&
      this.sentinel &&
      this.sentinel.getBoundingClientRect().top - window.innerHeight <
        this.props.threshold!
    ) {
      this.props.onLoadMore();
    }
  };

  render() {
    const sentinel = <div ref={this.sentinelRef} />;

    if (this.props.render) {
      return this.props.render({
        sentinel,
        children: this.props.children,
      });
    }

    if (this.props.component) {
      const Container = this.props.component;
      return <Container sentinel={sentinel}>{this.props.children}</Container>;
    }

    return (
      <div>
        {this.props.children}
        {sentinel}
      </div>
    );
  }
}

export default InfiniteScroll;
