import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBoxTwoTone';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick?: () => void;
};

const useStyles = makeStyles({
  card: {
    maxWidth: 200,
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 140,
  },
  icon: {
    fontSize: '5rem',
  },
});

const ImageUploadButton: React.FC<Props> = ({ onClick }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={onClick} component="span">
        <CardMedia
          className={classes.media}
          title={t('registration.finish.uploadDoc')}
        >
          <AddBoxIcon className={classes.icon} />
        </CardMedia>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t('registration.finish.uploadDoc')}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ImageUploadButton;
