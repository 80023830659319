import { NOTIFICATIONS_INITIAL_STATE } from '@rtt-libs/state-containers';
import type { NotificationsSliceState } from '@rtt-libs/state-containers';
import type { Reducer } from 'redux';
import type { ChatsAction } from './actions';
import * as TYPES from './types';

export const notificationsReducer: Reducer<
  NotificationsSliceState,
  ChatsAction
> = (state = NOTIFICATIONS_INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.CHATS_MESSAGES_GET_LIST_REQUEST:
    case TYPES.CHATS_CHANNELS_MAKE_READ_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          newMessages: 0,
        },
      };

    case TYPES.CHATS_RECEIVE_SYSTEM_NOTIFICATION: {
      if (action.payload.type !== 'new_message') return state;

      return {
        ...state,
        data: { ...state.data, newMessages: (state.data.newMessages ?? 0) + 1 },
      };
    }
    default:
      return state;
  }
};
