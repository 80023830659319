import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

/**
 * Setup interceptor for handle unauthorized errors
 * @param apiInstance - Already configured instance of Axios.
 * @param onUnauthorized - Callback when occurred 401 HTTP error.
 *
 * @example
 * interceptor.setupInterceptors(
 *   api,
 *   () => store.dispatch({ type: 'CLEAR_AUTH' }),
 * );
 */
export const setupInterceptors = (
  apiInstance: AxiosInstance,
  onUnauthorized: () => void,
): void => {
  apiInstance.interceptors.response.use(
    (response: AxiosResponse) => response,

    (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        onUnauthorized();
      }
      return Promise.reject(error);
    },
  );
};
