import { Box, CircularProgress, Typography } from '@material-ui/core';
import { restrictions } from '@rtt-libs/constants';
import 'moment/locale/ru';
import 'moment/locale/uk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GiftedChat } from 'react-web-gifted-chat';
import {
  connectToChannel,
  disconnectFromChannel,
  getMessageListRequest,
  loadMoreMessageListRequest,
  makeChannelReadRequest,
  sendMessageRequest,
} from '../../duck/actions';
import {
  selectChatCollection,
  selectMessagesByChannel,
  selectMessagesState,
  selectRttIdByChannel,
} from '../../duck/selectors';
import type {
  ChannelIdentity,
  ChannelType,
  ChatMessageWithPostData,
  ChatMessageWithProduct,
  Message,
  OpponentType,
} from '../../types';
import {
  transformGiftedChatToMessage,
  transformMessageToGiftedChat,
} from '../../utils';
import ChatWrapper from './ChatWrapper';
import {
  CustomComposer,
  CustomTicks,
  InputActions,
  SendButton,
  useModalState,
  EditMessageModal,
} from './customChatComponents';
import CustomMessageView from './customChatComponents/CustomMessageView';

type Props = {
  userId: number;
  header: React.ReactNode;
  opponentType?: OpponentType;
} & Partial<ChannelIdentity>;

const Chat = ({
  userId,
  header,
  channelId,
  channelType,
  opponentType,
}: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const messages = useSelector(selectMessagesByChannel(channelId));

  const rttId = useSelector(selectRttIdByChannel(channelId));

  const handleSendMessage = (newMessages: ChatMessageWithPostData[]) => {
    if (!channelId || !channelType) return undefined;

    return dispatch(
      sendMessageRequest({
        channelType,
        channelId,
        type: 'text',
        data: { message: newMessages[0].text },
        ...(newMessages[0].postData || {}),
        tempMessage: transformGiftedChatToMessage(channelId, newMessages[0]),
      }),
    );
  };

  // Get messages
  useEffect(() => {
    if (channelId && channelType) {
      dispatch(
        getMessageListRequest({
          channelId,
          channelType,
        }),
      );
      dispatch(connectToChannel(channelId));
      dispatch(
        makeChannelReadRequest({
          channelId,
          channelType,
        }),
      );
    }

    return () => {
      if (channelId) {
        dispatch(disconnectFromChannel(channelId));
      }
    };
  }, [dispatch, channelId, channelType]);

  const handleLoadEarlier = () => {
    if (channelId && channelType) {
      dispatch(
        loadMoreMessageListRequest({
          channelId,
          channelType,
          id: messages[messages.length - 1].id,
        }),
      );
    }
  };

  const { loading, hasMore } = useSelector(selectMessagesState);

  const [chatMessages, setChatMessages] = useState<ChatMessageWithProduct[]>(
    messages.map(transformMessageToGiftedChat),
  );

  // Optimized updating messages. Keep already transformed messages
  useEffect(() => {
    const isMessageUpdated = (
      newMessage: Message,
      message?: ChatMessageWithProduct,
    ): boolean => {
      if (!message) return true;

      if (message.id !== newMessage.id) return true;

      if (message.createdAt.valueOf() !== new Date(newMessage.date).valueOf())
        return true;

      if (message.isUnread !== newMessage.isUnread) return true;

      return message.text !== newMessage.data.message;
    };

    setChatMessages(prevMessages =>
      messages.map((message, id) => {
        const existingMessage: ChatMessageWithProduct | undefined =
          prevMessages[id];

        if (isMessageUpdated(message, existingMessage)) {
          return transformMessageToGiftedChat(message);
        }

        return existingMessage;
      }),
    );
  }, [messages, setChatMessages]);

  const disabledInput = useInputDisabled(opponentType, channelId, channelType);

  const showAddProduct = channelType === 'chat';

  const { isOpened, open, close } = useModalState();

  const [editingValues, changeEditingValues] = useState<{
    id: string;
    text: string;
  }>();

  useEffect(() => {
    if (messages[0]?.sender.id !== userId && channelId && channelType) {
      dispatch(makeChannelReadRequest({ channelId, channelType }));
    }
  }, [dispatch, messages, channelId, channelType, userId]);

  return (
    <ChatWrapper>
      {typeof header === 'string' ? (
        <Typography component="h2" variant="h5">
          {header}
        </Typography>
      ) : (
        header
      )}
      <Box flex="1 1" height="0px" maxHeight="100%">
        <GiftedChat
          locale={i18n.language}
          loadEarlier={hasMore}
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          infiniteScroll
          messages={chatMessages}
          user={{
            id: userId,
          }}
          onSend={handleSendMessage}
          onLoadEarlier={handleLoadEarlier}
          isLoadingEarlier={loading}
          label={t('controls.loadMore')}
          placeholder={t('common.messagePlaceholder')}
          renderComposer={props => <CustomComposer {...props} />}
          renderSend={props => <SendButton {...props} />}
          renderActions={props => (
            <InputActions
              disabled={disabledInput}
              rttId={rttId}
              showAddProduct={showAddProduct}
              {...props}
            />
          )}
          renderLoading={() => <CircularProgress />}
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          renderCustomView={CustomMessageView}
          textInputProps={{
            disabled: disabledInput,
            inputProps: { maxLength: restrictions.CHAT_MESSAGE_MAX_SIZE },
          }}
          onLongPress={() => undefined}
          renderTicks={(message: ChatMessageWithProduct) => (
            <CustomTicks
              {...message}
              userId={userId}
              onEditClick={(id, text) => {
                changeEditingValues({ id, text });
                open();
              }}
            />
          )}
          textProps={{ style: { overflowWrap: 'anywhere' } }}
        />

        {editingValues && channelId && channelType && (
          <EditMessageModal
            open={isOpened}
            onClose={close}
            text={editingValues.text}
            id={editingValues.id}
            channelId={channelId}
            channelType={channelType}
            onExited={() => {
              changeEditingValues(undefined);
            }}
          />
        )}
      </Box>
    </ChatWrapper>
  );
};

export default Chat;

const useInputDisabled = (
  opponentType?: OpponentType,
  channelId?: string,
  channelType?: ChannelType,
): boolean => {
  const collection = useSelector(selectChatCollection);

  if (!channelId) return true;

  const { isResolved, admin, isBlocked } = collection[channelId] || {};

  if (isBlocked) return true;

  if (channelType !== 'support') return false;

  return isResolved || (opponentType === 'distributor' && !admin);
};
