import type {
  Message,
  ChatMessageWithProduct,
  UUID,
  MessageType,
} from '../types';

export const transformMessageToGiftedChat = (
  message: Message,
): ChatMessageWithProduct => {
  return {
    id: message.id,
    createdAt: new Date(message.date),
    text: message.data.message ?? '',
    image: message.data.imgSrc,
    product: message.data.product,
    isUnread: message.isUnread,
    user: {
      id: message.sender.id,
      name: `${message.sender.firstName} ${message.sender.lastName}`,
      avatar: message.sender.img ?? undefined,
    },
  };
};

export const transformGiftedChatToMessage = (
  channelId: UUID,
  message: ChatMessageWithProduct,
): Message => {
  return {
    channelId,
    type: pickMessageType(message),
    id: message.id,
    date: message.createdAt.toISOString(),
    data: {
      message: message.text,
      product: message.product,
      imgSrc: message.image,
    },
    sender: {
      id: message.user.id as number,
      firstName: '',
      lastName: '',
      role: 'admin',
    },
    isUnread: message.isUnread,
  };
};

const pickMessageType = (message: ChatMessageWithProduct): MessageType => {
  if (message.product) return 'product';

  if (message.image) return 'image';

  return 'text';
};
