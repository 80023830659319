import { AxiosError } from 'axios';
import { FORM_ERROR, SubmissionErrors } from 'final-form';

export type APIError = {
  message: string;
  response?: {
    data: {
      message: string;
      errors?: {
        [field: string]: string[];
      };
    };
  };
};

type Callback = (error: APIError) => void;
export type TransformAPIError<
  T extends object = Record<string, string[] | string>,
  R extends object & { [FORM_ERROR]?: string } = Record<string, string>
> = (errors: Partial<T>) => Partial<R>;

const formErrorTransform = (
  e: APIError,
  transform?: TransformAPIError,
  callback?: Callback,
  generalErrorKey: string = FORM_ERROR,
): SubmissionErrors => {
  let errors: Record<string, string[] | string | undefined> = {};
  if (e.response) {
    if (transform) {
      errors = {
        ...transform(e.response.data.errors || {}),
        [generalErrorKey]: e.response.data.message,
      };
    } else {
      errors = {
        ...(e.response.data.errors || {}),
        [generalErrorKey]: e.response.data.message,
      };
    }
  } else {
    errors = {
      [generalErrorKey]: e.message,
    };
  }

  if (typeof callback === 'function') callback(e);

  return errors;
};

export default formErrorTransform;

export function transformSimpleErrorMessage(
  e: AxiosError<{
    status_code: number;
    message: string;
  }>,
) {
  throw new Error(e.response ? e.response.data.message : e.message);
}
