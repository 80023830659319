import { Box, CircularProgress, InputAdornment } from '@material-ui/core';
import {
  FieldWithMessages,
  LoadingButton,
  TextFieldAdapter,
} from '@rtt-libs/views';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { PromptDirtyForm } from '../../common';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import { getFeedbackEmailRequest } from '../duck/actions';
import { selectEmailSettingsData } from '../duck/selectors';
import type {
  FeedbackEmailFormErrors,
  FeedbackEmailFormValues,
} from '../types';

const FeedbackEmail = (): React.ReactElement => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { email, loading, error } = useSelector(selectEmailSettingsData);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const initialValues: FeedbackEmailFormValues | undefined = useMemo(() => {
    if (!email) return undefined;

    return {
      email,
    };
  }, [email]);

  useEffect(() => {
    if (!email) {
      dispatch(getFeedbackEmailRequest());
    }
  }, [email, dispatch]);

  const onResolve = () => {
    enqueueSnackbar(t('common.snackbars.saveSuccess'), { variant: 'success' });
  };
  const onReject = (errors: FeedbackEmailFormErrors) => {
    enqueueSnackbar(t('common.snackbars.saveFailed'), { variant: 'error' });
    return errors;
  };

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.SETTINGS_EMAIL_SET_REQUEST}
      resolve={types.SETTINGS_EMAIL_SET_SUCCESS}
      reject={types.SETTINGS_EMAIL_SET_FAILURE}
    >
      {submit => (
        <Form
          onSubmit={values => submit(values).then(onResolve, onReject)}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            pristine,
            submitting,
            hasValidationErrors,
            hasSubmitErrors,
            dirtySinceLastSubmit,
          }) => (
            <Box clone display="flex" alignItems="center">
              <form onSubmit={handleSubmit}>
                <PromptDirtyForm />

                <Box flexBasis={300} mr={2}>
                  <FieldWithMessages
                    name="email"
                    type="email"
                    label={t('admin.settings.feedback.emailLabel')}
                    component={TextFieldAdapter}
                    required
                    fullWidth
                    margin="dense"
                    InputProps={{
                      endAdornment: loading ? (
                        <InputAdornment position="end">
                          <CircularProgress size="1rem" />
                        </InputAdornment>
                      ) : undefined,
                    }}
                  />
                </Box>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={submitting}
                  disabled={
                    hasValidationErrors ||
                    pristine ||
                    submitting ||
                    (hasSubmitErrors && !dirtySinceLastSubmit)
                  }
                >
                  {t('controls.save')}
                </LoadingButton>
              </form>
            </Box>
          )}
        </Form>
      )}
    </MakeAsyncFunction>
  );
};

export default FeedbackEmail;
