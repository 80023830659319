import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { ux } from '@rtt-libs/constants';

interface Props {
  onChange(value: string): void;
}

const SearchChatRow = ({ onChange }: Props) => {
  const [t] = useTranslation();

  const onChangeDebounced = debounce(onChange, ux.DEBOUNCE_DELAY);

  const handleSearchDebounced = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.target;
      onChangeDebounced(value);
    },
    [onChangeDebounced],
  );

  return (
    <TextField
      name="search"
      type="search"
      autoComplete="off"
      id="chatSearch"
      label={t('common.search')}
      onChange={handleSearchDebounced}
      variant="outlined"
      fullWidth
      size="small"
      margin="normal"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchChatRow;
