export const CHATS_EMIT_EVENT = '@rtt[Ch]/CHATS_EMIT_EVENT' as const;

export const CHATS_RECEIVE_MESSAGE = '@rtt[Ch]/CHATS_RECEIVE_MESSAGE' as const;
export const CHATS_RECEIVE_ERROR = '@rtt[Ch]/CHATS_RECEIVE_ERROR' as const;
export const CHATS_RECEIVE_SYSTEM_NOTIFICATION = '@rtt[Ch]/CHATS_RECEIVE_SYSTEM_NOTIFICATION' as const;

export const CHATS_CANCEL = '@rtt[Ch]/CHATS_CANCEL' as const;

export const CHATS_CONNECT_CHANNEL = '@rtt[Ch]/CHATS_CONNECT_CHANNEL' as const;
export const CHATS_DISCONNECT_CHANNEL = '@rtt[Ch]/CHATS_DISCONNECT_CHANNEL' as const;

export const CHATS_CONNECT_SYSTEM = '@rtt[Ch]/CHATS_CONNECT_SYSTEM' as const;
export const CHATS_DISCONNECT_SYSTEM = '@rtt[Ch]/CHATS_DISCONNECT_SYSTEM' as const;

export const CHATS_CHANNELS_UPDATE_DATA = '@rtt[Ch]/CHATS_CHANNELS_UPDATE_DATA' as const;

export const CHATS_CHANNELS_GET_LIST_REQUEST = '@rtt[Ch]/CHATS_CHANNELS_GET_LIST_REQUEST' as const;
export const CHATS_CHANNELS_GET_LIST_SUCCESS = '@rtt[Ch]/CHATS_CHANNELS_GET_LIST_SUCCESS' as const;
export const CHATS_CHANNELS_GET_LIST_FAILURE = '@rtt[Ch]/CHATS_CHANNELS_GET_LIST_FAILURE' as const;

export const CHATS_CHANNELS_GET_MORE_LIST_REQUEST = '@rtt[Ch]/CHATS_CHANNELS_GET_MORE_LIST_REQUEST' as const;
export const CHATS_CHANNELS_GET_MORE_LIST_SUCCESS = '@rtt[Ch]/CHATS_CHANNELS_GET_MORE_LIST_SUCCESS' as const;
export const CHATS_CHANNELS_GET_MORE_LIST_FAILURE = '@rtt[Ch]/CHATS_CHANNELS_GET_MORE_LIST_FAILURE' as const;

export const CHATS_CHANNELS_MAKE_READ_REQUEST = '@rtt[Ch]/CHATS_CHANNELS_MAKE_READ_REQUEST' as const;
export const CHATS_CHANNELS_MAKE_READ_SUCCESS = '@rtt[Ch]/CHATS_CHANNELS_MAKE_READ_SUCCESS' as const;
export const CHATS_CHANNELS_MAKE_READ_FAILURE = '@rtt[Ch]/CHATS_CHANNELS_MAKE_READ_FAILURE' as const;

export const CHATS_CHANNELS_RESOLVE_REQUEST = '@rtt[Ch]/CHATS_CHANNELS_RESOLVE_REQUEST' as const;
export const CHATS_CHANNELS_RESOLVE_SUCCESS = '@rtt[Ch]/CHATS_CHANNELS_RESOLVE_SUCCESS' as const;
export const CHATS_CHANNELS_RESOLVE_FAILURE = '@rtt[Ch]/CHATS_CHANNELS_RESOLVE_FAILURE' as const;

export const CHATS_SUPPORT_CLOSE_REQUEST = '@rtt[Ch]/CHATS_SUPPORT_CLOSE_REQUEST' as const;
export const CHATS_SUPPORT_CLOSE_SUCCESS = '@rtt[Ch]/CHATS_SUPPORT_CLOSE_SUCCESS' as const;
export const CHATS_SUPPORT_CLOSE_FAILURE = '@rtt[Ch]/CHATS_SUPPORT_CLOSE_FAILURE' as const;

export const CHATS_SUPPORT_ASSIGN_REQUEST = '@rtt[Ch]/CHATS_SUPPORT_ASSIGN_REQUEST' as const;
export const CHATS_SUPPORT_ASSIGN_SUCCESS = '@rtt[Ch]/CHATS_SUPPORT_ASSIGN_SUCCESS' as const;
export const CHATS_SUPPORT_ASSIGN_FAILURE = '@rtt[Ch]/CHATS_SUPPORT_ASSIGN_FAILURE' as const;

export const CHATS_MESSAGES_GET_LIST_REQUEST = '@rtt[Ch]/CHATS_MESSAGES_GET_LIST_REQUEST' as const;
export const CHATS_MESSAGES_GET_LIST_SUCCESS = '@rtt[Ch]/CHATS_MESSAGES_GET_LIST_SUCCESS' as const;
export const CHATS_MESSAGES_GET_LIST_FAILURE = '@rtt[Ch]/CHATS_MESSAGES_GET_LIST_FAILURE' as const;

export const CHATS_MESSAGES_GET_MORE_LIST_REQUEST = '@rtt[Ch]/CHATS_MESSAGES_GET_MORE_LIST_REQUEST' as const;
export const CHATS_MESSAGES_GET_MORE_LIST_SUCCESS = '@rtt[Ch]/CHATS_MESSAGES_GET_MORE_LIST_SUCCESS' as const;
export const CHATS_MESSAGES_GET_MORE_LIST_FAILURE = '@rtt[Ch]/CHATS_MESSAGES_GET_MORE_LIST_FAILURE' as const;

export const CHATS_MESSAGES_SEND_REQUEST = '@rtt[Ch]/CHATS_MESSAGES_SEND_REQUEST' as const;
export const CHATS_MESSAGES_SEND_SUCCESS = '@rtt[Ch]/CHATS_MESSAGES_SEND_SUCCESS' as const;
export const CHATS_MESSAGES_SEND_FAILURE = '@rtt[Ch]/CHATS_MESSAGES_SEND_FAILURE' as const;

export const CHATS_MESSAGES_EDIT_REQUEST = '@rtt[Ch]/CHATS_MESSAGES_EDIT_REQUEST' as const;
export const CHATS_MESSAGES_EDIT_SUCCESS = '@rtt[Ch]/CHATS_MESSAGES_EDIT_SUCCESS' as const;
export const CHATS_MESSAGES_EDIT_FAILURE = '@rtt[Ch]/CHATS_MESSAGES_EDIT_FAILURE' as const;

export const CHATS_IMAGES_UPLOAD_REQUEST = '@rtt[Ch]/CHATS_IMAGES_UPLOAD_REQUEST' as const;
export const CHATS_IMAGES_UPLOAD_SUCCESS = '@rtt[Ch]/CHATS_IMAGES_UPLOAD_SUCCESS' as const;
export const CHATS_IMAGES_UPLOAD_FAILURE = '@rtt[Ch]/CHATS_IMAGES_UPLOAD_FAILURE' as const;
export const CHATS_IMAGES_UPLOAD_CLEANUP = '@rtt[Ch]/CHATS_IMAGES_UPLOAD_CLEANUP' as const;
