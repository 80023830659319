import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { ActionOption, EntryId } from './types';

type Props = {
  id: EntryId;
  options: ActionOption[];
};

const ActionsMenu: React.FC<Props> = ({ id, options }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={`actions-menu-${String(id)}`}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`actions-menu-${String(id)}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(({ label, onClick, title, ...rest }) => {
          const menuItemElement = (
            <MenuItem
              key={label}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                e.stopPropagation();
                onClick(id);
                handleClose(e);
              }}
              {...rest}
            >
              {label}
            </MenuItem>
          );

          return title ? (
            <Tooltip key={label} title={title} placement="left" interactive>
              <div>{menuItemElement}</div>
            </Tooltip>
          ) : (
            menuItemElement
          );
        })}
      </Menu>
    </>
  );
};

export default ActionsMenu;
